<template>
    <section>
        <h6>{{ t("user_account_information") }}</h6>
        <VForm v-slot="{ onValidate }">
            <div class="text-center">
                <ImageInput :handleImageChange="handleImageChange" />
            </div>
            <div class="mt-3 mb-3">
                <div v-show="cardNumber" class="col mt-3">
                    <label>{{ t("card_number") }}</label>
                    <div class="input-group">
                        <input disabled type="text" class="form-control" :value="cardNumber" aria-label="card_number" />
                    </div>
                </div>

                <div class="row">
                    <div class="col mt-3">
                        <VTextField
                            :disabled="disableName"
                            v-model="form.name"
                            label="full_name"
                            rules="required"></VTextField>
                    </div>
                    <div class="col mt-3">
                        <VPhoneField v-model="form.phone" label="phone_number" rules="required"></VPhoneField>
                    </div>
                </div>
                <div class="row">
                    <div class="col mt-3">
                        <VTextField v-model="form.blood_type" label="blood_type" rules="required"></VTextField>
                    </div>
                    <div class="col mt-3">
                        <VTextField v-model="form.family_number" label="family_number" rules="required"></VTextField>
                    </div>
                </div>
                <div class="row">
                    <div class="col mt-3">
                        <VPasswordField
                            name="password"
                            vid="password"
                            v-model="form.password"
                            label="password"
                            rules="required|min:8|regex:^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])"></VPasswordField>
                    </div>
                    <div class="col mt-3">
                        <VPasswordField
                            name="confirmPassword"
                            label="confirm_password"
                            v-model="form.confirmPassword"
                            rules="required|confirmed:password"></VPasswordField>
                    </div>
                </div>

                <div class="row">
                    <div class="col mt-3">
                        <VTextField type="email" v-model="form.email" label="email" rules="required"></VTextField>
                    </div>
                    <div class="col mt-3">
                        <VSelectField
                            v-model="governorateId"
                            :items="governorates"
                            label="governorate"
                            rules="required"></VSelectField>
                    </div>
                </div>

                <div v-show="governorateId" class="row">
                    <div class="col mt-3">
                        <VSelectField
                            v-model="form.districtId"
                            :items="districts"
                            label="district"
                            rules="required"></VSelectField>
                    </div>
                </div>

                <div class="row">
                    <div class="col mt-3">
                        <VTextField v-model="form.address" label="address" rules="required"></VTextField>
                    </div>
                    <div class="col mt-3">
                        <VBirthdateField
                            v-if="!disableGenderAndBirthDate"
                            v-model="form.birthDate"
                            label="birthdate"
                            rules="required"></VBirthdateField>
                        <VTextField
                            v-if="disableGenderAndBirthDate"
                            :disabled="disableGenderAndBirthDate"
                            v-model="form.birthDate"
                            label="birthdate"
                            rules="required"></VTextField>
                    </div>
                </div>
                <div class="row">
                    <div class="col mt-3">
                        <VSelectField
                            :disabled="disableGenderAndBirthDate"
                            v-model="form.gender"
                            :items="[
                                {
                                    text: t('male'),
                                    value: true,
                                },
                                {
                                    text: t('female'),
                                    value: false,
                                },
                            ]"
                            label="gender"
                            rules="required"></VSelectField>
                    </div>
                    <div class="col mt-3">
                        <VSelectField
                            v-model="form.schooling"
                            :items="degrees"
                            label="schooling"
                            rules="required"></VSelectField>
                    </div>
                </div>
                <div class="row">
                    <div class="col mt-3">
                        <label>{{ t("nationalties") }}</label>
                        <span class="text-sm text-danger mx-1">*</span>
                        <multiselect
                            v-model="form.nationalityIds"
                            :options="nationalties"
                            name="nationalties"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            placeholder="Search"
                            label="text"
                            track-by="text"></multiselect>
                    </div>
                    <div class="col mt-3">
                        <label>{{ t("languages") }}</label>
                        <span class="text-sm text-danger mx-1">*</span>
                        <multiselect
                            v-model="form.languageIds"
                            :options="languages"
                            name="languages"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            placeholder="Search"
                            label="text"
                            track-by="text"></multiselect>
                    </div>
                </div>
            </div>
            <div class="mt-4 text-center text-danger">
                {{ error ? error : "" }}
            </div>
            <div class="row mt-2">
                <div class="col-12 text-right">
                    <button :disabled="loading" @click="onValidate(submitForm)" type="submit" class="btn btn-primary">
                        {{ loading ? t("loading") : t("verify") }}
                    </button>
                </div>
            </div>
        </VForm>
    </section>
</template>

<script>
    import axiosInstance from "../../../Utils/axios";
    import ImageInput from "./ImageInput.vue";
    import { removeSpaces } from "../../../Utils/helpers";

    export default {
        props: ["userType", "cardNumber", "userInfoFromQi", "userRegisterationSuccess"],
        data() {
            return {
                degrees: [
                    { text: this.t("primery"), value: 0 },
                    { text: this.t("middle"), value: 1 },
                    { text: this.t("secondary"), value: 2 },
                    { text: this.t("diploma"), value: 3 },
                    { text: this.t("bs"), value: 4 },
                    { text: this.t("masters"), value: 5 },
                    { text: this.t("phd"), value: 6 },
                    { text: this.t("prof"), value: 7 },
                ],
                loading: false,
                disableName: false,
                disableGenderAndBirthDate: false,
                error: "",
                governorateId: "",
                confirmPassword: "",
                form: {
                    afeaaCheckId: "",
                    name: "",
                    email: "",
                    phone: "",
                    password: "",
                    gender: "",
                    address: "",
                    districtId: "",
                    birthDate: "",
                    schooling: "",
                    userType: this.userType,
                    blood_type: "",
                    family_number: "",
                },
            };
        },
        computed: {
            governorates() {
                return this.$store.getters["auth/governorate"];
            },
            districts() {
                return this.$store.getters["auth/districts"];
            },
            nationalties() {
                return this.$store.getters["auth/nationalties"];
            },
            languages() {
                return this.$store.getters["auth/languages"];
            },
        },
        watch: {
            userInfoFromQi: function (info) {
                this.disableName = true;
                this.disableGenderAndBirthDate = true;
                this.form.name = `${info.firstName} ${info.secondName} ${info.thirdName}`;
                this.form.birthDate = info.dob.split("/").reverse().join("-");
                this.form.gender = info.genderName === "ذكر" ? true : false;
                this.form.afeaaCheckId = info.afeaaCheckId;
            },
            governorateId: function (v) {
                this.$store.dispatch("auth/get_districts_for", v);
            },
        },
        methods: {
            handleImageChange(img) {
                this.form.picture = img;
            },
            async submitForm() {
                this.error = "";
                const { gender, nationalityIds, languageIds, email, phone, ...others } = this.form;
                let userData = this.cardNumber
                    ? {
                          ...others,
                          email: email.toLowerCase(),
                          gender: JSON.parse(gender),
                          cardNumber: this.cardNumber,
                      }
                    : { ...others, email: email.toLowerCase(), gender: JSON.parse(gender) };
                const formData = new FormData();
                const objKeys = Object.keys(userData);
                const objValues = Object.values(userData);
                for (let i = 0; i < objKeys.length; i++) {
                    formData.append(objKeys[i], objValues[i]);
                }
                if (nationalityIds) {
                    for (let i = 0; i < nationalityIds.length; i++) {
                        formData.append("nationalityIds", nationalityIds[i].value);
                    }
                }

                if (languageIds) {
                    for (let i = 0; i < languageIds.length; i++) {
                        formData.append("languageIds", languageIds[i].value);
                    }
                }

                formData.append("phone", removeSpaces(phone));

                try {
                    this.loading = true;
                    const { data } = await axiosInstance.post("User/Register", formData);
                    this.loading = false;
                    if (!data.error) {
                        // data.data means token
                        this.userRegisterationSuccess(data.data);
                        localStorage.setItem("user", JSON.stringify(this.form));
                    }
                } catch (error) {
                    this.loading = false;
                    if (error.response) {
                        // Request made and server responded
                        this.error = error.response.data.message;
                    } else {
                        // Request timeout
                        // Something happened in setting up the request that triggered an Error
                        this.error = "يوجد خلل في الانترنت، الرجاء المحاولة لاحقا";
                    }
                }
            },
        },
        components: { ImageInput },
    };
</script>
