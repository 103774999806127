<template>
    <footer class="footer-section">
        <!--footer top start-->
        <!--for light footer add .footer-light class and for dark footer add .bg-dark .text-white class-->
        <div class="footer-top ptb-120" :class="footerDark ? 'bg-gradient text-white' : 'footer-light'">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-md-8 col-lg-4 mb-md-4 mb-lg-0">
                        <div class="footer-single-col">
                            <div class="footer-single-col mb-4">
                                <img
                                    src="@/assets/images/afea-logo.png"
                                    alt="logo"
                                    width="120"
                                    class="img-fluid logo-white" />
                                <img
                                    src="@/assets/images/afea-logo.png"
                                    alt="logo"
                                    width="120"
                                    class="img-fluid logo-color" />
                            </div>
                            <p>
                                نظام عافية الصحي، الخيار الأمثل لإدارة السجل الطبي، يتكامل بشكل مباشر مع الضمان الصحي،
                                 ومُضمنا حقك في الحصول على الخدمات الصحية
                                المستحقة.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0">
                        <div class="row">
                            <div class="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                                <div class="footer-single-col">
                                    <h3>
                                        {{ t("fast_links") }}
                                    </h3>
                                    <ul class="list-unstyled footer-nav-list mb-lg-0">
                                        <li>
                                            <a
                                                href="https://dashboard.afea.healthcare/"
                                                class="text-decoration-none"
                                                target="_blank">
                                                {{ t("login") }}
                                            </a>
                                        </li>
                                        <li>
                                            <router-link to="/" class="text-decoration-none">
                                                {{ t("home") }}
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/#services" class="text-decoration-none">
                                                {{ t("services") }}
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/#sponsers" class="text-decoration-none">
                                                {{ t("sponsers") }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                                <div class="footer-single-col">
                                    <h3>{{ t("services") }}</h3>
                                    <ul class="list-unstyled footer-nav-list mb-lg-0">
                                        <li>
                                            <router-link to="/services/3" class="text-decoration-none">
                                                خدمة السجل الصحي
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/services/0" class="text-decoration-none">
                                                {{ t("clinicService") }}
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/services/1" class="text-decoration-none">
                                                {{ t("pharmacyService") }}
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/services/2" class="text-decoration-none">
                                                {{ t("laboratoryService") }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                                <div class="footer-single-col">
                                    <h3>{{ t("support") }}</h3>
                                    <ul class="list-unstyled footer-nav-list mb-lg-0">
                                        <li>
                                            <router-link to="/contact" class="text-decoration-none">
                                                {{ t("contact_us") }}
                                            </router-link>
                                        </li>

                                        <li>
                                            <router-link to="/privacy" class="text-decoration-none">
                                                {{ t("privacy") }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--footer top end-->

        <!--footer bottom start-->
        <div class="footer-bottom text-white py-4" :class="footerDark ? 'bg-dark bg-gradient' : 'footer-light'">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-md-7 col-lg-7">
                        <div class="copyright-text">
                            <p class="mb-lg-0 mb-md-0">
                                &copy; {{ date.getFullYear() }} {{ t("afea_rights_reserved") }}
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                        <div class="footer-single-col text-start text-lg-end text-md-end">
                            <ul class="list-unstyled list-inline footer-social-list mb-0">
                                <li class="list-inline-item">
                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"><i class="fab fa-instagram"></i></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"><i class="fab fa-dribbble"></i></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#"><i class="fab fa-github"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--footer bottom end-->
    </footer>
</template>

<script>
    import Rating from "../common/Rating.vue";

    export default {
        data() {
            return {
                date: new Date(),
            };
        },
        components: { Rating },
        name: "Footer",
        props: {
            footerDark: String,
        },
    };
</script>