export const systemServices = [
    {
        id: 0,
        name: "clinic_management",
        description: "hospital_management_des",
        icon: "fas fa-hospital",
    },
    {
        id: 1,
        name: "pharmacies_management",
        description: "pharmacies_management_des",
        icon: "fas fa-capsules",
    },
    {
        id: 2,
        name: "laboratory_management",
        description: "laboratory_management_des",
        icon: "fas fa-flask",
    },
    {
        id: 3,
        name: "خدمة السجل صحي",
        description:
            "يدمج نظام عافية الصحي مجموعة من الخدمات، التي تسهل للمرضى التحقق من التغطية الصحية المتاحة لهم. يساعد النظام أيضًا في تتبع الخدمات الصحية المقدمة، مما يضمن استحقاقات الرعاية الصحية بشكل صحيح.",
        icon: "fa-solid fa-calendar-days",
    },
    // {
    //     id: 3,
    //     name: "patient_health_record",
    //     description: "mobile_app_des_2",
    //     icon: "fa-solid fa-calendar-days",
    // },
    // {
    // 	id: 4,
    // 	name: 'electronic_payment',
    // 	description: 'electronic_payment_des',
    // 	icon: 'fa-solid fa-credit-card-front',
    // },
    {
        id: 5,
        name: "reducing_treatment_expenses",
        description: "reducing_treatment_expenses_des",
        icon: "fas fa-flask",
    },
    // {
    // 	id: 6,
    // 	name: 'medical_loans_and_advances',
    // 	description: 'medical_loans_and_advances_des',
    // 	icon: 'fa-solid fa-money-bill-wave',
    // },
    {
        id: 7,
        name: "appointment_reminder",
        description: "appointment_reminder_des",
        icon: "fa-solid fa-calendar-days",
    },
    {
        id: 8,
        name: "reduce_medical_errors",
        description: "reduce_medical_errors_des",
        icon: "fa-solid fa-triangle-exclamation",
    },
    {
        id: 9,
        name: "medical_record_management",
        description: "medical_record_management_des",
        icon: "fas fa-book",
    },
    {
        id: 10,
        name: "treatment_priority",
        description: "treatment_priority_des",
        icon: "fas fa-book",
    },
];

export const servicesfaq = [
    {
        id: 0,
        questions: [
            { question: "clinic_faq_q1", answer: "clinic_faq_a1" },
            { question: "clinic_faq_q2", answer: "clinic_faq_a2" },
            { question: "clinic_faq_q3", answer: "clinic_faq_a3" },
            { question: "clinic_faq_q4", answer: "clinic_faq_a4" },
            { question: "clinic_faq_q5", answer: "clinic_faq_a5" },
            { question: "clinic_faq_q6", answer: "clinic_faq_a6" },
        ],
    },
    {
        id: 1,
        questions: [
            { question: "pharmacy_faq_q1", answer: "pharmacy_faq_a1" },
            { question: "pharmacy_faq_q2", answer: "pharmacy_faq_a2" },
            { question: "pharmacy_faq_q3", answer: "pharmacy_faq_a3" },
            { question: "pharmacy_faq_q4", answer: "pharmacy_faq_a4" },
            { question: "pharmacy_faq_q5", answer: "pharmacy_faq_a5" },
            { question: "pharmacy_faq_q6", answer: "pharmacy_faq_a6" },
            { question: "pharmacy_faq_q7", answer: "pharmacy_faq_a7" },
        ],
    },
    {
        id: 2,
        questions: [
            { question: "lab_faq_q1", answer: "lab_faq_a1" },
            { question: "lab_faq_q2", answer: "lab_faq_a2" },
            { question: "lab_faq_q3", answer: "lab_faq_a3" },
            { question: "lab_faq_q4", answer: "lab_faq_a4" },
            { question: "lab_faq_q5", answer: "lab_faq_a5" },
            { question: "lab_faq_q6", answer: "lab_faq_a6" },
            { question: "lab_faq_q7", answer: "lab_faq_a7" },
        ],
    },
    // {
    //     id: 3,
    //     questions: [
    //         { question: "patient_faq_q1", answer: "patient_faq_a1" },
    //         { question: "patient_faq_q2", answer: "patient_faq_a2" },
    //         { question: "patient_faq_q3", answer: "patient_faq_a3" },
    //         { question: "patient_faq_q4", answer: "patient_faq_a4" },
    //         { question: "patient_faq_q5", answer: "patient_faq_a5" },
    //     ],
    // },
    {
        id: 3,
        questions: [
            {
                question: "هل سيلاحظ المواطن المشترك  الفرق في تلقي الخدمات الصحية؟",
                answer: "نعم، نظام  مبني على مبدأ التكافل الصحي وتوزيع المخاطر، مع مساهمة الحكومة من خلال توفير الأموال والدعم المالي. هذا يساعد في تحسين الواقع الصحي الحالي ويتيح تقديم خدمات صحية عالية الجودة من قبل مقدمي الخدمة، مما يساهم في تقليل الإنفاق الشخصي على الخدمات الصحية والطبية وتخفيف الأعباء المالية على المواطنين.",
            },
            {
                question: "ما هي الفوائد الرئيسية للمواطنين المشتركين؟",
                answer: "أحد أهم المزايا هو سهولة وسرعة الوصول إلى الخدمات الصحية. النظام  يستند إلى الأنظمة الإلكترونية الحديثة التي تلبي حاجات المواطنين، من سرعة الاستجابة والدعم، ونظام الحجوزات والمواعيد، وتوفير دليل للخدمة الإرشادية. كما يحافظ النظام على خصوصية المرضى ومعلوماتهم الشخصية، ويحميهم من التلاعب والغش، من خلال التعامل معهم بشفافية ووضوح",
            },
            // {
            //     question:
            //         "ما هي النسبة التي تتكفل بها الدولة من قيمة الخدمات الصحية عند مراجعة المواطن المشترك بالضمان للمؤسسات الصحية؟",
            //     answer: "تتكفل الدولة بدفع نسبة 30% من قيمة الخدمات الصحية لمقدم الخدمة عند مراجعة المواطن المشترك بالضمان للمؤسسات الصحية العاملة بالضمان. كما تدفع الدولة أيضًا 30% من كلفة العمليات الجراحية عند إجرائها في المستشفيات الحكومية.",
            // },
            // {
            //     question:
            //         " ما هي النسبة التي تتحملها الدولة من كلفة العمليات الجراحية للمواطن المشترك بالضمان؟",
            //     answer: "تتحمل الدولة دفع نسبة 75% من كلفة العمليات الجراحية للمواطن المشترك بالضمان عند إجرائها في المستشفيات غير الحكومية.",
            // },
        ],
    },
];
