<template>
    <section class="promo-section ptb-120 bg-light" id="services">
        <div class="fuild-container mx-lg-5 px-3">
            <div class="row justify-content-center">
                <div class="col-md-12 col-lg-8">
                    <div class="section-heading text-center" data-aos="fade-up">
                        <h2>{{ t("afea_services") }}</h2>
                        <p class="lead">
                            {{ t("afea_body_description") }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-xl-3 col-md-6 col-sm-12 mt-4 mt-lg-0">
                    <div
                        class="bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom"
                        data-aos="fade-up"
                        data-aos-delay="200">
                        <div class="promo-card-info mb-4">
                            <h5 class="mb-4">
                                <i class="far fa-fingerprint text-warning me-2"></i>
                                المتابعة الصحية
                            </h5>
                            <p class="four-lines-text">
                                يوفر نظام عافية الصحي مجموعة من الخدمات التي تسهل للمرضى التحقق من التغطية الصحية المتاحة لهم. يساعد النظام أيضًا في تتبع الخدمات الصحية المقدمة، مما يضمن استحقاقات الرعاية الصحية بشكل صحيح.
                            </p>
                            <router-link :to="`/services/3`" class="link-with-icon text-decoration-none mt-3">
                                {{ t("view_details") }}
                                <i v-if="currentLang === 'ar'" class="far fa-arrow-left"></i>
                                <i v-else class="far fa-arrow-right"></i>
                            </router-link>
                            <br />
                            <router-link to="/patient/registration" class="btn btn-primary mt-2">
                                {{ t("get_started") }}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3 col-md-6 col-sm-12 mt-4 mt-lg-0 mt-md-0">
                    <div
                        class="bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom"
                        data-aos="fade-up"
                        data-aos-delay="100">
                        <div class="promo-card-info mb-4">
                            <h5 class="mb-4">
                                <i class="far fa-fingerprint text-warning me-2"></i>
                                {{ t("clinicService") }}
                            </h5>
                            <p class="four-lines-text">
                                {{ t("clinic_description") }}
                            </p>
                            <router-link :to="`/services/0`" class="link-with-icon text-decoration-none mt-3">
                                {{ t("view_details") }}
                                <i v-if="currentLang === 'ar'" class="far fa-arrow-left"></i>
                                <i v-else class="far fa-arrow-right"></i>
                            </router-link>
                            <br />
                            <router-link to="/clinic/registration" class="btn btn-primary mt-2">
                                {{ t("get_started") }}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3 col-md-6 col-sm-12 mt-4 mt-lg-0 mt-md-0">
                    <div
                        class="bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom"
                        data-aos="fade-up"
                        data-aos-delay="150">
                        <div class="promo-card-info mb-4">
                            <h5 class="mb-4">
                                <i class="far fa-fingerprint text-warning me-2"></i>
                                {{ t("pharmacyService") }}
                            </h5>
                            <p class="four-lines-text">
                                {{ t("pharmacy_description") }}
                            </p>
                            <router-link :to="`/services/1`" class="link-with-icon text-decoration-none mt-3">
                                {{ t("view_details") }}
                                <i v-if="currentLang === 'ar'" class="far fa-arrow-left"></i>
                                <i v-else class="far fa-arrow-right"></i>
                            </router-link>
                            <br />
                            <router-link to="/pharmacy/registration" class="btn btn-primary mt-2">
                                {{ t("get_started") }}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3 col-md-6 col-sm-12 mt-4 mt-lg-0 mt-md-0">
                    <div
                        class="bg-dark p-5 text-center d-flex flex-column h-100 rounded-custom"
                        data-aos="fade-up"
                        data-aos-delay="200">
                        <div class="promo-card-info mb-4">
                            <h5 class="mb-4">
                                <i class="far fa-fingerprint text-warning me-2"></i>
                                {{ t("laboratoryService") }}
                            </h5>
                            <p class="four-lines-text">
                                {{ t("lab_description") }}
                            </p>
                            <router-link :to="`/services/2`" class="link-with-icon text-decoration-none mt-3">
                                {{ t("view_details") }}
                                <i v-if="currentLang === 'ar'" class="far fa-arrow-left"></i>
                                <i v-else class="far fa-arrow-right"></i>
                            </router-link>
                            <br />
                            <router-link to="/lab/registration" class="btn btn-primary mt-2">
                                {{ t("get_started") }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                currentLang: localStorage.getItem("lang"),
            };
        },
        name: "PromoTwo",
    };
</script>

<style></style>
