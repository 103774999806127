<template>
    <section>
        <h6>{{ t("license_information") }}</h6>
        <VForm v-slot="{ onValidate }">
            <div class="row">
                <div class="col mt-3">
                    <VTextField
                        type="number"
                        v-model="license.number"
                        label="license_number"
                        rules="required|numeric"></VTextField>
                </div>
                <div class="col mt-3">
                    <VDateField v-model="license.date" label="date" rules="required"></VDateField>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-5">
                    <VFileField v-model="license.picture" type=".jpg, .png" label="upload_licence_image"></VFileField>
                </div>
            </div>
            <div class="mt-4 text-center text-danger">
                {{ error ? error : "" }}
            </div>
            <div class="row mt-2">
                <div class="col-12 text-right">
                    <button
                        :disabled="loading || !license.picture"
                        type="submit"
                        @click="onValidate(submitForm)"
                        class="btn btn-primary">
                        {{ loading ? t("loading") : t("send") }}
                    </button>
                </div>
            </div>
        </VForm>
    </section>
</template>

<script>
    import axios from "axios";
    export default {
        name: "LicenseCard",
        props: ["licenseInfoSuccess", "token"],
        data() {
            return {
                error: "",
                loading: false,
                license: {
                    number: "",
                    date: "",
                    picture: "",
                },
            };
        },
        methods: {
            async submitForm() {
                this.loading = true;
                const instance = axios.create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    timeout: 30000,
                    headers: { Authorization: `Bearer ${this.token}` },
                });
                const formData = new FormData();
                const objKeys = Object.keys(this.license);
                const objValues = Object.values(this.license);
                for (let i = 0; i < objKeys.length; i++) {
                    formData.append(objKeys[i], objValues[i]);
                }

                try {
                    const { data } = await instance.post("User/AddLicense", formData);
                    this.loading = false;
                    if (!data.error) {
                        this.licenseInfoSuccess();
                    }
                } catch (error) {
                    if (error.response) {
                        // Request made and server responded
                        this.error = error.response.data.message;
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.error = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.error = error.message;
                    }
                    this.loading = false;
                }
            },
        },
    };
</script>
