import axiosInstance from "../../Utils/axios";

const state = {
    contract: [],
};

const getters = {
    contract: (state) => state.contract,
};
const actions = {
    async fetchContract({ commit }) {
        const response = await axiosInstance.get("Auth/GetContracts");
        commit("setContract", JSON.parse(JSON.stringify(response.data)));
    },
};
const mutations = {
    setContract: (state, contract) => (state.contract = contract),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
