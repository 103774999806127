<template>
    <section>
        <div class="row">
            <div v-if="step === 'LEFT_FINGER'" class="text-center">
                <lottie :options="defaultOptions" :height="200" :width="200" />
                <div class="mt-3">
                    {{ t("left_thumb_print") }}
                </div>
            </div>
            <div v-if="step === 'RIGHT_FINGER'" class="text-center">
                <lottie :options="defaultOptions" :height="200" :width="200" />
                <div class="mt-3">
                    {{ t("right_thumb_print") }}
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Lottie from "./lottie.vue";

    export default {
        name: "FaceId",
        props: ["fingerPrintSuccess"],
        components: {
            lottie: Lottie,
        },
        data() {
            return {
                step: "LEFT_FINGER",
                defaultOptions: { animationData: require("@/assets/animate/FingerPrint.json") },
                leftFingerPrint: "",
                rightFingerPrint: "",
            };
        },
        methods: {
            getRightThumb() {
                this.$store
                    .dispatch("auth/getFingerPrint")
                    .then((data) => {
                        if (data.status === 200) {
                            this.rightFingerPrint = data.data.data.ImageBase64;
                            this.fingerPrintSuccess(this.leftFingerPrint, data.data.data.ImageBase64);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            },
        },
        mounted() {
            this.$store
                .dispatch("auth/getFingerPrint")
                .then((data) => {
                    console.log({ data });
                    if (data.status === 200) {
                        this.leftFingerPrint = data.data.data.ImageBase64;
                        this.step = "RIGHT_FINGER";
                        this.getRightThumb();
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    };
</script>
