import axiosInstance from "../../Utils/axios";

const state = {
    policy: [],
};

const getters = {
    policy: (state) => state.policy,
};
const actions = {
    async fetchPolicy({ commit }) {
        const response = await axiosInstance.get("Auth/GetPolicy");
        commit("setPolicy", JSON.parse(JSON.stringify(response.data)));
    },
};
const mutations = {
    setPolicy: (state, policy) => (state.policy = policy),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
