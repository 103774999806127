<template>
    <div>
        <Navbar />
        <Price />
        <Footer />
    </div>
</template>

<script>
    import Footer from "../components/footer/Footer.vue";
    import Navbar from "../components/nav/Navbar.vue";
    import Price from "../components/pricing/Price.vue";

    export default {
        components: {
            Price,
            Navbar,
            Footer,
        },
        name: "Pricing",
    };
</script>
