<template>
    <section id="mobile" class="app-two-feature-two pt-60 pb-120">
        <div class="container">
            <div class="row align-items-center justify-content-lg-between">
                <div class="col-lg-6 col-xl-6 mt-5 col-md-12">
                    <div class="app-two-feature-two-img">
                        <img
                            style="margin-top: -30px"
                            src="@/assets/images/patient_mobile_feature.png"
                            alt="feature"
                            class="img-fluid" />
                    </div>
                </div>
                <div class="col-xl-5 col-lg-5 mt-5">
                    <div class="app-two-feature-two-right">
                        <div class="feature-content-wrap">
                            <h4 class="h6">{{ t("about_app") }}</h4>
                            <h2>{{ t("afea_mobile_app") }}</h2>
                            <p>
                                {{ t("mobile_app_des_1") }}
                            </p>
                        </div>
                        <div class="app-two-feature-two-content">
                            <ul class="list-unstyled mt-4">
                                <li v-for="feature of features" :key="feature" class="py-1 d-flex">
                                    <i class="fad fa-check-circle me-2"></i>
                                    {{ t(feature) }}
                                </li>
                            </ul>
                            <div class="action-btns mt-4">
                                <ul class="list-unstyled text-center">
                                    <li class="d-inline-block me-2 mb-lg-0">
                                        <a
                                            class="d-flex align-items-center text-decoration-none rounded"
                                            href="https://apps.apple.com">
                                            <i class="fab fa-apple pe-2"></i>
                                            <span>
                                                {{ t("available_on_the") }}
                                                <span>App Store</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li class="d-inline-block me-2 mb-lg-0">
                                        <a
                                            target="_blank"
                                            class="d-flex align-items-center text-decoration-none rounded"
                                            href="https://play.google.com/store/apps/details?id=health.afea&pli=1">
                                            <i class="fab fa-google-play pe-2"></i>
                                            <span>
                                                {{ t("available_on_the") }}
                                                <span>Google Play</span>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "ImgContentNine",
        data() {
            return {
                features: [
                    "feat1",
                    "feat2",
                    "feat3",
                    "feat4",
                    "feat5",
                    "feat6",
                    "feat7",
                    "feat8",
                    "feat9",
                    "feat10",
                    "feat11",
                ],
            };
        },
    };
</script>
