export default {
    props: {
        label: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        disabled: {
            type: [Function, Boolean],
        },
        readonly: {
            type: [Function, Boolean],
            default: false,
        },
        rules: {
            required: false,
            default: "",
        },
        options: {
            required: false,
            default: () => {
                return {};
            },
        },
        value: {},
        show: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        handleChange(event) {
            if (typeof this.options.change === "function") return this.options.change(event);
            return false;
        },
        handleInput(event) {
            if (typeof this.options.input === "function") return this.options.input(event);
            return false;
        },
        handleClick(event) {
            if (typeof this.options.click === "function") return this.options.click(event);
            return false;
        },
        handleKeyup(event) {
            if (typeof this.options.keyup === "function") return this.options.keyup(event);
            return false;
        },
        handleKeydown(event) {
            if (typeof this.options.keydown === "function") return this.options.keydown(event);
            return false;
        },
        handleEvent(type) {
            switch (type) {
                case "change":
                    this.handleChange(event);
                    break;
                case "input":
                    this.handleInput(event);
                    break;
                case "click":
                    this.handleClick(event);
                    break;
                case "keyup":
                    this.handleKeyup(event);
                    break;
                case "keydown":
                    this.handleKeydown(event);
                    break;
            }
        },
    },
};
