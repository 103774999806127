import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueAwesomeSwiper from "vue-awesome-swiper";
import CoolLightBox from "vue-cool-lightbox";
import mixins from "./plugins/mixins";
import store from "./store";
import OtpInput from "@bachdgvn/vue-otp-input";
import Multiselect from "vue-multiselect";
import VueCountdown from "@chenfengyuan/vue-countdown";
import device from "vue-device-detector";
import "./components";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import "@/plugins";

// register globally
Vue.component("multiselect", Multiselect);
Vue.component(VueCountdown.name, VueCountdown);
Vue.component("v-otp-input", OtpInput);
Vue.use(VueAwesomeSwiper);
Vue.use(VueTelInput);
Vue.use(CoolLightBox);
Vue.use(device);
Vue.mixin(mixins);

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    render: (h) => h(App),
    beforeCreate: () => {
        import("./plugins/styleBuilder"), store.dispatch("auth/governorates");
        store.dispatch("auth/districts");
        store.dispatch("auth/nationalties");
        store.dispatch("auth/languages");
        store.dispatch("doctor/specialties");
        store.dispatch("doctor/generalSpecialties");
    },
}).$mount("#app");
