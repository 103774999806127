<template>
    <ValidationProvider
        v-slot="{ errors }"
        :vid="randomName"
        :name="label"
        class="form-group"
        :disabled="disabled"
        :rules="rules"
        tag="div"
        mode="eager">
        <label v-if="label && !hideLabel" :for="randomName">{{ t(label) }}</label>
        <span v-if="label && rules.includes('required')" class="text-sm text-danger mx-1">*</span>

        <input
            type="date"
            onkeydown="return false"
            v-model="field"
            :placeholder="t(placeholder)"
            class="form-control picker"
            :max="now" />
        <small class="text-danger">{{ errors[0] }}</small>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from "vee-validate";
    import fieldMixins from "@/mixins/fieldMixins";

    export default {
        name: "VDateField",
        components: {
            ValidationProvider,
        },
        mixins: [fieldMixins],
        props: {
            label: String,
            hideLabel: Boolean,
        },
        data() {
            const now = new Date().toISOString().split("T")[0];

            return {
                now: now,
            };
        },
        computed: {
            isDisabled() {
                if (typeof this.disabled === "function") return this.disabled();
                return this.disabled;
            },
            field: {
                get() {
                    return this.value ? this.value.split("/").reverse().join("/") : null;
                },
                set(value) {
                    // eslint-disable-next-line no-console
                    this.$emit("input", value);
                },
            },
            randomName() {
                return Math.random().toString(36).substring(7);
            },
        },
        watch: {
            value() {
                this.field = this.value;
            },
        },
    };
</script>

<style>
    .menu__content {
        z-index: 9999 !important;
    }

    .datepicker__input-icon {
        margin-top: 6px;
    }

    .datepicker__input-wrapper {
        padding-right: 5px;
    }
</style>
