<template>
    <div class="col-md-12 text-center mb-3">
        <div class="testimonial-tab-slider-wrap">
            <h1 class="fw-bold text-white display-5">
                {{ t("app_name") }}
            </h1>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RegisterationHeader",
        data() {
            return {};
        },
    };
</script>
