var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{staticClass:"form-group",attrs:{"vid":_vm.randomName,"name":_vm.label,"disabled":_vm.disabled,"rules":_vm.rules,"tag":"div","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(_vm.label && !_vm.hideLabel)?_c('label',{attrs:{"for":_vm.randomName}},[_vm._v(_vm._s(_vm.t(_vm.label)))]):_vm._e(),(_vm.rules.includes('required'))?_c('span',{staticClass:"text-sm text-danger mx-1"},[_vm._v("*")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.field),expression:"field"}],staticClass:"form-control picker",attrs:{"type":"date","onkeydown":"return false","placeholder":_vm.t(_vm.placeholder),"min":_vm.min
                .toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                })
                .split('/')
                .reverse()
                .join('-'),"max":_vm.max
                .toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                })
                .split('/')
                .reverse()
                .join('-')},domProps:{"value":(_vm.field)},on:{"input":function($event){if($event.target.composing)return;_vm.field=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }