var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"feature-section two-bg-dark-light ptb-120",attrs:{"id":"features"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center justify-content-between"},[_vm._m(0),_c('div',{staticClass:"col-lg-5 col-md-6"},[_c('div',{staticClass:"feature-content-wrap",attrs:{"data-aos":"fade-up","data-aos-delay":"50"}},[_c('h4',{staticClass:"h5 text-primary"},[_vm._v(_vm._s(_vm.t("our_services")))]),_c('h2',[_vm._v(_vm._s(_vm.t("system_features")))]),_c('p',[_vm._v(" "+_vm._s(_vm.t("afea_description"))+" ")]),_c('ul',{staticClass:"list-unstyled d-flex flex-wrap list-two-col mt-4"},[_c('li',{staticClass:"d-flex align-items-start"},[_vm._m(1),_c('div',{staticClass:"icon-content mt-2"},[_c('h3',{staticClass:"h5"},[_vm._v(_vm._s(_vm.t("pharmacies_management")))])])]),_c('li',{staticClass:"d-flex align-items-start"},[_vm._m(2),_c('div',{staticClass:"icon-content mt-2"},[_c('h3',{staticClass:"h5"},[_vm._v(_vm._s(_vm.t("laboratory_management")))])])]),_c('li',{staticClass:"d-flex align-items-start"},[_vm._m(3),_c('div',{staticClass:"icon-content mt-2"},[_c('h3',{staticClass:"h5"},[_vm._v(_vm._s(_vm.t("online_booking")))])])]),_c('li',{staticClass:"d-flex align-items-start"},[_vm._m(4),_c('div',{staticClass:"icon-content mt-2"},[_c('h3',{staticClass:"h5"},[_vm._v(_vm._s(_vm.t("electronic_payment")))])])]),_c('li',{staticClass:"d-flex align-items-start"},[_vm._m(5),_c('div',{staticClass:"icon-content mt-2"},[_c('h3',{staticClass:"h5"},[_vm._v(_vm._s(_vm.t("appointment_reminder")))])])]),_c('li',{staticClass:"d-flex align-items-start"},[_vm._m(6),_c('div',{staticClass:"icon-content mt-2"},[_c('h3',{staticClass:"h5"},[_vm._v(_vm._s(_vm.t("reduce_medical_errors")))])])]),_c('li',{staticClass:"d-flex align-items-start"},[_vm._m(7),_c('div',{staticClass:"icon-content mt-2"},[_c('h3',{staticClass:"h5"},[_vm._v(_vm._s(_vm.t("medical_record_management")))])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{staticClass:"image-wrap mb-5 mb-md-0 mb-lg-0 mb-xl-0",attrs:{"data-aos":"fade-right"}},[_c('img',{staticClass:"img-fluid rounded-custom",attrs:{"src":require("@/assets/img/screen/clinic/clinic2.png"),"alt":"feature img"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box bg-primary rounded me-4"},[_c('i',{staticClass:"fas fa-capsules text-white"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box bg-info rounded me-4"},[_c('i',{staticClass:"fas fa-flask text-white"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box bg-info rounded me-4"},[_c('i',{staticClass:"fa-solid fa-calendar-days text-white"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box bg-warning rounded me-4"},[_c('i',{staticClass:"fa-solid fa-credit-card-front text-white"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box bg-primary rounded me-4"},[_c('i',{staticClass:"fa-solid fa-calendar-days text-white"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box bg-danger rounded me-4"},[_c('i',{staticClass:"fa-solid fa-triangle-exclamation text-white"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box bg-info rounded me-4"},[_c('i',{staticClass:"fas fa-book text-white"})])
}]

export { render, staticRenderFns }