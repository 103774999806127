<template>
    <section class="feature-section ptb-120">
        <div class="container">
            <ServiceRow orderType="order-lg-1" :details="clinicFeat1" />
            <ServiceRow orderType="order-lg-2" :details="clinicFeat2" />
            <ServiceRow orderType="order-lg-1" :details="clinicFeat3" />
            <ServiceRow orderType="order-lg-2" :details="clinicFeat4" />
        </div>
    </section>
</template>

<script>
    import ServiceRow from "./ServiceRow.vue";

    export default {
        name: "ClinicServices",
        components: {
            ServiceRow,
        },
        data() {
            return {
                clinicFeat1: {
                    image: "clinic/clinic2.png",
                    title: "clinic_management_detail2_title",
                    features: [
                        "clinic_management_detail2_feat1",
                        "clinic_management_detail2_feat2",
                        "clinic_management_detail2_feat3",
                        "clinic_management_detail2_feat4",
                    ],
                },
                clinicFeat2: {
                    image: "clinic/clinic1.png",
                    title: "clinic_management_detail1_title",
                    features: [
                        "clinic_management_detail1_feat1",
                        "clinic_management_detail1_feat2",
                        "clinic_management_detail1_feat3",
                    ],
                },
                clinicFeat3: {
                    image: "clinic/clinic3.png",
                    title: "clinic_management_detail3_title",
                    features: ["clinic_management_detail3_feat1", "clinic_management_detail3_feat2"],
                },
                clinicFeat4: {
                    image: "clinic/clinic4.png",
                    title: "clinic_patient_details_question",
                    features: [
                        "clinic_patient_details_answer1",
                        "clinic_patient_details_answer2",
                        "clinic_patient_details_answer3",
                        "clinic_patient_details_answer4",
                        "clinic_patient_details_answer5",
                        "clinic_patient_details_answer6",
                        "clinic_patient_details_answer7",
                        "clinic_patient_details_answer8",
                    ],
                },
            };
        },
    };
</script>
