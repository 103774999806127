<template>
    <ValidationProvider
        v-show="show"
        v-slot="{ errors }"
        class="form-group"
        :name="label"
        :disabled="disabled"
        :rules="rules"
        tag="div"
        mode="eager">
        <div class="custom-file h-100 text-center mb-2">
            <label class="custom-file-upload">
                <img
                    v-if="label === 'certificateImage' || placeholder == 'wizard'"
                    :src="value ? image : camera"
                    height="200"
                    class="w-100 mb-3" />
                <img v-else :src="value ? image : camera" width="100" height="100" class="rounded-circle mb-3" />
                <br />
                <span>{{ value ? value.name : t(label) }}</span>
                <input
                    type="file"
                    :disabled="isDisabled"
                    :class="{ 'form-control': true, 'is-invalid': errors.length }"
                    :accept="type"
                    @change="fileHandler" />
            </label>
        </div>
        <div class="invalid-feedback">{{ errors[0] }}</div>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from "vee-validate";
    import fieldMixins from "@/mixins/fieldMixins";
    import { ImageURL } from "@/Utils/helpers";

    export default {
        name: "VFileField",
        components: {
            ValidationProvider,
        },
        data() {
            return { editing: false };
        },
        mixins: [fieldMixins],
        props: {
            type: {
                type: String,
                default: "text",
            },
        },
        computed: {
            isDisabled() {
                if (typeof this.disabled === "function") return this.disabled();
                return this.disabled;
            },
            field: {
                get() {
                    return this.image_path(this.value);
                },
                set(value) {
                    this.$emit("input", value);
                },
            },
            image() {
                if (typeof this.value === "string") {
                    if ((this.value && this.value.includes("data:image")) || this.value.length > 350) return this.value;
                    return this.image_path(this.value);
                }
                return URL.createObjectURL(this.value);
            },
            camera() {
                return this.label === "certificateImage" || this.placeholder == "wizard"
                    ? ImageURL("cover.jpg")
                    : ImageURL("upload.jpg");
            },
        },
        watch: {
            value() {
                this.field = this.value;
            },
        },
        methods: {
            fileHandler($event) {
                this.field = $event.target.files[0];
                this.$emit("change", $event);
            },
        },
    };
</script>
<style scoped>
    input {
        display: none;
    }
</style>
