<template>
    <div>
        <Navbar />
        <BreadCrumb
            :title="t('app_name')"
            desc="نظام عافية الصحي  هو منصة تكاملية تعزز الوصول إلى الخدمات الصحية، وتسهل البحث عن مقدمي الخدمات وحجز المواعيد. يمكن للمرضى المشتركين  تتبع طلبات الاسترداد والتأكد من حصولهم على الرعاية المستحقة." />
        <Features hasTitle="hasTitle" bgLight="bgLight" />
        <!-- <TestimonialSlider /> -->
        <!-- <CtaTwo /> -->
        <Footer />
    </div>
</template>

<script>
    import BreadCrumb from "../components/common/BreadCrumb.vue";
    import CtaTwo from "../components/cta/CtaTwo.vue";
    import Features from "../components/features/Features.vue";
    import Footer from "../components/footer/Footer.vue";
    import Navbar from "../components/nav/Navbar.vue";
    // import TestimonialSlider from "../components/testimonial/TestimonialSlider.vue";
    export default {
        components: {
            Navbar,
            BreadCrumb,
            Footer,
            CtaTwo,
            // TestimonialSlider,
            Features,
        },
    };
</script>

<style></style>