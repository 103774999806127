export default {
    ar: {
        phone: "رقم الهااااتففف",
        app_name: "نظام عافية الصحي",
        app_slogan: "عافية … رعاية لاتنتهي",
        services_title: "أفضل الخدمات",
        services_subtitle: "لتلبية احتياجات عملك",
        Language: "اللغة",
        clinicService: "العيادة الطبية",
        pharmacyService: "الصيدلية",
        laboratoryService: "المختبر الطبي",
        patientService: "السجل الطبي للمريض",
        register: "تسجيل",
        verify: "تأكيد",
        registerNow: "سجل الان",
        login: "تسجيل الدخول",
        choose_service_type: "اختر نوع الخدمة",
        hospital: "المستشفى",
        pharmacy: "الصيدلية",
        laboratory: "المختبر",
        contract: "العقد",
        upload_signature: "رفع صورة التوقيع",
        upload_signature_image: "رفع صورة التوقيع",
        open_camera_take_live_photo: "افتح الكامرة و التقط صورة حية",
        card_number: "رقم البطاقة",
        retake: "اعادة",
        confirm: "تأكيد",
        enter_otp: "ادخل الرمز الذي ارسلناه الى رقم هاتفك",
        choose_package_type: "اختر نوع الأشتراك",
        discount: "تخفيض",
        basic: "اعتيادي",
        advanced: "متقدم",
        privacy_policy_and_the_terms_of_use: "سياسة الخصوصية و شروط الأستخدام",
        poilcy_agreement: "نعم ، أود الموافقة على شروط سياسة الخصوصية وشروط الاستخدام",
        choose_product_type: "اختر نوع الاشتراك",
        iqd: "دينار عراقي",
        payment: "الدفع",
        card_expire: "تاريخ انتهاء البطاقة",
        ccv: "ccv",
        service_information: "معلومات الخدمة",
        name: "الأسم",
        phone_number: "رقم الهاتف",
        address: "العنوان",
        do_you_have_qi_card: "هل لديك بطاقة كي كارد؟",
        yes: "نعم",
        no: "كلا",
        previous: "رجوع",
        next: "التالي",
        send: "ارسال",
        user_account_information: "معلومات حساب المستخدم",
        governorate: "المحافظة",
        licence: "الاجازة",
        licence_number: "رقم الأجازة",
        date: "التاريخ",
        upload_licence_image: "رفع صورة الأجازة",
        get_started: "سجل الآن",
        home: "الرئيسية",
        sponsers: "الشركات الراعية",
        contact_us: "تواصل معنا",
        services: "خدماتنا",
        registration: "التسجيل",
        afea_services: "خدمات عافية",
        about_app: "عن التطبيق",
        afea_mobile_app: "تطبيق عافية للموبايل",
        available_on_the: "متوفر على",
        afea_healthcare_sponser: "رعاة نظام عافية الصحي",
        alrafidin_bank: "مصرف الرافدين",
        qi_card: "كي كارد",
        bait_altamen: "شركة بيت التأمين",
        afea_rights_reserved: "جميع الحقوق محفوظة لعافية",
        pricing: "الأسعار",
        support: "الدعم",
        our_team: "فريق العمل",
        faq: "الأسئلة المتداولة",
        fast_links: "روابط مهمة",
        afea_description:
            "اختيارك الافضل لإنشاء وإدارة السجل الطبي والذي يحتوي على جميع بيانات الزيارات الطبية المتمثله بالتشخيصات والعلاج والتحاليل الطبية داخل المؤسسات الصحية من المستشفيات والعيادات والصيدليات والمختبرات",
        afea_body_description:
            "اختيارك الافضل لإنشاء وإدارة السجل الطبي والذي يحتوي على جميع بيانات الزيارات الطبية المتمثله بالتشخيصات والعلاج والتحاليل الطبية داخل المؤسسات الصحية من المستشفيات والعيادات والصيدليات والمختبرات		",
        clinic_description:
            "نظام متكامل لانشاء وادارة السجل الطبي للمريض لامكانيته بجمع بيانات الزيارات الطبية والعلاج والتحاليل وبيانات المريض السابقة بجميع المؤسسات والعيادات التي تعمل بالنظام كذلك الاستفادة من الاحصائيات والتقارير وادارة العاملين في العيادة من اطباء وممرضين وسكرتارية.",
        pharmacy_description:
            "يوفر النظام الوصفة الالكترونية التي تقلل من أخطاء وغموض أسماء الادوية في مثليتها اليدوية والتي تسهل من عمل الصيدلي وكذلك إدارة الأدوية الموجودة في الصيدلية والتواصل السهل مع الطبيب وادارة الحسابات والحركات المالية للصيدلية مع اتمتة كاملة في الادارة والمبيعات.",
        lab_description:
            "يوفر النظام وصول طلبات التحاليل بسرعة فائقة الى المختبرات وتقليل الوقت وكذلك إيصال النتائج مباشرة من خلال إضافتها الى الملف المرضي للمريض	كذلك ادارة الحسابات والحركات المالية للمختبر	مع اتمتة كاملة في الادارة والمبيعات والحجز الالكتروني للمرضى.",
        mobile_app_des_1:
            "يستطيع الطبيب استخدام تطبيق الموبايل بدلا من جهاز الكمبيوتر او استخدامهما معاً لادارة مرضاه واستقبال طلبات الحجز الالكتروني واستعراض السجل الصحي للمريض واضافة التشخيص والعلاج وادارة العيادة والعاملين فيها.",
        mobile_app_des_2:
            "يمكن للمريض استخدام تطبيق الهاتف المتحرك لإدارة موعده والتذكير واستلام نتائج التحاليل الطبية الإلكترونية وعرض الوصفات الطبية ومراجعة سجله الصحي.",
        feat1: "استعراض وادارة الزيارات والسجل الصحي للمرضى",
        feat2: "ادارة العيادة وبيانات العمل",
        feat3: "ادارة الحجز الالكتروني",
        feat4: "التشخيص والوصفة الطبية",
        feat5: "الحسابات والفواتير",
        feat6: "العروض الطبية والخصومات",
        feat7: "التقارير والاحصائيات",
        feat8: "ترحيل المرضى لاطباء ادق اختصاصاً",
        feat9: "استقبال تقييم وآراء المرضى",
        feat10: "طلب العلاج والتحاليل من الصدليات والمختبرات",
        feat11: "ذاكرة خزن سحابية وسرعة في الوصول الى البيانات",
        qi_card_des:
            "الشركة العالمية للبطاقة الذكية كي كارد وماستر كي الرائدة والمختصة في القطاع المالي والمصرفي والتي تقدم افضل الحلول في مجال الدفع الالكتروني في العراق",
        alrafidin_bank_des:
            "يعتبر من اهم المصارف العراقية لما يمتلكه من تقنيات متطورة وتسهيلات مصرفية لحاملي بطاقة ماستر كي اضافةً لخدمات الدفع الالكتروني للقطاعين الحكومي والاهلي",
        bait_altamen_des:
            "من كبرى الشركات لإدارة خدمات الرعاية الصحية وتقديم الدعم اللازم لكافة الدوائر وشركات التأمين الصحي وصناديق التكافل الاجتماعي في القطاع العام والخاص",
        system_features: "مميزات النظام",
        hospital_management: "ادارة المستشفى",
        medical_record_management: "إدارة السجل الطبي",
        reduce_medical_errors: "تقليل الاخطاء الطبية",
        pharmacies_management: "ادارة الصيدليات",
        laboratory_management: "ادارة المختبرات",
        online_booking: "الحجز الالكتروني",
        treatment_priority: "الأولوية بالعلاج",
        electronic_payment: "الدفع الألكتروني",
        appointment_reminder: "تذكير بالمواعيد",
        reducing_treatment_expenses: "تقليل نفقات العلاج",
        medical_loans_and_advances: "القروض و السلف الطبية",
        afea_des2:
            "المشروع هو نواة لإدارة جميع المؤسسات الطبية بجميع النواحي الإدارية والصحية التي تشمل علاقة المرضى والاطباء والموظفين بالمؤسسات الصحية. المنتج متوفر على جميع المنصات ولدى جميع المستخدمين الممثلين بالمرضى والكادر الصحي والمؤسسات التي تشمل الموظفين الإداريين وغيرهم، يحتوي التطبيق على الذكاء الكافي لتقديم المقترحات والآراء لجميع المستخدمين وكذلك يستطيع التطبيق من التواصل مع المؤسسات في الحالة الحرجة لطلب فرق الدعم وتنبيه المرضى من خلال التقارير الصحية. سيقوم النظام بتوفير احصائيات وتقارير تسهل عمل جميع المؤسسات والكادر الطبي وكذلك توفير بعض التقارير الخاصة للمريض الذي من شأنها تسهيل معرفة حالته المريضية.",
        hospital_management_des:
            "إنشاء وإدارة نظام معلومات متكامل وشامل لإدارة الجوانب المالية والإدارية و السريرية للمستشفيات. يساعد النظام في التحكم في البيانات في معظم أقسام الرعاية الصحية ، المالية ، المرضى، غرفة العمليات ، التمريض ، الأشعة ، المختبرية ، العيادات الخارجية و الصيدليات.		",
        medical_record_management_des:
            "إنشاء وإدارة السجل الطبي للمريض الذي يحتوي على جميع بيانات الزيارة والتحاليل والعلاج إضافة الى السجل الطبي والعائلي للمرضى مثل الأمراض الوراثية, التحسس, التطعيمات وكذلك جميع الحركات المرضية داخل المؤسسات الصحية.		",
        reduce_medical_errors_des:
            "من خلال وجود أغلب بيانات الأدوية والأمراض والتحاليل التي تقلل من الأخطاء البشرية وكذلك توفر بيانات المريض التي تسمح للطبيب بتوفير الوقت والجهد في تشخيص الحالة المرضية.		",
        pharmacies_management_des:
            "حيث يوفر النظام الوصفة الالكترونية التي تقلل من أخطاء وغموض أسماء الادوية في مثليتها اليدوية والتي تسهل من عمل الصيدلي وكذلك إدارة الأدوية الموجودة في الصيدلية والتواصل السهل مع المذاخر توفير المستلزمات اللازمة.		",
        laboratory_management_des:
            "حيث يوفر النظام وصول طلبات التحاليل بسرعة الى المختبرات وتقليل الوقت وكذلك إيصال النتائج مباشرة من خلال إضافتها الى الملف المرضي للمريض وأيضا سهولة الاطلاع عليها وعرض مخططات توضيحية للطبيب مما يسهل عليه اتخاذ القرار الصحيح في التشخيص المرضي للمريض.		",
        online_booking_des:
            "يسمح النظام للمرضى بالحجز الالكتروني عن طريق بطاقات الدفع الالكتروني دون العناء والذهاب الى المؤسسة الصحية وكذلك سيوفر النظام إمكانية حجز عربات الأجرة للتنقل الى تلك المؤسسات.		",
        treatment_priority_des:
            "حيث يقدم النظام تطبيق للمريض يستطيع من خلاله معرفة العلاج والتحاليل وكذلك يوفر النظام الاتصال الأمثل بين الأطباء والصيادلة والمحللين حيث يقل عامل الوقت في وصول طلبات الطبيب وبالتالي زيادة سرعة الاستجابة وتوفير الوقت والجهد.		",
        appointment_reminder_des: "من مميزات تطبيق المريض، هو تذكير بمواعيد الدواء والزيارة وجدولتها.		",
        reducing_treatment_expenses_des:
            "وذلك حيث سيوفر النظام إمكانية التواصل بين المريض والطبيب الكترونيا وذلك سيقلل الجهد والوقت من خلال اطلاع الطبيب على التحاليل والأشعة السابقة.		",
        medical_loans_and_advances_des:
            "من خلال بطاقات الدفع الالكتروني للمتوطنة رواتبهم ومن خلال تنسيق شركتنا مع مصرف الرافدين وفرنا خدمة السلف والقروض العالجية.		",
        electronic_payment_des:
            "من خلال البطاقات الدفع الالكتروني يستطيع المريض الدفع للمؤسسة الصحية وكذلك يستطيع المرضى الدفع بالأجل للموظفين المتوطنة رواتبهم وكذلك تقسيط الدفع واستلام السلف الطبية من خلال ضمان الراتب الشهري او صندوق الضمان التابع الى الجهة الموظف لديها المريض.		",
        features: "المميزات",
        pricingAndSubscriptions: "الأسعار و الأشتراكات",
        plans: "الخطط والتسعير",
        clinicSubscriptions: "اشتراك العيادات",
        pharmacySubscriptions: "اشتراك الصيدليات",
        labSubscriptions: "اشتراك المختبرات",
        subscribe: "اشترك",
        duration: "فترة الأشتراك",
        trailDuration: "فترة تجريبية",
        view_details: "اظهار التفاصيل",
        our_services: "خدماتنا",
        clinic_management: "ادارة العيادات الطبية",

        clinic_management_detail1_title: "هل تواجه صعوبة في إدارة العيادة والمواعيد الخاصة بك ؟",
        clinic_management_detail1_feat1:
            "يمكنك الآن إدارة العيادة الطبية الخاص بكل سهولة بدءا من تعريف الملف الخاص بالطبيب ومعلومات العيادة والعناوين الخاصة بها،",
        clinic_management_detail1_feat2:
            "كذلك تحديد جدول ايام وساعات العمل،  تغيير أو تأجيل المواعيد اذا اضطر الامر لذلك بخطوة واحدة واشعار المرضى بذلك،",
        clinic_management_detail1_feat3: "اضافة مدير العيادة الخاص بك ليتسنى له القيام بمهام أخرى وغيرها الكثير",

        clinic_management_detail2_title:
            " هل تعتمد على التقارير الدورية لمتابعة العيادة الطبية الخاصة بك والوضع الصحي للمرضى ؟",
        clinic_management_detail2_feat1:
            "يمكنك الآن طلب تقرير طبي شامل عن وضع المريض والعلاج المصروف والمتبع ونتائج التحاليل والأشعة الطبية السابقة",
        clinic_management_detail2_feat2:
            "تقرير التاريخ الطبي للمريض الإصابات، العمليات، التطعيمات السابقة وغيرها من المعلومات التاريخ الطبي",
        clinic_management_detail2_feat3: "تقرير الامراض الاكثر تشخيصاً ومعالجه من قبل العيادة الخاصة بك",
        clinic_management_detail2_feat4:
            "تقرير نسب ومعدلات المواعيد بتصنيف اسبوعي، شهري، سنوي أو قيم بتحديد التاريخ حسب الإختيار",

        clinic_management_detail3_title: "هل لديك عيادات متعددة وتواجه صعوبة في إدارتها ؟",
        clinic_management_detail3_feat1:
            "عافيه نظام طبيه يهدف الى توثيق جميع الحركات الطبية للمرضى في المستشفيات، العيادات الخاصة، والصيدليات، والمختبرات وعيادات الأشعة ليتسنى له تقديم نظرة واسعة عن وضع المريض الى الطبيب قبل اعطاء اي تشخيص او صرف للعلاج",
        clinic_management_detail3_feat2:
            "يمكن للطبيب اضافه جميع العيادات الطبية الخاصة به بصوره منفصله عن بعضها الاخرى وتعيين مدير عيادة او سكرتير مشترك او منفصل لإدارة المواعيد والملف الخاص به او ادراته شخصياً كذاك متابعة حالة المرضى الخاصين بكل عياده ",

        clinic_faq_q1: "ما هي طرق الدفع ؟",
        clinic_faq_a1:
            "إذا كنت تفضل إدارة العيادة الطبية الخاصة بك، المواعيد والدفع، ملف وتاريخ المرضى دون الاستعانة بمدير عياده او سكرتير، نظام عافيه الطبي الحل الأمثل لك يمكنك الآن قبول، رفض تغيير تواريخ وأوقات المواعيد بخطوه واحده بدون الحاجة الى الاتصال بالمريض كما يمكنك اضافة بطاقتك الالكترونيه ليتسنى لك استقبال مبالغ الحجوزات الكترنياً بكل سهوله",

        clinic_faq_q2: "كيف يمكن تقديم العروض الطبية او اعفاء المريض من الرسوم ؟",
        clinic_faq_a2:
            "عن طريق نظام 'عافيه' يمكنك الإعلان عن العروض الطبية بكل سهولة ومعرفة المرضى المهتمين بها سيتم إشعار المريض بجميع العروض الطبية المقدمة من الأطباء والعيادات كذلك يمكنك إعفاء المريض من جزء أو كامل رسوم الحجز لبناء علاقة انسانية مع المرضى والتخفيف عن المرضى",

        clinic_faq_q3: "كيف يمكنني معرفة جودة الخدمة المقدمة للمرضى ؟",
        clinic_faq_a3:
            "يهتم نظام عافيه الى تطوير وتحسين الواقع الطبي ومعرفة مشاكل وعيوب النظام الحالي المتبع وتقديم حل معظم أو جميع المشاكل .. يمكن للمريض الآن تقييم خدمات كل من الأطباء، المختبرات، الصيدليات واضافه المشاكل او مقترح لتحسين الخدمه ليتسنى لهم الأخذ بها وتجنبها",

        clinic_faq_q4: "هل يمكن للنظام إرسال إشعارات ؟",
        clinic_faq_a4:
            "يوفر النظام خدمة ارسال الاشعارات لكل الحركات والتفاصيل والعمليات التي يجريها مستخدم النظام إذا كان طبيب , صيدلي , مختبري , مريض",

        clinic_faq_q5: "هل النظام ذكي ؟",
        clinic_faq_a5:
            "يستطيع النظام التنبؤ أكثر الأمراض انتشارا ووفقا للرقعة الجغرافية واعمار المرضى بالتالي توفير الوقت والجهد عن طريق تصدير الإحصائيات والتقارير للحد والسيطرة على الأمراض فور انتشارها كذلك إصدار التقارير بالتفاصيل والتواريخ التي يتم اختيارها من قبل مستخدم النظام",

        clinic_faq_q6: "هل يمكن توثيق زيارات المرضى ؟",
        clinic_faq_a6:
            "جميع المعلومات المضافه للسجل الطبي للمريض يتم توثيقها الكترونيا بكفاءة وحماية عالية ليتسنى لك عرض الزيارات بسهوله في اي وقت عن طريق تطبيق  'عافيه' الخاص بالطبيب و لوحة التحكم الخاصة بك كذلك عرض ملفات التشخيص لكل المريض ليتسنى لك مراجعة تقدم الحالة ومدى تأثير العلاج المتبع",

        clinic_patient_details_question:
            "ما هي المعلومات التي تتطلب معرفتها من المريض قبل إعطاء التشخيص ؟ وكم تستغرق من وقت ؟",
        clinic_patient_details_answer1:
            "يحتوي السجل الطبي للمريض سجل الأمراض، تاريخ الإصابات، العمليات الجراحية، التطعيمات التي حصل عليها، التحسس الدوائي، الأدوية المتبعة، التحاليل الطبية، العادات الخاص بالمريض كالتدخين وغيرها",
        clinic_patient_details_answer2:
            "مما يؤدي إلى إعطاء معلومات دقيقة الى الطبيب عن وضع المريض والتي تساهم في دقة وكفاءة التشخيص",
        clinic_patient_details_answer3: "كما يمكن للطبيب إدارة الجلسة الطبية بكفاءة كما لم تكن من قبل",
        clinic_patient_details_answer4: "صرف العلاج والوصفات الطبية ومتابعة حالة صرف العلاج البديل للمريض",
        clinic_patient_details_answer5: "إدارة التشخيصات وملف التشخيص",
        clinic_patient_details_answer6: "طلب التحاليل الطبية للمريض ومتابعة نتائجها",
        clinic_patient_details_answer7: "إدارة تحويل المريض الى طبيب اخصائي اخر",
        clinic_patient_details_answer8:
            "إدارة طلب تشخيص من طبيب آخر ومتابعة حالة المريض وغيرها الكثير من المميزات التي تساهم في كفاءة التشخيص واختصار الوقت للطبيب",

        pharmacy_management_detail1_title: "ماذا يقدم نظام عافيه للصيدلي ؟",
        pharmacy_management_detail1_feat1:
            "يمكنك رفع جميع الوثائق والشهادات واجازة الصيدلية الى النظام وخزنها مع امكانية عرضها",
        pharmacy_management_detail1_feat2: "تثبيت موقع الصيدلة على الخريطة ليتسنى لمستخدمين النظام العثور عليها",
        pharmacy_management_detail1_feat3: "امكانية ضبط وتعديل مواعيد وساعات العمل والعطل",
        pharmacy_management_detail1_feat4: "انشاء وتعديل حسابات المستخدمين للنظام داخل الصيدلية بحسب صلاحياتهم",
        pharmacy_management_detail1_feat5: "اضافة جميع الادوية المتوفرة داخل الصيدلية للنظام مع امكانية التعديل عليها",

        pharmacy_management_detail2_title: "ما هي آلية صرف العلاج ؟",
        pharmacy_management_detail2_feat1:
            "يستطيع الصيدلي الولوج للنظام وادخال الرقم الصحي للمريض بعد الحصول على هذه الصلاحيه حيث ستعرض له الادويه التي تم وصفها من قبل الطبيب ليقوم بصرفها مع ضبط العدد وجرعة الاستخدام والتعليمات بكل سهولة",

        pharmacy_management_detail3_title: "هل يمكن للنظام اصدار تقارير ؟",
        pharmacy_management_detail3_feat1:
            "يمكنك النظام من استعراض وتصدير احصائيات وتقارير بجميع التفاصيل والتواريخ الخاصة بحركات الصيدلية من بيانات الادوية وصرفها ومبالغها وأعدادها والمرضى الذين تم التعامل معهم مع امكانية ضبط تاريخ التقرير ليوم او شهر او سنة او حسب ضبط التاريخ ",

        pharmacy_faq_q1: "هل يمكن للصيدلي مراجعة ماتم صرفه من العلاجات ؟",
        pharmacy_faq_a1:
            "يقوم النظام باستعراض جميع المرضى الذين تم صرف العلاج لهم بالتواريخ والتفاصيل فلا تهتم لتلف او ضياع السجلات الورقية سيتكفل النظام بخزن هذه المعلومات واستعراضها في اي وقت",

        pharmacy_faq_q2: "هل يوفر النظام الدفع الالكتروني ؟",
        pharmacy_faq_a2:
            "يوفر النظام امكانية الدفع كاش والدفع الالكتروني ببطاقات الدفع ليتم تحويل المبالغ للمحفظة الالكترونية للصيدلة واصتحصال المبالغ بكل سهولة مع بيان تقارير بجميع الحركات المالية",

        pharmacy_faq_q3: "هل يمكن تقديم العروض على العلاج او المستلزمات الطبية ؟",
        pharmacy_faq_a3:
            "بالتاكيد سيوفر لك النظام نشر العروض الخاصه بك من خصومات او تخفيض والاعلان عن مستلزماتك الطبية بطريقة ملفته وفريده حيث ستتمكن من رفع صور للعروض وضبط المدة لها بكل سهولة",

        pharmacy_faq_q4: "ماذا لو اردت عمل خصم خاص لمريض معين ؟",
        pharmacy_faq_a4:
            "النظام له القدرة على اضافة خصم بنسبة مئوية او مبلغ ثابت لأي مريض يتم اختياره او اعفاءه من الدفع وكل هذا سيتم توثيقه بالاحصائيات والتقارير لاهميتها اثناء تدقيق حسابات الصيدلية",

        pharmacy_faq_q5: "كيف يمككني معرفة جودة الخدمة المقدمة للمرضى ؟",
        pharmacy_faq_a5:
            "يهتم نظام عافيه الى تطوير وتحسين الواقع الطبي ومعرفة مشاكل وعيوب النظام الحالي المتبع وتقديم حل معظم أو جميع المشاكل .. يمكن للمريض الآن تقييم خدمات كل من الأطباء، المختبرات، الصيدليات واضافه المشاكل او مقترح لتحسين الخدمه ليتسنى لهم الأخذ بها وتجنبها",

        pharmacy_faq_q6: "هل يمكن للنظام ارسال اشعارات ؟",
        pharmacy_faq_a6:
            "يوفر النظام خدمة ارسال الاشعارات لكل الحركات والتفاصيل والعمليات التي يجريها مستخدم النظام اذا كان طبيب , صيدلي , مختبري , مريض",

        pharmacy_faq_q7: "هل النظام ذكي ؟",
        pharmacy_faq_a7:
            "باستطاعة النظام التنبؤ باكثر الادوية استخداماً ووفقا للمنطقة التي تم صرف العلاج لمرضاها وبالتالي معرفة اكثر الحالات المرضية فور انتشارها .. فضلا عن التقارير والاحصائيات التي يصدرها النظام ستوفر الوقت والجهد في اعمال الجرد والتجهيز للادوية والمستلزمات الطبية",

        lab_management_detail1_title: "ماذا يقدم نظام عافيه للمختبر ؟",
        lab_management_detail1_feat1:
            "ادارة للمختبر بجميع اجهزته وانواع التحاليل والاشعه التي يقدمها بالاضافة لادارة المحللين العاملين فيه",
        lab_management_detail1_feat2:
            "فضلا عن امكانية رفع نتائج التحاليل الكترونيا واستعراضها من قبل المريض والطبيب في اي وقت",
        lab_management_detail1_feat3: "رفع جميع الوثائق والشهادات واجازة المختبر الى النظام وخزنها مع امكانية عرضها",
        lab_management_detail1_feat4: "تثبيت موقع المختبر على الخريطة ليتسنى لمستخدمي النظام العثور عليه",
        lab_management_detail1_feat5: "إمكانية ضبط وتعديل مواعيد وساعات العمل والعطل",
        lab_management_detail1_feat6: "إنشاء وتعديل حسابات المستخدمين للنظام داخل المختبر بحسب صلاحياتهم",
        lab_management_detail1_feat7:
            "اضافة وتعديل انواع التحاليل والأشعة والخدمات التي يقدمها المختبر مع إمكانية تعطيله في حال عدم توفرها",

        lab_management_detail2_title: "هل يمكن للنظام إصدار تقارير ؟",
        lab_management_detail2_feat1:
            "يمكنك النظام من استعراض وتصدير إحصائيات وتقارير بجميع التفاصيل والتواريخ الخاصة بحركات المختبر من بيانات التحاليل ومبالغها وأعدادها والمرضى الذين تم التعامل معهم مع إمكانية ضبط تاريخ التقرير ليوم او شهر او سنة او حسب ضبط التاريخ",

        lab_faq_q1: "هل يمكن للمحلل مراجعة ماتم عمله من تحاليل للمرضى ؟",
        lab_faq_a1:
            "يقوم النظام باستعراض جميع المرضى الذين تم عمل تحليل لهم داخل المختبر وخارجه بالتواريخ والتفاصيل فلا تهتم تلف أو ضياع السجلات الورقية سيتكفل النظام بخزن هذه المعلومات واستعراضها في أي وقت",

        lab_faq_q2: "هل هناك حجز الكتروني وماذا لو أردت تقديم خدمة التحليل خارج المختبر ؟",
        lab_faq_a2:
            "بالتأكيد يمكن للمرضى الحجز الكترونيا او عن طريق التحويل المباشر من الطبيب ويستطيع النظام تقديم هذه خدمة التحليل المنزلي حيث يمكن للمريض الحجز واختيار الخدمة المناسبه له وطلب طاقم المحللين للمنزل لإجراء اللازم",

        lab_faq_q3: "هل يوفر النظام الدفع الإلكتروني ؟",
        lab_faq_a3:
            "يوفر النظام امكانية الدفع كاش والدفع الالكتروني ببطاقات الدفع ليتم تحويل المبالغ للمحفظة الإلكترونية للمختبر واستحصال المبالغ بكل سهولة مع بيان تقارير بجميع الحركات المالية",

        lab_faq_q4: "هل يمكن تقديم العروض على التحاليل والأشعة الطبية ؟",
        lab_faq_a4:
            "بالتأكيد سيوفر لك النظام نشر العروض الخاصة بك من خصومات او تخفيض والاعلان عن انواع التحاليل الطبية بطريقة ملفتة وفريدة حيث ستتمكن من رفع صور للعروض وضبط المدة لها بكل سهولة",

        lab_faq_q5: "ماذا لو اردت عمل خصم خاص لمريض معين ؟",
        lab_faq_a5:
            "النظام له القدرة على اضافة خصم بنسبة مئوية أو مبلغ ثابت لأي مريض يتم اختياره او اعفاءه من الدفع وكل هذا سيتم توثيقه والإحصائيات والتقارير لأهميتها أثناء تدقيق حسابات المختبر",

        lab_faq_q6: "كيف يمكنني معرفة جودة الخدمة المقدمة للمرضى ؟",
        lab_faq_a6:
            "يهتم نظام عافيه الى تطوير وتحسين الواقع الطبي ومعرفة مشاكل وعيوب النظام الحالي المتبع وتقديم حل معظم أو جميع المشاكل .. يمكن للمريض الآن تقييم خدمات كل من الأطباء، المختبرات، الصيدليات واضافه المشاكل او مقترح لتحسين الخدمه ليتسنى لهم الأخذ بها وتجنبها",

        lab_faq_q7: "هل النظام ذكي ؟",
        lab_faq_a7:
            "يقوم النظام بتحليل البيانات المسجلة فيه لمعرفة أكثر التحاليل طلباً وعدد المرضى وبالتالي توفير الوقت والجهد والتنبؤ بأوقات عمل الصيانة الدورية للاجهزة المختبرية ",
        quick_support: "الدعم السريع",
        get_in_touch_today: "تواصل معنا اليوم",
        contact_us_des: "لنتحدث. أرسل لنا رسالة ونحن سوف نقوم بالرد خلال يوم عمل واحد.",
        call_us: "اتصل بنا",
        call_us_des: "أسئلة حول منتجاتنا أو أسعارنا؟ اتصل للحصول على الدعم",
        chat_us: "راسلنا",
        chat_us_des: "سيساعدك دعمنا على مدار الساعة طوال أيام الأسبوع",
        fill_contact_form_title: "املأ النموذج وسنتواصل معك في أقرب وقت ممكن.",
        email: "البريد الألكتروني",
        subject: "الموضوع",
        your_message: "رسالتك",

        loading: "يرجى الأنتظار ...",
        patient: "المريض",
        password: "كلمة السر",
        birthdate: "تاريخ الولادة",
        gender: "الجنس",
        confirm_phone_number: "تأكيد رقم الهاتف",
        registeration_complete: "لقد تمت عملية التسجيل",
        registeration_complete_text: "تمت عملية التسجيل بنجاح، الآن يمكنك تسجيل الدخول عن طريق تطبيق عافية",
        health_number_text: 'معلومات "الرقم الصحي" الخاصة بك ، يرجى استخدامها بعناية ومشاركتها مع أطبائك فقط',
        verify_identity: "التحقق من الهوية",
        face_id_verification: "التحقق بأستخدام بصمة الوجه",
        otp_verification: "التحقق بأستخدام رقم الهاتف",
        register_by_qi_card: "التسجيل بأستخدام بطاقة كي",
        retry: "اعادة المحاولة",
        male: "ذكر",
        female: "أنثى",
        district: "المنطقة",
        description: "الوصف",
        clinic_account_information: "تسجيل معلومات العيادة",
        doctor_nickname: "اسم شهرة الطبيب",
        doctor_experience_years: "عدد سنوات الخبرة",
        general_specialties: "التخصص العام",
        specialization: "التخصص الدقيق",
        expect_visit_duration: "مدة الزيارة المتوقعة",
        non_patient_registration_complete_text:
            "تم إنشاء حسابك بنجاح ، يرجى التحقق من عنوان بريدك الإلكتروني وانتظر مسؤول النظام الصحي في عافية للموافقة على معلوماتك ، فقد يستغرق الأمر بضع ساعات.",
        pharmacy_account_information: "تسجيل معلومات الصيدلية",
        lab_account_information: "تسجيل معلومات المختبر",
        patient_health_record: "السجل الطبي للمريض",
        patient_service_title_1: "مميزات نظام عافية الطبي للمريض",
        patient_service_title_2: "التسجيل عن طريق البطاقة العالمية الذكية",
        patient_service_title_3: "التسجيل عن طريق البطاقة العالمية الذكية",
        patient_service_title_4: "التسجيل عن طريق المعلومات الشخصية",
        patient_service_title_5: "تأكيد رقم الهاتف للمستخدم",
        patient_service_title_6: "تأكيد البريد الالكتروني للمستخدم",
        patient_service_title_7: "الرقم الصحي",
        patient_service_title_8: "تمتع بمميزات نظام عافية",

        patient_steps_1: "يمكن للمريض من التسجيل في النظام بعدة طرق ",
        patient_steps_1_details_1:
            "التسجيل عن طريق رقم الهاتف فيقوم النظام بإرسال رمز التحقق OTP على نفس رقم الهاتف فيتولد للمريض رقم صحي مكون من 10 ارقام يستطيع من خلاله الدخول للنظام فيما بعد",
        patient_steps_1_details_2:
            "التسجيل عن طريق بطاقة ماستر كي الصادرة من مصرف الرافدين بالشراكة مع الشركة العالمية للبطاقة الذكية فكل ماعليك هو ادخال رقم حساب البطاقة يقوم النظام بالتحقق من هويتك عن طريق بصمة الوجه",
        patient_steps_1_details_3:
            "التسجيل عن طريق بطاقة ماستر كي الصادرة من مصرف الرافدين بالشراكة مع الشركة العالمية للبطاقة الذكية فكل ماعليك هو ادخال رقم حساب البطاقة يقوم النظام بالتحقق من هويتك عن طريق بصمة الوجه",
        patient_steps_3: ". ",

        patient_service_details_1:
            "أنشاء الملف الصحي وادارة السجلات الطبية التي تحتوي على جميع معلومات و الزيارات للاطباء",
        patient_service_details_2:
            "تقليل الأخطاء البشرية والجهد من خلال وجود أغلب بيانات الأدوية والتشخيصات والتحاليل الطبية في النظام",
        patient_service_details_3:
            "يسمح النظام للمرضى بالحجز الالكتروني للمواعيد دون العناء والذهاب الى المؤسسة الصحية",
        patient_service_details_4:
            "الأولوية بالعلاج حيث يقدم النظام تطبيق للمريض يستطيع من خلاله معرفة العلاج والتحاليل وكذلك يوفر النظام الاتصال الأمثل بين الأطباء والصيادلة والمحللين ",
        patient_service_details_5: "نظام اشعارات متكامل من مميزاته تذكير المريض بالمواعيد الطبية والدواء وجدولتها",
        patient_service_details_6:
            "يهدف النظام الى تقليل نفقات العلاج عن طريق توفير إمكانية التواصل بين المريض والطبيب الكترونيا تدريجياً وأخذ استشارة طبية",
        patient_service_details_7:
            "التسجيل عن طريق بطاقة ماستر كي الصادرة من مصرف الرافدين بالشراكة مع الشركة العالمية للبطاقة الذكية كي كارد",
        patient_service_details_8:
            "كل ماعليك هو ادخال رقم حساب البطاقة سوف يقوم النظام بالتحقق من هويتك عن طريق الصوره التعريفية لحامل البطاقة",
        patient_service_details_9:
            "يمكنك التسجيل عن طريق رقم حساب بطاقه الماستر كارد او رقم بطاقه كي كارد من مصرف الرافدين والتحقق من صحة المعلومات بطريقة آمنة",
        patient_service_details_10:
            "سوف يتم التحقق من تطابق الهوية التعريفية لحامل البطاقة مع بيانات الشخص المسجل في النظام",
        patient_service_details_11: "كذلك يتم التحقق من تطابق رقم هاتف المستخدم مع رقم هاتف حامل البطاقة",
        patient_service_details_12: "يستطيع المستخدم التسجيل عن طريق ملئ استماره المعلومات",
        patient_service_details_13: "كذلك يمكن للمستخدم التسجيل في النظام عن طريق احد وسائل التواصل الاجتماعي",
        patient_service_details_14:
            "للتأكد من صحة معلومات جميع مستخدمي نظام عافيه سوف يتم التأكد من رقم هاتف المستخدم عن طريق ارسال رمز الحمايه لهاتف المستخدم",
        patient_service_details_15:
            "للتأكد من صحة معلومات جميع مستخدمي نظام عافيه سوف يتم التأكد من البريد الالكتروني المستخدم عن طريق ارسال رابط التفعيل للبريد الالكتروني للمستخدم",
        patient_service_details_16:
            "بعد أنتهاء الخطوات السابقة سوف يتم أنشار رقم صحي للمريض بالاعتماد على محافظة المستخدم",
        patient_service_details_17: "يمكن استخدام الرقم الصحي لحجز المواعيد الكترونياً",
        patient_service_details_18: "يمكن استخدام الرقم الصحي لانشاء ملف صحي خاص به",
        patient_service_details_19:
            "كذلك يمكن استخدام الرقم الصحي للتحقق من الوصفات الطبية والتحاليل المرتبطة بملفه الطبي",
        patient_service_details_20: "العثور على الصيدليات والمختبرات والأطباء بناءً على الموقع والتخصص",
        patient_service_details_21: "عرض نتائج تحاليل المختبرات الطبية وطباعتها",
        patient_service_details_22: "الوصول إلى الأدوية والوصفات الطبية وطباعتها",
        patient_service_details_23: "مشاركه الملفات الطبية عن طريق التطبيق وغيرها من المميزات المهمة للمستخدم",

        Done: "تم بنجاح",
        "Sent successfully": "تم استلام رسالتك بنحاح",

        patient_faq_q1: "ما الذي يميز تطبيق عافيه كنظام إلكتروني بالنسبة للمرضى عن غيره ؟",
        patient_faq_a1:
            "ستتميز كمستخدم لنظام عافيه بالأولوية بالعلاج حيث يقدم النظام تطبيق للمريض يستطيع من خلاله معرفة العلاج والتحاليل وتوفير الاتصال الأمثل بين الأطباء والصيادلة والمحللين حيث يقل عامل الوقت في وصول طلبات الطبيب وبالتالي زيادة سرعة الاستجابة وتوفير الوقت والجهد.",
        patient_faq_q2: "هل يمكنني الحجز والدفع دون الوصول إلى مكان العيادة ؟",
        patient_faq_a2:
            "بالطبع من خلال استخدام التطبيق يمكنك بسهولة عرض الاطباء , العيادات , الصيدليات , المختبرات الطبية والحجز الكترونيا يسمح النظام للمرضى بالحجز الإلكتروني دون العناء والذهاب الى المؤسسة الصحية والدفع كاش عند الحضور للعيادة.",
        patient_faq_q3: "ماذا سيستفيد الطبيب من الرقم الصحي للمريض ؟",
        patient_faq_a3:
            "سيكون الرقم الصحي هو بمثابة الذاكرة الدائمة لكل بيانات المريض القديمة والحديثة من خلال وجود أغلب بيانات الأدوية والأمراض والتحاليل للمريض في سجله الصحي الإلكتروني التي تقلل من الأخطاء البشرية وتسمح للطبيب بتوفير الوقت والجهد في تشخيص الحالة المرضية.",
        patient_faq_q4: "هل يهتم النظام للاشخاص الغير مهتمين للتكنولوجيا واجهزة الهواتف الذكية ؟",
        patient_faq_a4:
            "يتكون نظام عافيه من مجموعة واجهات بسيطة جدا مفهومة ومقروءة من قبل جميع الفئات العمرية بالوان تناسب جميع مستويات النظر للأشخاص الكبار في السن كذلك بعدة لغات العربية , الإنكليزية فهو ملائم للجميع وسهل الاستخدام",
        patient_faq_q5: "هل سيذكرني النظام بأخذ جرعة العلاج ؟",
        patient_faq_a5:
            "يحتوي النظام على نظام اشعارات متكامل حيث ان من مميزات تطبيق المريض، هو التذكير بمواعيد أخذ الدواء وموعد الزيارة وجدولتها حسب الوقت المحدد.",

        privacy: "سياسة الخصوصية",
        phone_error: "الرجاء ادخال رقم الهاتف بصورة صحيحة",
        full_name: "الأسم الكامل",
        pharmacy_name: "اسم الصيدلية",
        clinic_name: "اسم العيادة",
        lab_name: "اسم المختبر",
        license_number: "رقم الأجازة",
        number_error: "الرجاء ادخال رقم صحيح",
        expect_visit_duration_des: "وهو الوقت المتوقع الذي يقضيه المريض مع الطبيب.",
        resend_otp: "اعادة ارسال الكود",
        license_information: "معلومات الأجازة",
        official_information: "المعلومات الرسمية",
        first_name: "الاسم الاول",
        second_name: "الأسم الثاني",
        third_name: "الأسم الثالث",
        face_id_required: "صورة الوجه مطلوبة",
        choose_verification_type: "اختر طريقة تأكيد الحساب",
        verify_by_phone: "التأكيد بوساطة رقم الهاتف",
        verify_by_email: "التأكيد بوساطة البريد الالكتروني",
        verify_by_email_text: "لقد تم اتمام عملية التسجيل و قد قمنا بأرسال رابط التفعيل على البريد الالكتروني الخاص بك",
        doctor_expected_fee: "الأجر المتوقع",
        contract_conditions: "شروط العقد",
        contract_agreement: "نعم ، أود الموافقة على شروط العقد",
        confirm_identity: "تأكيد الهوية",
        choose_confirmation_type: "كيف تفضل تأكيد هويتك؟",
        manual_filling: "ملئ المعلومات بشكل يدوي",
        passport_scanning: "عن طريق جواز السفر",
        click_here_to_upload_passport: "اضغط هنا لتحميل جواز سفرك",
        passport_required: "جواز السفر مطلوب",
        worng_passport: "الرجاء تحميل صورة جواز حقيقية",
        private: "خاص",
        public: "عام",
        mixed: "مشترك",
        type: "النوع",
        nationalties: "الجنسية",
        languages: "اللغات",
        schooling: "الشهادة",
        primery: "شهادة الأبتدائية",
        middle: "شهادة المتوسطة",
        secondary: "شهادة الأعدادية",
        diploma: "دبلوم",
        bs: "بكلوريوس",
        masters: "ماجستير",
        phd: "الدكتوراة",
        prof: "بروفيسور",
        registeration_by_email_text: "تمت عملية التسجيل بنجاح، الرجاء التحقق من بريدك الالكتروني لتأكيد حسابك",
        social_links: "روابط مواقع التواصل الاجتماعي",
        link: "الرابط",
        save: "حفظ",
        skip: "تخطي",
        password_reset: "اعادة تعيين كلمة السر",
        enter_new_password: "ادخل كلمة السر الجديدة",
        password_reset_success: "لقد تمت اعادة تعيين كلمة السر بنجاح",
        password_reset_error: "هنالك خطأ",
        download_app: "حمل التطبيق",
        phone_required: "رقم الهاتف مطلوب",
        confirm_password_must_mach_password: "كلمة السر يجب ان تتشابه مع حقل تأكيدد كلمة السر",
        confirm_password: "تأكيد كلمة السر",
        uploading_image: "جار رفع الصورة",
        fee_placeholder: "بالدينار مثال : 20,000 دينار",
        appointment_time_placeholder: "بالدقائق مثال : 15 دقيقة",
        please_enter_a_valid_info: "الرجاء ادخال المعلومات بشكل صحيح",
        if_you_did_not_receive_the_verification_email_click_here: "إذا لم تتلق رسالة التحقق انقر هنا",
        retake_image: "اعادة التقاط الصورة",
        change_verification_methode: "تغيير طريقة التأكيد",
        required: "مطلوب",
        min_length_validation: "لحد الادنى لعدد الحروف 8",
        max_length_validation: "الحد الاعلى لعدد الحروف",
        incorrect: "غير صحيح",
        confirmed: "يجب ان تكون مطابقة لكلمة المرور الجديدية",
        numeric: "يجب ان يكون رقم",
        incorrect_password_format: "يجب أن يتضمن حرفًا كبيرًا واحدًا على الأقل وحرفًا صغيرًا ورقمًا واحدًا على الأقل.",
        face_id: "صورة الوجه",
        thumbs_print: "بصمة الأبهامين",
        left_thumb_print: "بصمة الأبهام الايسر",
        right_thumb_print: "بصمة الأبهام الأيمن",
        download_insurance_card: "تحميل بطاقة الضمان",
        send_data: "ارسال البيانات",
        blood_type: "فصيلة الدم",
        family_number: "الرقم العائلي",
    },
    en: {
        app_name: "Afea Healthcare System",
        app_slogan: "Afea … Endless Care",
        services_title: "Best Services",
        services_subtitle: "Grow Your Business Value",
        Language: "Language",
        clinicService: "Medical Clinic",
        pharmacyService: "Medical Pharmacy",
        laboratoryService: "Medical Laboratory",
        patientService: "Patient Medical Records",
        choose_service_type: "Choose Service Type",
        register: "Register",
        verify: "Verify",
        registerNow: "Register Now",
        login: "Login",
        hospital: "Hospital",
        pharmacy: "Pharmacy",
        laboratory: "Laboratory",
        contract: "Contract",
        upload_signature: "Upload Signature",
        upload_signature_image: "Upload Signature Image",
        open_camera_take_live_photo: "Open camera and take live photo",
        card_number: "Card number",
        retake: "Retake",
        confirm: "Confirm",
        enter_otp: "Enter Otp",
        choose_package_type: "Choose package type",
        discount: "Discount",
        basic: "Basic",
        advanced: "Advanced",
        privacy_policy_and_the_terms_of_use: "Privacy policy and the terms of use",
        poilcy_agreement: "Yes, I like to Agree to the terms of the Privacy Policy and theTerms of Use",
        choose_product_type: "Choose product type",
        iqd: "IQD",
        payment: "Payment",
        card_expire: "Card expire",
        ccv: "CCV",
        service_information: "service_information",
        name: "Name",
        phone_number: "Mobile Number",
        address: "Address",
        do_you_have_qi_card: "Do you have Qi or Master Qi Card?",
        yes: "Yes",
        no: "No",
        previous: "Previous",
        next: "Next",
        send: "Send",
        user_account_information: "User account information",
        governorate: "Governorate",
        licence: "Licence",
        licence_number: "Licence number",
        date: "Date",
        upload_licence_image: "Upload licence image",
        get_started: "Get started",
        home: "Home",
        sponsers: "Sponser",
        contact_us: "Contact us",
        services: "Services",
        registration: "Registration",
        afea_services: "Afea services",
        about_app: "About app",
        afea_mobile_app: "Afea mobile app",
        available_on_the: "Available on the",
        afea_healthcare_sponser: "Afea healthcare sponsers",
        alrafidin_bank: "Al-Rafidin bank",
        qi_card: "Qi Card",
        bait_altamen: "Bait Al-Tamen",
        afea_rights_reserved: "Afea all rights reserved",
        pricing: "Pricing",
        support: "Support",
        our_team: "Our team",
        faq: "FAQ",
        fast_links: "Fast links",
        afea_description:
            "Your best choice for creating and managing the medical record, which contains all the data of medical visits represented by diagnoses, treatment and medical analyzes within health institutions, including hospitals, clinics, pharmacies and laboratories",
        afea_body_description:
            "The system is considered the nucleus of public and private medical institutions because it provides statistics and reports that facilitate the work of all institutions and medical staff, as well as providing patient-specific reports that will facilitate knowledge of the health status and medical and family record of the patient.",
        clinic_description:
            "An integrated system for the creation and management of the patient’s medical record for its ability to collect data from medical visits, treatment, analyzes and previous patient data in all institutions and clinics operating in the system, as well as benefit from statistics and reports, and manage the clinic’s staff, including doctors, nurses and secretaries.",
        pharmacy_description:
            "The system provides an electronic prescription that reduces the errors and ambiguity of the names of medicines in their manual analogy, which facilitates the work of the pharmacist, as well as the management of medicines in the pharmacy, easy communication with the doctor, the management of accounts and financial movements of the pharmacy with complete automation in management and sales.",
        lab_description:
            "The system provides very fast access to analysis requests to the laboratories, reducing time, as well as delivering results directly by adding them to the patient’s pathological file, as well as easy access to them and displaying illustrative charts to the doctor, which makes it easier for him to make the right decision in the pathological diagnosis. As well as managing accounts and financial movements of the laboratory with full automation in management, sales and electronic reservation of patients.",
        mobile_app_des_1:
            "The doctor can use the mobile application instead of the computer, or use both of them together to manage his patients, receive electronic reservation requests, review the patient’s health record, add diagnosis and treatment, and manage the clinic and its staff.",
        mobile_app_des_2:
            "The Patient can use the mobile application  to manage his appointment, reminders, receive electronic medical analysis results, view prescriptions and to review his health record",
        feat1: "Review and management of visits and the health record of patients",
        feat2: "Clinic management and work data",
        feat3: "Manage online reservations",
        feat4: "Diagnosis and Prescription",
        feat5: "Accounts and Invoices",
        feat6: "Medical offers and discounts",
        feat7: "Reports and Statistics",
        feat8: "Refer patients to more specialized doctors",
        feat9: "Receiving evaluation and patient opinions",
        feat10: "Request for treatment and analysis from pharmacies and laboratories",
        feat11: "Cloud storage and data access speed",
        qi_card_des:
            "The global smart card company, Key Card and MasterKey, is a pioneer and specialized in the financial and banking sector, which provides the best solutions in the field of electronic payment in Iraq.",
        alrafidin_bank_des:
            "It is considered one of the most important Iraqi banks due to its advanced technologies and banking facilities for MasterK card holders, in addition to electronic payment services for the government and private sectors.",
        bait_altamen_des:
            "One of the largest companies to manage health care services and provide the necessary support to all departments, health insurance companies and social solidarity funds in the public and private sectors.",
        system_features: "System Features",
        hospital_management: "Hospital management",
        medical_record_management: "Medical Record Management",
        reduce_medical_errors: "Reduce medical errors",
        pharmacies_management: "Pharmacies management",
        laboratory_management: "Laboratories management",
        online_booking: "Online booking",
        treatment_priority: "Treatment priority",
        electronic_payment: "Electronic payment",
        appointment_reminder: "Reminder",
        reducing_treatment_expenses: "Reduce treatment expenses",
        medical_loans_and_advances: "Medical loans and advances",
        hospital_management_des:
            "Establishing and managing an integrated and comprehensive information system to manage the clinical, administrative and financial aspects of hospitals. The system helps in controlling data in most departments of health care, finance, patients, operating room, nursing, radiology, laboratory, outpatient clinics and pharmacies. ",
        medical_record_management_des:
            "Create and manage the patient's medical record that contains all visit data, analysis and treatment in addition to the medical and family history of patients such as genetic diseases, allergies, vaccinations, as well as all disease movements within health institutions.",
        reduce_medical_errors_des:
            "By having the majority of drug, disease and test data that reduces human errors and patient data that allows clinicians to save time and effort in diagnosing a disease. ",
        pharmacies_management_des:
            "The system provides an electronic prescription that reduces the errors and ambiguity of the names of medicines in their manual analogy, which facilitates the work of the pharmacist, as well as the management of medicines in the pharmacy, easy communication with stores and the provision of the necessary supplies. ",
        laboratory_management_des:
            "The system provides quick access to analysis requests to laboratories, reducing time, as well as communicating results directly by adding them to the patient’s sick file, as well as easy access to them and displaying explanatory charts to the doctor, which makes it easier for him to make the right decision in diagnosing the patient.",
        online_booking_des:
            "The system allows patients to book electronically through electronic payment cards without the trouble of going to the health institution, as well as the system will provide the ability to book taxis to move to those institutions.",
        treatment_priority_des:
            "The system provides an application for the patient through which he can know the treatment and analyzes, as well as the system provides optimal communication between doctors, pharmacists and analysts, where the time factor in the arrival of doctor's requests is reduced, thus increasing the speed of response and saving time and effort. ",
        appointment_reminder_des:
            "One of the features of the patient app is a reminder and scheduling of medication and visit appointments. ",
        reducing_treatment_expenses_des:
            "This is where the system will provide the ability to communicate between the patient and the doctor electronically and take a free medical consultation in some cases, which will reduce the effort and time by informing the doctor about previous analyzes and x-rays. ",
        medical_loans_and_advances_des:
            "Through electronic payment cards, the local residents received their salaries, and through the coordination of our company with the Rafidain Bank, we provided advances and medical loans. ",
        electronic_payment_des:
            "Through electronic payment cards, the patient can pay to the health institution, as well as patients can pay on deferred payment to the endemic employees of their salaries, as well as payment in installments and receive medical advances through the monthly salary guarantee or the guarantee fund of the employee who has the patient.",
        features: "Features",
        pricingAndSubscriptions: "Pricing",
        plans: "Plans & Pricing",
        clinicSubscriptions: "Clinic Subscriptions",
        pharmacySubscriptions: "Pharmacy Subscriptions",
        labSubscriptions: "Lab Subscriptions",
        subscribe: "Subscribe",
        duration: "Duration",
        trailDuration: "Trail Duration",
        afea_des2:
            "The project is a nucleus for managing all medical institutions in all administrative and health aspects, which include the relationship of patients, doctors and employees to health institutions. The product is available on all platforms and for all users represented by patients, health staff, and institutions that include administrative staff and others. The application contains sufficient intelligence to provide suggestions and opinions to all users, as well as the application can communicate with institutions in a critical situation to request support teams and alert patients through health reports. The system will provide statistics and reports that facilitate the work of all institutions and medical staff, as well as provide some special reports for the patient that will facilitate the knowledge of his sick condition.",
        view_details: "View Details",
        our_services: "Our services",
        clinic_management: "Clinic management",

        clinic_management_detail1_title: "Are you having difficulty managing your clinic and appointments?",
        clinic_management_detail1_feat1:
            "You can now manage your medical clinic with ease, starting with defining the doctor's file and the clinic's information and addresses,",
        clinic_management_detail1_feat2:
            "Also, setting a schedule of working days and hours, changing or postponing appointments if necessary, in one step, and notifying patients of that.",
        clinic_management_detail1_feat3: "Add your clinic manager so that he can do other tasks and many more",

        clinic_management_detail2_title:
            "Do you rely on periodic reports to follow up your medical clinic and the health status of patients?",
        clinic_management_detail2_feat1:
            "You can now request a comprehensive medical report on the patient’s condition, the spent and followed treatment, and the results of previous medical tests and x-rays",
        clinic_management_detail2_feat2:
            "Report of the patient's medical history injuries, operations, previous vaccinations and other medical history information",
        clinic_management_detail2_feat3: "Report of the diseases most diagnosed and treated by your clinic",
        clinic_management_detail2_feat4:
            "A report on the rates and rates of appointments according to a weekly, monthly, annual classification or values ​​by specifying the date according to the choice.",

        clinic_management_detail3_title: "Do you have multiple clinics and have difficulty managing them?",
        clinic_management_detail3_feat1:
            "Afia is a medical system that aims to document all the medical movements of patients in hospitals, private clinics, pharmacies, laboratories and radiology clinics so that he can provide a broad view of the patient’s condition to the doctor before giving any diagnosis or treatment disbursement.",
        clinic_management_detail3_feat2:
            "The doctor can add all his medical clinics separately from each other and appoint a clinic manager or a joint or separate secretary to manage his appointments and file or personally manage him, as well as follow up on the condition of patients in each clinic.",

        clinic_faq_q1: "What are the payment methods?",
        clinic_faq_a1:
            "If you prefer to manage your medical clinic, appointments and payment, file and history of patients without the help of a clinic manager or a secretary, Afia Medical System is the perfect solution for you. So that you can easily receive the amounts of reservations online.",

        clinic_faq_q2: "How can medical offers be submitted or patients be exempted from fees?",
        clinic_faq_a2:
            "Through the Afia system, you can easily announce medical offers and know the patients interested in them. The patient will be notified of all medical offers submitted by doctors and clinics. You can also exempt the patient from part or all of the booking fees to build a human relationship with patients and relieve patients.",

        clinic_faq_q3: "How can I know the quality of service provided to patients?",
        clinic_faq_a3:
            "The Afia system is concerned with developing and improving the medical reality, knowing the problems and defects of the current system followed, and providing a solution to most or all problems.. The patient can now evaluate the services of doctors, laboratories, pharmacies and add problems or a proposal to improve the service so that they can take them and avoid them.",

        clinic_faq_q4: "Can the system send notifications?",
        clinic_faq_a4:
            "The system provides the service of sending notifications for all movements, details and operations carried out by the user of the system if he is a doctor, pharmacist, laboratory, patient.",

        clinic_faq_q5: "Is the system smart?",
        clinic_faq_a5:
            "The system can predict the most prevalent diseases according to the geographical area and age of patients, thus saving time and effort by exporting statistics and reports to limit and control diseases as soon as they spread, as well as issuing reports with details and dates that are chosen by the user of the system.",

        clinic_faq_q6: "Can patient visits be documented?",
        clinic_faq_a6:
            "All the information added to the patient's medical record is electronically documented with high efficiency and protection, so that you can easily view visits at any time through the doctor's 'Afya' application and your control panel, as well as displaying the diagnostic files for each patient so that you can review the progress of the case and the impact of the treatment followed.",

        clinic_patient_details_question:
            "What information does the patient need to know before giving a diagnosis? How long does it take?",
        clinic_patient_details_answer1:
            "The patient's medical record contains disease history, injuries history, surgeries, vaccinations he received, drug allergies, medications used, medical tests, the patient's habits such as smoking and others",
        clinic_patient_details_answer2:
            "Which leads to giving accurate information to the doctor about the patient’s condition, which contributes to the accuracy and efficiency of the diagnosis",
        clinic_patient_details_answer3:
            "The doctor can also manage the medical session as efficiently as never before.",
        clinic_patient_details_answer4:
            "Disbursing treatment and medical prescriptions and following up on the patient’s alternative treatment status",
        clinic_patient_details_answer5: "Manage Diagnostics and Diagnostic File",
        clinic_patient_details_answer6: "Requesting medical tests for the patient and following up on their results.",
        clinic_patient_details_answer7: "Managing the patient's referral to another specialist doctor",
        clinic_patient_details_answer8:
            "Managing a diagnosis request from another doctor, following up on the patient’s condition, and many more features that contribute to the efficiency of the diagnosis and shortening the time for the doctor",

        pharmacy_management_detail1_title: "What does the Afia system offer to the pharmacist?",
        pharmacy_management_detail1_feat1:
            "You can upload all documents, certificates and pharmacy license to the system and store them with the possibility of displaying them",

        pharmacy_management_detail1_feat2:
            "Pin the location of the pharmacy on the map so that users of the system can find it",
        pharmacy_management_detail1_feat3: "The ability to set and modify working hours and hours and holidays",
        pharmacy_management_detail1_feat4:
            "Create and modify user accounts for the system within the pharmacy according to their powers",
        pharmacy_management_detail1_feat5:
            "Add all medications available inside the pharmacy to the system with the possibility of modification",

        pharmacy_management_detail2_title: "What is the treatment mechanism?",
        pharmacy_management_detail2_feat1:
            "The pharmacist can access the system and enter the patient’s health number after obtaining this authority, as the medicines that were prescribed by the doctor will be shown to him to be dispensed with, with ease, adjusting the number, dosage and instructions",

        pharmacy_management_detail3_title: "Can the system issue reports?",
        pharmacy_management_detail3_feat1:
            "The system enables you to review and export statistics and reports with all the details and dates related to the pharmacy movements from drug data, disbursement, amounts, numbers and patients who have been dealt with with the possibility of adjusting the date of the report for a day, month, year or according to the date setting",

        pharmacy_faq_q1: "Can the pharmacist review the treatments dispensed?",
        pharmacy_faq_a1:
            "The system reviews all patients who have been treated with dates and details, so do not worry about the damage or loss of paper records, the system will store and review this information at any time.",

        pharmacy_faq_q2: "Does the system provide electronic payment?",
        pharmacy_faq_a2:
            "The system provides the possibility of cash payment and electronic payment with payment cards, so that the amounts are transferred to the electronic wallet of the pharmacy and the sums can be collected easily with a statement of reports on all financial movements,",

        pharmacy_faq_q3: "Can offers be submitted on treatment or medical supplies?",
        pharmacy_faq_a3:
            "Of course, the system will provide you with publishing your offers of discounts or discounts and advertising your medical supplies in an attractive and unique way, where you will be able to upload pictures of the offers and set the duration for them with ease.",

        pharmacy_faq_q4: "What if I want to make a special discount for a specific patient?",
        pharmacy_faq_a4:
            "The system has the ability to add a percentage discount or a fixed amount to any patient who is selected or exempted from paying, and all of this will be documented by statistics and reports for their importance during the pharmacy accounts audit.",

        pharmacy_faq_q5: "How can I know the quality of the service provided to patients?",
        pharmacy_faq_a5:
            "The Afia system is concerned with developing and improving the medical reality, knowing the problems and defects of the current system followed, and providing a solution to most or all problems.. The patient can now evaluate the services of doctors, laboratories, pharmacies and add problems or a proposal to improve the service so that they can take them and avoid them.",

        pharmacy_faq_q6: "Can the system send notifications?",
        pharmacy_faq_a6:
            "The system provides the service of sending notifications for all movements, details and operations carried out by the user of the system if he is a doctor, pharmacist, laboratory, patient.",

        pharmacy_faq_q7: "Is the system smart?",
        pharmacy_faq_a7:
            "The system can predict the most commonly used medicines and according to the area in which the treatment was given to its patients, and thus know the most sick cases as soon as they spread.. In addition to the reports and statistics issued by the system, it will save time and effort in the inventory and preparation of medicines and medical supplies.",
        lab_management_detail1_title: "What provides a wellness system to the laboratory?",
        lab_management_detail1_feat1:
            "Management of the laboratory with all its equipment and the types of analyzes and x-rays it provides, in addition to the management of the analysts working in it",
        lab_management_detail1_feat2:
            "As well as the possibility of uploading the results of the tests electronically and reviewing them by the patient and the doctor at any time",
        lab_management_detail1_feat3:
            "Upload all documents, certificates and laboratory license to the system and store them with the possibility of displaying them",
        lab_management_detail1_feat4:
            "Pin the location of the laboratory on the map so that users of the system can find it",
        lab_management_detail1_feat5: "The ability to set and modify working hours and hours and holidays",
        lab_management_detail1_feat6:
            "Create and modify user accounts for the system within the laboratory according to their powers",
        lab_management_detail1_feat7:
            "Adding and modifying the types of analyzes, x-rays, and services provided by the laboratory, with the possibility of disabling it if it is not available",

        lab_management_detail2_title: "Can the system issue reports?",
        lab_management_detail2_feat1:
            "The system enables you to review and export statistics and reports with all the details and dates of the laboratory movements from the analysis data, amounts and numbers and the patients who have been dealt with with the possibility of adjusting the date of the report for a day, month, year or according to the date setting",

        lab_faq_q1: "Can the analyst review what has been done from the analyzes of patients?",
        lab_faq_a1:
            "The system reviews all patients who have been analyzed inside and outside the laboratory with dates and details. Do not worry about the damage or loss of paper records. The system will store and review this information at any time.",

        lab_faq_q2:
            "Is there an electronic reservation, and what if I want to provide the analysis service outside the laboratory?",
        lab_faq_a2:
            "Certainly, patients can book electronically or by direct transfer from the doctor, and the system can provide this home analysis service, where the patient can book and choose the appropriate service for him and request the analysis team to take the necessary actionCertainly, patients can book electronically or by direct transfer from the doctor, and the system can provide this home analysis service, where the patient can book and choose the appropriate service for him and request the analysis team to take the necessary action",

        lab_faq_q3: "Does the system provide electronic payment?",
        lab_faq_a3:
            "The system provides the possibility of cash payment and electronic payment with payment cards so that the amounts are transferred to the electronic wallet of the laboratory and obtain the amounts easily with a statement of reports on all financial movements",

        lab_faq_q4: "Is it possible to submit offers on medical tests and x-rays?",
        lab_faq_a4:
            "The system will certainly provide you with publishing your offers of discounts or discounts and announcing the types of medical tests in a striking and unique way, where you will be able to upload pictures of the offers and adjust the duration for them with ease.",

        lab_faq_q5: "What if I want to make a special discount for a specific patient?",
        lab_faq_a5:
            "The system has the ability to add a percentage discount or a fixed amount to any patient selected or exempted from payment, and all this will be documented, statistics and reports for their importance during the laboratory audit.",

        lab_faq_q6: "How can I know the quality of service provided to patients?",
        lab_faq_a6:
            "Afia system is concerned with developing and improving the medical reality, knowing the problems and defects of the current system followed, and providing a solution to most or all of the problems.",

        lab_faq_q7: "Is the system smart?",
        lab_faq_a7:
            "The system analyzes the data recorded in it to find out the most requested analyzes and the number of patients, thus saving time and effort and predicting work times for periodic maintenance of laboratory equipment",

        patient_service_title_1: "AFEA Medical System Features",
        patient_service_title_2: "International Smart Card Registration",
        patient_service_title_3: "International Smart Card Registration",
        patient_service_title_4: "Registration with Personal information",
        patient_service_title_5: "Confirm Patient Phone Number",
        patient_service_title_6: "Confirm Patient E-mail Address",
        patient_service_title_7: "Health Number",
        patient_service_title_8: "Enjoy the benefits of a AFEA Health system",

        patient_service_details_1:
            "Create a health profile and keep track of all information and doctor appointments in your medical records",
        patient_service_details_2:
            "Having the majority of the pharmacological data, diagnoses, and medical analyses in the system reduces human errors and effort",
        patient_service_details_3:
            "The technique helps patients to schedule appointments electronically without having to visit the medical facility",
        patient_service_details_4:
            "Priority in medication, as the platform provides a patient application through which he can learn about the treatment and analyses also the syste enables the best possible contact between doctors, pharmacists, and analysts",
        patient_service_details_5:
            "An integrated notification system that includes features to remind the patient of doctor appointments, medication schedules, and other important information",
        patient_service_details_6:
            "The system eventually makes it possible for patients and doctors to communicate electronically and conduct medical consultations in an effort to lower treatment costs",
        patient_service_details_7:
            "Registration using the Al-Rafidain Bank's Master QI Card in collaboration with the International Smart Card Company QI-Card",
        patient_service_details_8:
            "Simply input the card account number, and the system will use the cardholder's identification photo to confirm your identity",
        patient_service_details_9:
            "You can sign up with your Rafidain Bank QI Card or MasterCard account number and securely confirm the accuracy of the information.",
        patient_service_details_10:
            "The information of the individual enrolled in the system is used to confirm the identity of the cardholder",
        patient_service_details_11:
            "Additionally, the phone number of the Patient is compared to that of the cardholder.",
        patient_service_details_12: "The Patient can register by filling in the information form",
        patient_service_details_13: "The Patient can also register in the system through one of the social media",
        patient_service_details_14:
            "To ensure that the information of all Patients of AFEA system is correct, the Patient's phone number will be verified by sending a security code to the Patient's phone",
        patient_service_details_15:
            "In order to ensure that the information of all Patients of AFEA system is correct, the email used will be verified by sending the activation link to the Patient's email",
        patient_service_details_16:
            "After completing the previous steps, the patient's health number will be published depending on the Patient's province",
        patient_service_details_17: "The health number can be used to book appointments online",
        patient_service_details_18: "The health number can be used to create its own health file",
        patient_service_details_19:
            "The health number can also be used to verify medical prescriptions and analyzes related to his medical file",
        patient_service_details_20: "Find Pharmacies, Labs, and Doctors based on location, Governance and specialty",
        patient_service_details_21: "View and print the results of medical laboratory analyzes",
        patient_service_details_22: "Access and print medications and prescriptions",
        patient_service_details_23:
            "Sharing medical files through the application and other important features for the Patient",

        quick_support: "Quick Support",
        get_in_touch_today: "Get in Touch Today!",
        contact_us_des: "Let's talk . Send us a message and we will be in touch within one business day.",
        call_us: "Call Us",
        call_us_des: "Questions about our product or pricing? Call for supports",
        chat_us: "Chat Us",
        chat_us_des: "Our support will help you from 24/7",
        fill_contact_form_title: "Fill out the form and we'll be in touch as soon as possible.",
        email: "Email",
        subject: "Subject",
        your_message: "Your Message",

        loading: "Loading...",
        patient: "Patient",
        password: "Password",
        birthdate: "Birthdate",
        gender: "Gender",
        confirm_phone_number: "Confirm Mobile Number",
        registeration_complete: "Registeration Complete",
        registeration_complete_text: "Registration complete now you can login using the mobile app",
        verify_identity: "Verify Identity",
        face_id_verification: "Face Id Verification",
        otp_verification: "Mobile Number Verification",
        register_by_qi_card: "Register by QiCard",
        retry: "Retry",
        male: "Male",
        female: "Female",
        district: "District",
        description: "Description",
        clinic_account_information: "Clinic Information",
        doctor_nickname: "Doctor Nickname",
        doctor_experience_years: "Experience Years",
        general_specialties: "General Specialties",
        specialization: "Specialization",
        expect_visit_duration: "Expect Visit Duration",
        non_patient_registration_complete_text:
            "Your account have been created successfully please verify your email address and wait AFEA Health System Administrator to approve for your information it may take few hours.",
        pharmacy_account_information: "Pharmacy Information",
        lab_account_information: "Laboratory Information",
        patient_health_record: "Patient Health Profile",

        Done: "Done",
        "Sent successfully": "Sent successfully",

        patient_faq_q1: "What distinguishes the Afia application as an electronic system for patients from others?",
        patient_faq_a1:
            "As a user of the Afya system, you will be given priority in treatment, as the system provides an application for the patient through which he can know the treatment and analyzes and provide optimal communication between doctors, pharmacists and analysts, as the time factor in receiving the doctor’s requests is reduced, thus increasing the speed of response and saving time and effort.",
        patient_faq_q2: "Can I book and pay without going to the clinic?",
        patient_faq_a2:
            "Of course, by using the application, you can easily view doctors, clinics, pharmacies, medical laboratories and book online. The system allows patients to book online without the trouble of going to the health institution and paying cash when attending the clinic.",
        patient_faq_q3: "What will the doctor benefit from the patient's health number?",
        patient_faq_a3:
            "The health number will be the permanent memory of all the patient’s old and recent data through the presence of most of the patient’s drug, disease and analysis data in his electronic health record, which reduces human errors and allows the doctor to save time and effort in diagnosing the disease.",
        patient_faq_q4: "Does the system care for people who are not interested in technology and smart phone devices?",
        patient_faq_a4:
            "Afia system consists of a set of very simple interfaces that are understandable and readable by all age groups in colors that suit all levels of vision for elderly people as well as in several languages, Arabic, English, it is suitable for everyone and easy to use",
        patient_faq_q5: "Will the system remind me to take the treatment dose?",
        patient_faq_a5:
            "The system contains an integrated notification system, as one of the features of the patient application is to be reminded of the dates of taking the medicine and the date of the visit and scheduling it according to the specified time.",

        privacy: "Privacy & Policy",
        phone_error: "Please enter a valid mobile number",
        full_name: "Full Name",
        pharmacy_name: "Pharmacy Name",
        clinic_name: "Clinic Name",
        lab_name: "Lab Name",
        license_number: "License number",
        number_error: "Please enter a valid number",
        expect_visit_duration_des: "It is the expected time that the patient spend with the doctor.",
        resend_otp: "Resend OTP",
        license_information: "License Information",
        official_information: "Oficial Information",
        first_name: "First Name",
        second_name: "Second Name",
        third_name: "Third Name",
        face_id_required: "Face Id Required",
        choose_verification_type: "Choose account verification methode type",
        verify_by_phone: "Verify by phone number",
        verify_by_email: "Verify by email",
        verify_by_email_text:
            "The registration process has been completed and we have sent the activation link to your email",
        doctor_expected_fee: "Expected Fee",
        contract_conditions: "Contract Conditions",
        contract_agreement: "Yes, I like to Agree to the Contract Conditions,",
        confirm_identity: "Confirm Identity",
        choose_confirmation_type: "How would you prefer to confirm your Identity ?",
        manual_filling: "Filling manualy",
        passport_scanning: "By scanning passport",
        click_here_to_upload_passport: "Click here to upload passport",
        passport_required: "Passport required",
        worng_passport: "Please upload a valid passport image",
        private: "Private",
        public: "Public",
        mixed: "Mixed",
        type: "Type",
        nationalties: "Nationalties",
        languages: "Languages",
        schooling: "Educational Degree",
        primery: "Primary",
        middle: "Middle",
        secondary: "Secondary",
        diploma: "Diploma",
        bs: "BS Degree",
        masters: "Master Degree",
        phd: "PHD",
        prof: "PROF",
        registeration_by_email_text: "Registeration completed, please check your email to verify your account",
        social_links: "Social Links",
        link: "Link",
        save: "Save",
        skip: "Skip",
        password_reset: "Password reset",
        enter_new_password: "Enter the new password",
        password_reset_success: "Your password has been reset successfully",
        password_reset_error: "Error something haappend",
        download_app: "Download app",
        phone_required: "Phone number required",
        confirm_password_must_mach_password: "Password must match the confirm password field",
        confirm_password: "Confirm password",
        uploading_image: "Uploading Image",
        fee_placeholder: "In Dinar ex: 20,000 IQD",
        appointment_time_placeholder: "In Minutes ex: 15 minutes",
        please_enter_a_valid_info: "Please enter a valid information",
        if_you_did_not_receive_the_verification_email_click_here:
            "If you did not receive the verification email, click here",
        retake_image: "Retake live image",
        change_verification_methode: "Change verification methode",
        required: "required",
        min_length_validation: "Must be not less",
        max_length_validation: "Must be not more",
        incorrect: "incorrect",
        confirmed: "Not matches",
        numeric: "Must be a number",
        health_number_text: "Your 'Health Number' Information, please use it carefully and Share it only your Doctors",
        incorrect_password_format:
            "Must include at least one upper case letter, one lower case letter, and one numeric digit.",
        face_id: "Face ID",
        thumbs_print: "Thumbs print",
        left_thumb_print: "Left thumb print",
        right_thumb_print: "Right thumb print",
        download_insurance_card: "Download Insurance Card",
        send_data: "Send Data",
        blood_type: "Blood Type",
        family_number: "Family Number",
    },
};
