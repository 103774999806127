<template>
    <div class="main-wrapper">
        <ComingSoon />
    </div>
</template>

<script>
    import ComingSoon from "../components/other's/ComingSoon.vue";
    export default {
        components: {
            ComingSoon,
        },
    };
</script>
