<template>
    <ValidationProvider
        v-show="show"
        v-slot="{ errors }"
        :vid="randomName"
        class="form-group"
        :name="label"
        :disabled="disabled"
        :rules="rules"
        tag="div"
        mode="eager">
        <label v-if="label && !hideLabel">{{ t(label) }}</label>
        <span v-if="rules.includes('required')" class="text-sm text-danger mx-1">*</span>
        <vue-tel-input
            defaultCountry="iq"
            :disabled="disabled"
            dir="ltr"
            class="py-1"
            mode="international"
            v-model="field"></vue-tel-input>
        <small class="text-danger">{{ errors[0] }}</small>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from "vee-validate";
    import fieldMixins from "@/mixins/fieldMixins";

    export default {
        name: "VPhoneField",
        components: {
            ValidationProvider,
        },
        mixins: [fieldMixins],
        props: {
            type: {
                type: String,
                default: "text",
            },
            maxlength: String,
            hideLabel: Boolean,
        },
        computed: {
            isDisabled() {
                if (typeof this.disabled === "function") return this.disabled();
                return this.disabled;
            },
            field: {
                get() {
                    return this.value;
                },
                set(value) {
                    // eslint-disable-next-line no-console
                    this.$emit("input", value);
                },
            },
            randomName() {
                return Math.random().toString(36).substring(7);
            },
        },
        watch: {
            value() {
                this.field = this.value;
            },
        },
    };
</script>

<style scoped></style>
