<template>
    <section>
        <h6>{{ t("clinic_account_information") }}</h6>
        <VForm v-slot="{ onValidate }">
            <div class="row">
                <div class="col-sm-6 mt-3">
                    <VTextField v-model="clinic.name" label="clinic_name" rules="required"></VTextField>
                </div>
                <div class="col-sm-6 mt-3">
                    <VTextField v-model="doctorInfo.fullName" label="doctor_nickname" rules="required"></VTextField>
                </div>
                <div class="col-sm-6 mt-3">
                    <VPhoneField v-model="clinic.phone" label="phone_number" rules="required"></VPhoneField>
                </div>
                <div class="col-sm-6 mt-3">
                    <VSelectField
                        :items="governorates"
                        v-model="governorateId"
                        label="governorate"
                        rules="required"></VSelectField>
                </div>
                <div v-show="governorateId" class="col-sm-6 mt-3">
                    <VSelectField
                        :items="districts"
                        v-model="clinic.districtId"
                        label="district"
                        rules="required"></VSelectField>
                </div>
                <div class="col-sm-6 mt-3">
                    <VTextField v-model="clinic.address" label="address" rules="required"></VTextField>
                </div>
                <div class="col-sm-6 mt-3">
                    <VSelectField
                        :items="general"
                        v-model="doctorInfo.generalSpecialtyId"
                        label="general_specialties"
                        rules="required"></VSelectField>
                </div>
                <div v-show="doctorInfo.generalSpecialtyId" class="col-sm-6 mt-3">
                    <VSelectField
                        :items="specialties"
                        v-model="doctorInfo.specialtyId"
                        label="specialization"
                        rules="required"></VSelectField>
                </div>

                <div class="col-sm-6 mt-3">
                    <VTextField
                        v-model="doctorInfo.experience"
                        maxlength="2"
                        label="doctor_experience_years"
                        rules="required|numeric"></VTextField>
                </div>
                <div class="col-sm-6 mt-3">
                    <VTextField
                        :placeholder="t('fee_placeholder')"
                        maxlength="5"
                        v-model="doctorInfo.fee"
                        label="doctor_expected_fee"
                        rules="required|numeric"></VTextField>
                </div>
                <div class="col-sm-6 mt-3">
                    <VTextField
                        label="expect_visit_duration"
                        v-model="doctorInfo.appointmentTime"
                        maxlength="3"
                        :placeholder="t('appointment_time_placeholder')"
                        rules="required|numeric"></VTextField>
                    <div style="font-size: small" class="text-muted mx-1">
                        {{ t("expect_visit_duration_des") }}
                    </div>
                </div>
                <div class="col-sm-6 my-3">
                    <VTextArea label="description" v-model="clinic.description" rules="required"></VTextArea>
                </div>
            </div>
            <div class="mt-2 text-center text-danger">
                {{ error ? error : "" }}
            </div>
            <div class="row mt-2">
                <div class="col-12 text-right">
                    <button :disabled="loading" @click="onValidate(submitForm)" type="submit" class="btn btn-primary">
                        {{ loading ? t("loading") : t("next") }}
                    </button>
                </div>
            </div>
        </VForm>
    </section>
</template>

<script>
    import axios from "axios";
    import { mapState } from "vuex";
    import { minutesToTimeSpan, convertToEnglishNumbers, removeSpaces } from "../../../Utils/helpers";

    export default {
        props: ["handleClinicInfoSuccess", "token"],
        data() {
            return {
                loading: false,
                error: "",
                governorateId: "",
                doctorInfo: {
                    fullName: "",
                    experience: "",
                    generalSpecialtyId: "",
                    specialtyId: "",
                    appointmentTime: "",
                    fee: "",
                },
                clinic: {
                    name: "",
                    phone: "",
                    address: "",
                    districtId: "",
                    description: "",
                },
            };
        },
        computed: {
            general() {
                return this.$store.getters["doctor/general"];
            },
            specialties() {
                return this.$store.getters["doctor/specialties"];
            },
            governorates() {
                return this.$store.getters["auth/governorate"];
            },
            districts() {
                return this.$store.getters["auth/districts"];
            },
        },
        watch: {
            governorateId: function (v) {
                this.$store.dispatch("auth/get_districts_for", v);
            },
            "doctorInfo.generalSpecialtyId": function (v) {
                this.$store.dispatch("doctor/get_specialties_for", v);
            },
        },
        methods: {
            async submitForm() {
                this.loading = true;
                const instance = axios.create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    timeout: 30000,
                    headers: { Authorization: `Bearer ${this.token}` },
                });

                const { appointmentTime, fee, experience, ...others } = this.doctorInfo;

                const formData = new FormData();
                const doctorObjKeys = Object.keys(others);
                const doctorObjValues = Object.values(others);

                for (let i = 0; i < doctorObjKeys.length; i++) {
                    formData.append(`doctorInfo.${doctorObjKeys[i]}`, doctorObjValues[i]);
                }

                const { phone, ...otherData } = this.clinic;

                const clinicObjKeys = Object.keys(otherData);
                const clinicObjValues = Object.values(otherData);

                for (let i = 0; i < clinicObjKeys.length; i++) {
                    formData.append(clinicObjKeys[i], clinicObjValues[i]);
                }

                formData.append(
                    "doctorInfo.appointmentTime",
                    minutesToTimeSpan(convertToEnglishNumbers(appointmentTime))
                );
                formData.append("doctorInfo.fee", convertToEnglishNumbers(fee));
                formData.append("doctorInfo.experience", convertToEnglishNumbers(experience));
                formData.append("phone", removeSpaces(phone));

                try {
                    const { data } = await instance.post("User/AddNodeInformation", formData);
                    console.log({ data });
                    this.loading = false;
                    this.handleClinicInfoSuccess();
                } catch (error) {
                    if (error.response) {
                        // Request made and server responded
                        this.error = error.response.data.message;
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.error = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.error = error.message;
                    }
                    this.loading = false;
                }
            },
        },
    };
</script>
<style scoped>
    .modal {
        text-align: center;
    }

    @media screen and (min-width: 768px) {
        .modal:before {
            display: inline-block;
            vertical-align: middle;
            content: " ";
            height: 100%;
        }
    }

    .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
    }
</style>
