<template>
    <section>
        <div v-if="step === 0">
            <h4 class="mb-5">{{ t("face_id") }}</h4>
            <div class="text-center">
                <FaceID :faceIdSuccess="getImage" />
                <button @click="handleNext" v-if="liveImage" type="button" class="btn btn-sm btn-primary mt-2 px-5">
                    {{ t("next") }}
                </button>
            </div>
        </div>
        <div v-if="step === 1">
            <h4 class="mb-3">{{ t("thumbs_print") }}</h4>
            <div class="text-center">
                <FingerPrint :fingerPrintSuccess="getFingerPrints" />
            </div>
        </div>
        <div v-if="step === 2">
            <h4 class="mb-3">{{ t("send_data") }}</h4>
            <div class="text-center">
                <button
                    :disabled="loading"
                    @click="printPdfCard"
                    type="button"
                    class="btn btn-sm btn-primary mt-2 px-5">
                    {{ loading ? t("loading") : t("send_data") }}
                </button>
            </div>
        </div>
        <div v-if="step === 3">
            <h4 class="mb-3">{{ t("download_insurance_card") }}</h4>
            <div class="text-center">
                <a :href="link" target="blank" type="button" class="btn btn-sm btn-primary mt-2 px-5">
                    {{ t("download_insurance_card") }}
                </a>
            </div>
        </div>
    </section>
</template>

<script>
    import FaceID from "../Shared/CustomFaceId.vue";
    import FingerPrint from "../Shared/FingerPrint.vue";

    export default {
        name: "ExtraInfo",
        props: ["healthNumber"],
        data() {
            return {
                loading: false,
                step: 0,
                liveImage: "",
                leftFingerPrint: "",
                rightFingerPrint: "",
                link: "",
            };
        },
        methods: {
            handleNext() {
                this.step = this.step + 1;
            },
            handlePrev() {
                this.step = this.step - 1;
            },
            getImage(data) {
                this.liveImage = data;
            },
            getFingerPrints(left, right) {
                this.step = 2;
                this.rightFingerPrint = right;
                this.leftFingerPrint = left;
            },
            printPdfCard() {
                this.loading = true;
                const user = JSON.parse(localStorage.getItem("user"));
                console.log(JSON.parse(localStorage.getItem("user")));
                let submittedData = {
                    health_number: this.healthNumber,
                    image: this.liveImage,
                };
                this.$store
                    .dispatch("auth/requestPDF", { ...submittedData, ...user })
                    .then((data) => {
                        this.loading = false;
                        if (data.status === 200) {
                            this.link = data.data.link;
                            this.step = 3;
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            },
        },
        components: { FaceID, FingerPrint },
    };
</script>
