import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Features from "../views/Features.vue";
import SingleService from "../views/ServiceSingle.vue";
import Pricing from "../views/Pricing.vue";
import Contact from "../views/Contact.vue";
import AboutUs from "../views/AboutUs.vue";
// import PatientRegistration from "../views/Registeration/Patient";
import ClinicRegistration from "../views/Registeration/Clinic";
import PharmacyRegistration from "../views/Registeration/Pharmacy";
import LabRegistration from "../views/Registeration/Lab";
import ComingSoon from "../views/ComingSoon.vue";
import NotFound from "../views/NotFound.vue";
import ResetPassword from "../views/ResetPassword.vue";
import MobileAppDownload from "../views/MobileAppDownload.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/user/confirmEmail",
        name: "EmailAcivation",
        component: () => import("@/views/Auth/Activate"),
    },
    {
        path: "/user/invite",
        name: "ClinicInviteAccept",
        component: () => import("@/views/Auth/ClinicInviteAccept"),
    },
    {
        path: "/",
        component: Home,
        name: "Home",
        meta: {
            title: "Afea Healthcare System",
        },
    },
    {
        path: "/features",
        name: "Features",
        component: Features,
        meta: {
            title: "Features",
        },
    },
    {
        path: "/services/:id?",
        name: "Services",
        component: SingleService,
        meta: {
            title: "Services",
        },
    },
    {
        path: "/pricing/:packageType?",
        name: "Pricing",
        component: Pricing,
        meta: {
            title: "Pricing",
        },
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: {
            title: "Contact",
        },
    },
    {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs,
        meta: {
            title: "About Us",
        },
    },
    {
        path: "/patient/registration",
        name: "PatientRegistration",
        component: () => import("../views/Registeration/Insurance/index.vue"),
        meta: {
            title: "التسجيل في الضمان",
        },
    },
    // {
    //     path: "/patient/registration",
    //     name: "PatientRegistration",
    //     component: PatientRegistration,
    //     meta: {
    //         title: "Patient Registration",
    //     },
    // },
    {
        path: "/clinic/registration/:token?",
        name: "ClinicRegistration",
        component: ClinicRegistration,
        meta: {
            title: "Clinic Registration",
        },
    },
    {
        path: "/pharmacy/registration/:token?",
        name: "PharmacyRegistration",
        component: PharmacyRegistration,
        meta: {
            title: "Pharmacy Registration",
        },
    },
    {
        path: "/lab/registration/:token?",
        name: "LabRegistration",
        component: LabRegistration,
        meta: {
            title: "Lab Registration",
        },
    },
    {
        path: "/privacy",
        name: "Privacy",
        component: () => import("../views/Privacy"),
        meta: {
            title: "Privacy and Policy",
        },
    },
    {
        path: "/coming-soon",
        name: "ComingSoon",
        component: ComingSoon,
        meta: {
            title: "Coming Soon - Afea Healthcare System",
        },
    },
    {
        path: "/user/RestartByEmail/:token?",
        name: "ResetPassword",
        component: ResetPassword,
        meta: {
            title: "Password Reset - Afea Healthcare System",
        },
    },
    {
        path: "/mobile-app",
        name: "MobileApp",
        component: MobileAppDownload,
        meta: {
            title: "Download App - Afea Healthcare System",
        },
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFound,
        meta: {
            title: "Not Found",
        },
    },
];
const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash };
        }
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.afterEach((to) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    }
});

export default router;
