var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"form-group",attrs:{"vid":_vm.randomName,"name":_vm.label,"disabled":_vm.disabled,"rules":_vm.rules,"tag":"div","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(_vm.label && !_vm.hideLabel)?_c('label',[_vm._v(_vm._s(_vm.t(_vm.label)))]):_vm._e(),(_vm.rules.includes('required'))?_c('span',{staticClass:"text-sm text-danger mx-1"},[_vm._v("*")]):_vm._e(),((_vm.type)==='checkbox')?_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.field),expression:"field"}],staticClass:"form-control w-100",attrs:{"placeholder":_vm.t(_vm.placeholder),"disabled":_vm.isDisabled,"readonly":_vm.readonly,"maxlength":_vm.maxlength,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.field)?_vm._i(_vm.field,null)>-1:(_vm.field)},on:{"change":function($event){var $$a=_vm.field,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.field=$$a.concat([$$v]))}else{$$i>-1&&(_vm.field=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.field=$$c}}}},{
            ..._vm.$listeners,
            input: (e) => e.target.value,
        })):((_vm.type)==='radio')?_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.field),expression:"field"}],staticClass:"form-control w-100",attrs:{"placeholder":_vm.t(_vm.placeholder),"disabled":_vm.isDisabled,"readonly":_vm.readonly,"maxlength":_vm.maxlength,"type":"radio"},domProps:{"checked":_vm._q(_vm.field,null)},on:{"change":function($event){_vm.field=null}}},{
            ..._vm.$listeners,
            input: (e) => e.target.value,
        })):_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.field),expression:"field"}],staticClass:"form-control w-100",attrs:{"placeholder":_vm.t(_vm.placeholder),"disabled":_vm.isDisabled,"readonly":_vm.readonly,"maxlength":_vm.maxlength,"type":_vm.type},domProps:{"value":(_vm.field)},on:{"input":function($event){if($event.target.composing)return;_vm.field=$event.target.value}}},{
            ..._vm.$listeners,
            input: (e) => e.target.value,
        })),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }