<template>
    <div class="text-center mt-5 pt-5 container">
        <div class="row">
            <div class="col-md-12">
                <img src="@/assets/images/afea-logo.png" width="200" />
                <hr />
            </div>
        </div>
        <h2>Loading...</h2>
    </div>
</template>

<script>
    export default {
        mounted() {
            if (this.$device.ios) {
                window.location.href = "https://apps.apple.com";
            } else if (this.$device.android) {
                window.location.href = "https://play.google.com/store/apps/details?id=health.afea&pli=1";
            }
        },
    };
</script>
