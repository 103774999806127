<template>
    <ValidationProvider
        v-slot="{ errors }"
        :vid="randomName"
        class="form-group"
        :name="label"
        return="id"
        :disabled="disabled"
        :rules="rules"
        tag="div"
        mode="eager">
        <label v-if="label && !hideLabel">{{ t(label) }}</label>
        <span v-if="!hideLabel && rules.includes('required')" class="text-sm text-danger mx-1">*</span>
        <select
            ref="field"
            v-model="field"
            :placeholder="t(placeholder)"
            class="form-control w-100"
            :disabled="isDisabled">
            <template v-if="hasItems">
                <option v-for="(item, index) in items" :key="index" :value="item[itemValue]">
                    {{ item[itemText] }}
                </option>
            </template>
        </select>
        <small class="text-danger">{{ errors[0] }}</small>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from "vee-validate";
    import fieldMixins from "@/mixins/fieldMixins";
    import VSelect from "vue-select";

    export default {
        name: "VSelectField",
        components: {
            ValidationProvider,
            VSelect,
        },
        mixins: [fieldMixins],
        props: {
            add: Boolean,
            loadmore: Boolean,
            items: {
                type: Array,
                default: () => {
                    return [];
                },
            },
            itemText: {
                required: false,
                default: "text",
            },
            itemValue: {
                required: false,
                default: "value",
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            searchable: Boolean,
            hideLabel: Boolean,
        },
        computed: {
            hasItems() {
                return Array.isArray(this.items) && this.items.length !== 0;
            },
            isDisabled() {
                if (typeof this.disabled === "function") return this.disabled();
                return this.disabled;
            },
            field: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit("input", value);
                },
            },
            randomName() {
                return Math.random().toString(36).substring(7);
            },
        },
        watch: {
            value() {
                this.field = this.value;
            },
        },
        methods: {
            remove(index) {
                this.field.splice(index, 1);
            },
            addItemList() {
                this.$emit("createNew");
            },
            getItemName(item) {
                return this.items.find((d) => d.value === item).text;
            },
        },
    };
</script>

<style src="vue-select/dist/vue-select.css"></style>

<style>
    .v-select .vs__dropdown-toggle {
        background: #ffffff !important;
        padding: 0.6rem !important;
    }

    .v-select .vs__selected {
        position: absolute;
    }

    .vs__clear {
        margin-left: 10px;
        padding-top: 6px !important;
    }

    .vs__open-indicator {
        margin-top: 5px;
        margin-left: 5px;
    }

    .v-select .vs__dropdown-toggle {
        padding: 4px !important;
    }

    .vs__actions {
        padding: 0 !important;
    }
</style>
