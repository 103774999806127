<template>
    <section class="feature-section ptb-120">
        <div class="container">
            <ServiceRow orderType="order-lg-2" :details="patient_service" />
            <PatientServiceRow orderType="order-lg-1" :details="step1" />
<!--            <PatientServiceRow orderType="order-lg-2" :details="step2" />-->
<!--            <PatientServiceRow orderType="order-lg-1" :details="step3" />-->
<!--            <PatientServiceRow orderType="order-lg-2" :details="step4" />-->
<!--            <PatientServiceRow orderType="order-lg-1" :details="step5" />-->
<!--            <PatientServiceRow orderType="order-lg-2" :details="step6" />-->
<!--            <PatientServiceRow orderType="order-lg-1" :details="step7" />-->
<!--            <PatientServiceRow orderType="order-lg-2" :details="step8" />-->
        </div>
    </section>
</template>

<script>
    import PatientServiceRow from "./PatientServiceRow.vue";
    import ServiceRow from "./ServiceRow.vue";

    export default {
        name: "LabServices",
        components: {
            PatientServiceRow,
            ServiceRow,
        },
        data() {
            return {
                patient_service: {
                    image: "patient/landing.png",
                    title: "السجل الصحي",
                    image_class: "w-50",
                    features: [
                        "التحقق من التغطية: يمكن للمرضى التحقق بسهولة من التغطية الصحية المتاحة لهم من خلال النظام.",
                        "تتبع الخدمات: يمكن للنظام تتبع الخدمات الصحية التي تم تقديمها",
                        "ضمان الاستحقاقات: النظام يساعد في ضمان أن المرضى يحصلون على الرعاية الصحية التي يستحقونها",
                        "التقارير والتحليلات: النظام يوفر تقارير وتحليلات عن استخدام الضمان الصحي، مما يساعد في اتخاذ قرارات مستنيرة بشأن الرعاية الصحية.",
                    ],
                },
                step1: {
                    image: "patient/Patient-Step-1.png",
                    title: "ماذا يقدم السجل الصحي من خلال نظام عافية",
                    features: [
                        "نظام عافية يوفر للمشتركين  حرية الاختيار لمقدم الخدمة في أي مكان بالعراق وفي أي وقت، معززا مبدأ التكافل الاجتماعي والعدالة الصحية",
                    ],
                },
            };
        },
    };
</script>