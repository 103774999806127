<template>
    <header class="main-header w-100" :class="darkNav ? 'position-absolute' : ''">
        <nav
            class="navbar navbar-expand-xl sticky-header"
            :class="[darkNav ? 'navbar-dark' : 'navbar-light', isSticky ? 'affix' : '']">
            <div class="container d-flex align-items-center justify-content-lg-between position-relative">
                <router-link to="/" class="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none">
                    <img
                        src="@/assets/images/afea-logo-light.png"
                        alt="logo"
                        width="115"
                        class="img-fluid logo-white" />
                    <img src="@/assets/images/afea-logo.png" alt="logo" width="115" class="img-fluid logo-color" />
                </router-link>

                <router-link
                    class="navbar-toggler position-absolute right-0 border-0"
                    to="#offcanvasWithBackdrop"
                    role="button">
                    <span
                        class="far fa-bars"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasWithBackdrop"
                        aria-controls="offcanvasWithBackdrop"></span>
                </router-link>

                <div class="collapse navbar-collapse justify-content-center">
                    <ul class="nav col-12 col-md-auto justify-content-center main-menu">
                        <li>
                            <router-link to="/" class="nav-link">
                                {{ t("home") }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/#services" class="nav-link">
                                {{ t("services") }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/features" class="nav-link">
                                {{ t("features") }}
                            </router-link>
                        </li>
                        <li v-if="!this.$device.mobile">
                            <router-link to="/#mobile" class="nav-link">
                                {{ t("download_app") }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/contact" class="nav-link">
                                {{ t("contact_us") }}
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
                    <div class="nav-item dropdown">
                        <button
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            class="btn px-2 btn-sm btn-primary">
                            {{ t("get_started") }}
                        </button>

                        <a
                            href="https://dashboard.afea.healthcare/"
                            class="mx-2 btn btn-primary px-2 btn-sm"
                            target="_blank">
                            {{ t("login") }}
                        </a>

                        <div class="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                            <div class="dropdown-grid rounded-custom width-half">
                                <div class="dropdown-grid-item">
                                    <h6 class="drop-heading">
                                        {{ t("get_started") }}
                                    </h6>
                                    <router-link to="/patient/registration" class="dropdown-link">
                                        <span class="demo-list bg-primary rounded text-white fw-bold">1</span>
                                        <div class="dropdown-info">
                                            <div class="drop-title">تسجيل حساب صحي</div>
                                        </div>
                                    </router-link>
                                    <!--                                  <router-link to="/patient/registration" class="dropdown-link">-->
                                    <!--                                        <span class="demo-list bg-primary rounded text-white fw-bold">1</span>-->
                                    <!--                                        <div class="dropdown-info">-->
                                    <!--                                            <div class="drop-title">{{ t("patientService") }}</div>-->
                                    <!--                                        </div>-->
                                    <!--                                    </router-link>-->
                                    <router-link to="/clinic/registration" class="dropdown-link">
                                        <span class="demo-list bg-primary rounded text-white fw-bold">2</span>
                                        <div class="dropdown-info">
                                            <div class="drop-title">{{ t("clinicService") }}</div>
                                        </div>
                                    </router-link>
                                    <router-link to="/pharmacy/registration" class="dropdown-link">
                                        <span class="demo-list bg-primary rounded text-white fw-bold">3</span>
                                        <div class="dropdown-info">
                                            <div class="drop-title">{{ t("pharmacyService") }}</div>
                                        </div>
                                    </router-link>
                                    <router-link to="/lab/registration" class="dropdown-link">
                                        <span class="demo-list bg-primary rounded text-white fw-bold">4</span>
                                        <div class="dropdown-info">
                                            <div class="drop-title">
                                                {{ t("laboratoryService") }}
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Offcanvas />
            </div>
        </nav>
    </header>
</template>

<script>
    import Offcanvas from "./Offcanvas.vue";

    export default {
        components: { Offcanvas },
        name: "Navbar",
        props: {
            darkNav: String,
        },
        data() {
            return {
                isSticky: false,
            };
        },
        mounted() {
            window.addEventListener("scroll", () => {
                let scrollPos = window.scrollY;
                if (scrollPos >= 80) {
                    this.isSticky = true;
                } else {
                    this.isSticky = false;
                }
            });
        },
    };
</script>