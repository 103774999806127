<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <img src="@/assets/images/afea-logo.png" width="200" />
                <hr />
            </div>
        </div>
        <div>
            <h1 class="text-center pt-4">{{ t("password_reset") }}</h1>
            <VForm v-if="status === 'pending'" class="mt-5" v-slot="{ onValidate }">
                <div class="row d-flex justify-content-center">
                    <div class="col-sm-6 mb-3">
                        <VPasswordField
                            name="password"
                            vid="password"
                            v-model="password"
                            label="enter_new_password"
                            rules="required|min:8"></VPasswordField>
                    </div>
                    <div class="col-sm-6 mb-3">
                        <VPasswordField
                            name="confirmPassword"
                            label="confirm_password"
                            v-model="confirmPassword"
                            rules="required|min:8|confirmed:password"></VPasswordField>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12 text-right">
                        <button
                            :disabled="loading"
                            @click="onValidate(submitForm)"
                            type="submit"
                            class="btn btn-primary">
                            {{ loading ? t("loading") : t("save") }}
                        </button>
                    </div>
                </div>
            </VForm>
        </div>
        <div class="text-center mt-5" v-if="status === 'success'">
            <h4 class="text-bold">
                <b>{{ t("password_reset_success") }}</b>
            </h4>
        </div>
        <div class="text-center mt-5" v-if="status === 'error'">
            <h4 class="text-bold">
                <b>{{ t("password_reset_error") }}</b>
            </h4>
        </div>
        <div class="row">
            <div>
                <hr />
            </div>
        </div>
    </div>
</template>

<script>
    import $alert from "sweetalert2";
    export default {
        name: "ResetPassword",
        data() {
            return {
                loading: false,
                password: "",
                confirmPassword: "",
                status: "pending",
            };
        },
        methods: {
            async submitForm() {
                if (this.password === this.confirmPassword) {
                    this.status = "pending";
                    this.loading = true;
                    this.$store
                        .dispatch("auth/resetPassword", { token: this.$route.query.token, password: this.password })
                        .then(() => {
                            this.status = "success";
                            this.loading = false;
                        })
                        .catch((e) => {
                            this.status = "error";
                            this.loading = false;
                        });
                } else {
                    $alert.fire({
                        icon: "error",
                        title: "error",
                        text: this.t("confirm_password_must_mach_password"),
                    });
                }
            },
        },
    };
</script>
