<template>
    <section class="feature-section two-bg-dark-light ptb-120" id="features">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-6 col-md-6">
                    <div class="image-wrap mb-5 mb-md-0 mb-lg-0 mb-xl-0" data-aos="fade-right">
                        <img
                            src="@/assets/img/screen/clinic/clinic2.png"
                            alt="feature img"
                            class="img-fluid rounded-custom" />
                    </div>
                </div>
                <div class="col-lg-5 col-md-6">
                    <div class="feature-content-wrap" data-aos="fade-up" data-aos-delay="50">
                        <h4 class="h5 text-primary">{{ t("our_services") }}</h4>
                        <h2>{{ t("system_features") }}</h2>
                        <p>
                            {{ t("afea_description") }}
                        </p>
                        <ul class="list-unstyled d-flex flex-wrap list-two-col mt-4">
<!--                            <li class="d-flex align-items-start">-->
<!--                                <div class="icon-box bg-primary rounded me-4">-->
<!--                                    <i class="fas fa-heart-pulse text-white"></i>-->
<!--                                </div>-->
<!--                                <div class="icon-content mt-2">-->
<!--                                    <h3 class="h5">-->
<!--                                      السجل الصحي-->
<!--                                    </h3>-->
<!--                                </div>-->
<!--                            </li>-->

                            <li class="d-flex align-items-start">
                                <div class="icon-box bg-primary rounded me-4">
                                    <i class="fas fa-capsules text-white"></i>
                                </div>
                                <div class="icon-content mt-2">
                                    <h3 class="h5">{{ t("pharmacies_management") }}</h3>
                                </div>
                            </li>

                            <li class="d-flex align-items-start">
                                <div class="icon-box bg-info rounded me-4">
                                    <i class="fas fa-flask text-white"></i>
                                </div>
                                <div class="icon-content mt-2">
                                    <h3 class="h5">{{ t("laboratory_management") }}</h3>
                                </div>
                            </li>

                            <li class="d-flex align-items-start">
                                <div class="icon-box bg-info rounded me-4">
                                    <i class="fa-solid fa-calendar-days text-white"></i>
                                </div>
                                <div class="icon-content mt-2">
                                    <h3 class="h5">{{ t("online_booking") }}</h3>
                                </div>
                            </li>

                            <li class="d-flex align-items-start">
                                <div class="icon-box bg-warning rounded me-4">
                                    <i class="fa-solid fa-credit-card-front text-white"></i>
                                </div>
                                <div class="icon-content mt-2">
                                    <h3 class="h5">{{ t("electronic_payment") }}</h3>
                                </div>
                            </li>

                            <li class="d-flex align-items-start">
                                <div class="icon-box bg-primary rounded me-4">
                                    <i class="fa-solid fa-calendar-days text-white"></i>
                                </div>
                                <div class="icon-content mt-2">
                                    <h3 class="h5">{{ t("appointment_reminder") }}</h3>
                                </div>
                            </li>

                            <li class="d-flex align-items-start">
                                <div class="icon-box bg-danger rounded me-4">
                                    <i class="fa-solid fa-triangle-exclamation text-white"></i>
                                </div>
                                <div class="icon-content mt-2">
                                    <h3 class="h5">{{ t("reduce_medical_errors") }}</h3>
                                </div>
                            </li>

                            <li class="d-flex align-items-start">
                                <div class="icon-box bg-info rounded me-4">
                                    <i class="fas fa-book text-white"></i>
                                </div>
                                <div class="icon-content mt-2">
                                    <h3 class="h5">{{ t("medical_record_management") }}</h3>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "FetureThree",
    };
</script>
