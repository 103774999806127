<template>
    <div>
        <Navbar />
        <AboutPageHeader />
        <OurTeam />
        <CtaTwo />
        <Footer />
    </div>
</template>

<script>
    import AboutPageHeader from "../components/common/AboutPageHeader.vue";
    import CtaTwo from "../components/cta/CtaTwo.vue";
    import Footer from "../components/footer/Footer.vue";
    import Navbar from "../components/nav/Navbar.vue";
    import OurTeam from "../components/team/OurTeam.vue";

    export default {
        name: "AboutUs",
        components: {
            AboutPageHeader,
            OurTeam,
            CtaTwo,
            Navbar,
            Footer,
        },
    };
</script>
