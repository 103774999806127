<template>
    <section>
        <h6>{{ t("privacy_policy_and_the_terms_of_use") }}</h6>
        <div class="col-12 mt-2">
            <div class="p-3">
                <p v-if="policy.description" v-html="policy.description" />
                <h4 v-else class="pt-3 text-center">{{ t("loading") }}</h4>
            </div>
        </div>
        <div v-if="policy.description" class="col-12 mt-2">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="isAccept" id="flexCheckChecked" />
                <label class="form-check-label small" for="flexCheckChecked">
                    {{ t("poilcy_agreement") }}
                </label>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";

    export default {
        name: "PrivacyPolicyCard",
        props: ["handleAcceptPolicy"],
        data() {
            return {
                isAccept: false,
            };
        },
        computed: mapGetters(["policy"]),
        methods: {
            ...mapActions(["fetchPolicy"]),
        },
        watch: {
            isAccept: function (v) {
                this.handleAcceptPolicy(v);
            },
        },
        created() {
            this.fetchPolicy();
        },
    };
</script>
