<template>
    <section class="contact-us ptb-120 position-relative overflow-hidden">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-xl-5 col-lg-5 col-md-12">
                    <div class="section-heading" data-aos="fade-up">
                        <h4 class="h5 text-primary">
                            {{ t("quick_support") }}
                        </h4>
                        <h2>
                            {{ t("get_in_touch_today") }}
                        </h2>
                        <p>
                            {{ t("contact_us_des") }}
                        </p>
                    </div>
                    <div class="row justify-content-between pb-5">
                        <div class="col-sm-6 mb-4 mb-md-0 mb-lg-0" data-aos="fade-up" data-aos-delay="50">
                            <div class="icon-box d-inline-block rounded-circle bg-primary-soft">
                                <i class="fas fa-phone fa-2x text-primary"></i>
                            </div>
                            <div class="contact-info">
                                <h5>
                                    {{ t("call_us") }}
                                </h5>
                                <p>
                                    {{ t("call_us_des") }}
                                </p>
                                <a dir="ltr" href="tel:(+964) 780 0422 422" class="read-more-link text-decoration-none">
                                    <i class="far fa-phone me-2"></i>
                                    (+964) 780 0422 422
                                </a>
                            </div>
                        </div>
                        <div class="col-sm-6" data-aos="fade-up" data-aos-delay="100">
                            <div class="icon-box d-inline-block rounded-circle bg-danger-soft">
                                <i class="fas fa-comment-alt-lines fa-2x text-danger"></i>
                            </div>
                            <div class="contact-info">
                                <h5>
                                    {{ t("chat_us") }}
                                </h5>
                                <p>
                                    {{ t("chat_us_des") }}
                                </p>
                                <a dir="ltr" href="mailto:info@afea.health" class="read-more-link text-decoration-none">
                                    <i class="far fa-envelope me-2"></i>
                                    info@afea.health
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 col-lg-7 col-md-12">
                    <div
                        class="register-wrap p-5 bg-white shadow rounded-custom position-relative"
                        data-aos="fade-up"
                        data-aos-delay="150">
                        <form @submit="sendMessage" class="register-form position-relative z-5">
                            <h3 class="mb-5 fw-medium">
                                {{ t("fill_contact_form_title") }}
                            </h3>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="input-group mb-3">
                                        <input
                                            v-model="template_params.user_name"
                                            type="text"
                                            required
                                            class="form-control"
                                            :placeholder="t('name')"
                                            aria-label="name" />
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="input-group mb-3">
                                        <input
                                            v-model="template_params.user_email"
                                            type="text"
                                            required
                                            class="form-control"
                                            :placeholder="t('email')"
                                            aria-label="email" />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-group mb-3">
                                        <input
                                            v-model="template_params.user_subject"
                                            type="text"
                                            required
                                            class="form-control"
                                            :placeholder="t('subject')"
                                            aria-label="subject" />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-group mb-3">
                                        <textarea
                                            v-model="template_params.user_message"
                                            required
                                            class="form-control"
                                            :placeholder="t('your_message')"
                                            style="height: 120px"></textarea>
                                    </div>
                                </div>
                                <!-- <div class="col-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                    />
                    <label
                      class="form-check-label small"
                      for="flexCheckChecked"
                    >
                      Yes, I'd like to receive occasional marketing emails from
                      us. I have the right to opt out at any time.
                      <a href="#"> View privacy policy</a>.
                    </label>
                  </div>
                </div> -->
                                <div class="col-12">
                                    <button type="submit" class="btn btn-primary mt-4 d-block w-100">
                                        {{ loading ? t("loading") : t("send") }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-dark cont-dot position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z--1 py-5">
            <div class="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-5"></div>
            <div class="bg-circle rounded-circle circle-shape-1 position-absolute bg-warning right-5"></div>
        </div>
    </section>
</template>

<script>
    import axiosInstance from "../../Utils/axios";
    import $alert from "sweetalert2";

    export default {
        name: "ContactForm",
        data() {
            return {
                loading: false,
                template_params: {
                    user_subject: "",
                    user_message: "",
                    user_name: "",
                    user_email: "",
                },
            };
        },
        methods: {
            async sendMessage(e) {
                this.loading = true;
                e.preventDefault();
                let details = {
                    service_id: "service_43u4hdd",
                    template_id: "template_egwryc4",
                    user_id: "f2uyTYoddOEQcbkmY",
                    template_params: this.template_params,
                };
                try {
                    const { data } = await axiosInstance.post("https://api.emailjs.com/api/v1.0/email/send", details);
                    this.loading = false;
                    if (data === "OK") {
                        (this.template_params = {
                            user_subject: "",
                            user_message: "",
                            user_name: "",
                            user_email: "",
                        }),
                            $alert.fire(this.t("Done"), this.t("Sent successfully"), "success");
                    }
                } catch (error) {
                    this.loading = false;
                    $alert.fire({
                        icon: "error",
                        title: "error",
                        text: "email",
                    });
                }
            },
        },
    };
</script>

<style scoped>
    .cont-dot {
        background-image: url("../../assets/img/shape/dot-dot-wave-shape.svg");
        background-repeat: no-repeat;
        background-position: center top;
    }
</style>
