import Vue from "vue";
import Vuex from "vuex";
import entitlements from "./modules/entitlements";
import policy from "./modules/policy";
import contract from "./modules/contract";
import auth from "./modules/auth";
import doctor from "./modules/doctor";

// Create store
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        entitlements,
        policy,
        contract,
        auth,
        doctor,
    },
});
