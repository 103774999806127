<template>
    <section>
        <div class="mt-3 text-center">
            <h3>{{ t("do_you_have_qi_card") }}</h3>
            <div class="mt-5">
                <button @click="handleYes" class="btn btn-primary mx-1">
                    {{ t("yes") }}
                </button>
                <button @click="handleNo" class="btn btn-info mx-1">
                    {{ t("no") }}
                </button>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "StartRegisterationCard",
        props: ["qiCustomerCheck"],
        methods: {
            handleYes() {
                this.qiCustomerCheck(true);
            },
            handleNo() {
                this.qiCustomerCheck(false);
            },
        },
    };
</script>
