import { extend } from "vee-validate";
import { regex, required, numeric, email, min, max, confirmed, alpha_spaces } from "vee-validate/dist/rules";
import lang from "./lang";

const t = (word) => lang[localStorage.getItem("lang") || "ar"][word] || word;

extend("confirmed", {
    ...confirmed,
    message: () => `${t("confirmed")} `,
});

extend("required", {
    ...required,
    message: (field) => `${t(field)} ${t("required")} `,
});
extend("regex", {
    ...regex,
    message: (field) => `${t(field)} ${t("incorrect_password_format")}`,
});

extend("numeric", {
    ...numeric,
    message: (field) => `${t(field)} ${t("numeric")} `,
});
extend("max", {
    ...max,
    message: () => `${t("max_length_validation")}`,
});
extend("min", {
    ...min,
    message: () => `${t("min_length_validation")}`,
});

extend("email", {
    ...email,
    message: (field) => `${t(field)} ${t("incorrect")}`,
});

extend("alpha", {
    ...alpha_spaces,
    message: (field) => `${field}  يجب ان يحتوي على حروف ابجدية فقط`,
});

extend("health_number", {
    validate: (value) => /^-?\d+(?:\.\d+)?$/.test(value) && value.length === 9,
    message: () => `الرقم الصحي يتكون من 9 ارقام فقط`,
});