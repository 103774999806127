import axiosInstance from "../../Utils/axios";

const state = {
    entitlements: [],
};

const getters = {
    allEntitlements: (state) => state.entitlements,
};
const actions = {
    async getEntitlements({ commit }, type) {
        let url = "SuperAdmin/GetEntitelments?skip=0&take=100";
        if (type) {
            url = `SuperAdmin/GetEntitelments?skip=0&take=100&package=${type}`;
        }
        const response = await axiosInstance.get(url);
        commit("setEntitlements", JSON.parse(JSON.stringify(response.data)).data);
    },
};
const mutations = {
    setEntitlements: (state, entitlements) => (state.entitlements = entitlements),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
