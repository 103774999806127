<template>
    <ValidationObserver ref="form" v-slot="{ invalid }" :class="{ 'd-flex': flex }">
        <slot :invalid="invalid" :on-validate="onValidate"></slot>
    </ValidationObserver>
</template>

<script>
    import { ValidationObserver } from "vee-validate";
    export default {
        name: "VForm",
        components: {
            ValidationObserver,
        },
        props: {
            flex: Boolean,
        },
        methods: {
            validate() {
                return this.$refs.form.validate();
            },
            onValidate(callback) {
                this.$refs.form.validate().then((isValid) => {
                    if (isValid) callback();
                });
            },
        },
    };
</script>

<style scoped></style>
