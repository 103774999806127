<template>
    <div class="main-wrapper">
        <Navbar darkNav="darkNav" />
        <section class="sign-up-in-section bg-dark ptb-120 page-header">
            <div class="container">
                <div class="row align-items-center justify-content-between">
                    <RegisterationHeader />
                    <div class="col-md-10 mx-auto">
                        <h3 class="mb-3 text-center">{{ t("register") }} {{ t("clinicService") }}</h3>
                        <div class="register-wrap rounded-custom px-4 py-5 bg-white shadow mt-lg-0 mt-xl-0">
                            <div class="register-form">
                                <div v-show="step === 'TERMS_CONDITIONS'">
                                    <PrivacyPolicyCard :handleAcceptPolicy="handleAcceptPolicy"></PrivacyPolicyCard>
                                </div>
                                <div v-show="step === 'CONTRACT_CONDITIONS'">
                                    <ContractCard :handleAcceptContract="handleAcceptContract"></ContractCard>
                                </div>
                                <div v-if="step === 'FIND_CUSTOMER'">
                                    <FindCustomer :qiCustomerCheck="qiCustomerCheck"></FindCustomer>
                                </div>
                                <div v-if="step === 'REGISTER_BY_QI_CARD'">
                                    <QiCardRegistration :handleSuccess="handleQiHolderRegistrationComplete" />
                                </div>
                                <div v-show="step === 'USER_INFO_STEP'">
                                    <UserInfoForm
                                        :userType="9"
                                        :cardNumber="cardNumber"
                                        :userInfoFromQi="userInfoFromQi"
                                        :userRegisterationSuccess="userRegisterationSuccess" />
                                </div>
                                <div v-show="step === 'ORGINIZATION_INFO_STEP'">
                                    <ClinicInfoForm :token="token" :handleClinicInfoSuccess="handleClinicInfoSuccess" />
                                </div>
                                <div v-show="step === 'LICENCE_STEP'">
                                    <LicenseCard :licenseInfoSuccess="licenseInfoSuccess" :token="token" />
                                </div>
                                <div v-show="step === 'VERIFICATION_STEP'">
                                    <AccountVerification :verificationSuccess="verificationSuccess" :token="token" />
                                </div>
                                <div v-show="step === 'REGISTRATION_SUCCCESS_MESSAGE_PENDING'">
                                    <h4>{{ t("registeration_complete") }}</h4>
                                    <div class="text-center mx-auto">
                                        <p style="font-size: 20px">
                                            {{ t("non_patient_registration_complete_text") }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FooterBottom />
    </div>
</template>

<script>
    import FooterBottom from "../../../components/footer/FooterBottom.vue";
    import Navbar from "../../../components/nav/Navbar.vue";
    import UserInfoForm from "../Shared/UserInfoForm.vue";
    import QiCardRegistration from "../Shared/QiCardRegistration.vue";
    import RegisterationHeader from "../Shared/RegisterationHeader.vue";
    import PrivacyPolicyCard from "../Shared/PrivacyPolicyCard.vue";
    import FindCustomer from "../Shared/FindCustomer.vue";
    import AccountVerification from "../Shared/AccountVerification.vue";
    import ClinicInfoForm from "./ClinicInfoForm.vue";
    import LicenseCard from "../Shared/LicenseCard.vue";
    import ContractCard from "../Shared/ContractCard.vue";
    import { decodeToken } from "../../../Utils/helpers";

    export default {
        data() {
            return {
                step: "TERMS_CONDITIONS",
                cardNumber: "",
                userInfoFromQi: "",
                token: this.$route.params.token || "",
            };
        },
        components: {
            Navbar,
            FooterBottom,
            UserInfoForm,
            QiCardRegistration,
            RegisterationHeader,
            PrivacyPolicyCard,
            FindCustomer,
            AccountVerification,
            ClinicInfoForm,
            LicenseCard,
            ContractCard,
        },
        mounted() {
            // handle if uncompleted user came from dashboard
            if (this.$route.params.token) {
                let user = decodeToken(this.$route.params.token);
                if (user) {
                    switch (user.step) {
                        case "Register":
                            this.step = "VERIFICATION_STEP";
                            break;
                        case "Confirm":
                            this.step = "ORGINIZATION_INFO_STEP";
                            break;
                        case "AddOrgInfo":
                            this.step = "LICENCE_STEP";
                            break;
                        case "AddAdditionalInfo":
                            this.step = "REGISTRATION_SUCCCESS_MESSAGE_PENDING";
                            break;
                        default:
                            break;
                    }
                }
            }
        },
        methods: {
            handleAcceptPolicy(approved) {
                if (approved) {
                    this.step = "CONTRACT_CONDITIONS";
                }
            },
            handleAcceptContract(approved) {
                if (approved) {
                    this.step = "FIND_CUSTOMER";
                }
            },
            qiCustomerCheck(haveQi) {
                if (haveQi) {
                    this.step = "REGISTER_BY_QI_CARD";
                } else {
                    this.step = "USER_INFO_STEP";
                }
            },
            userRegisterationSuccess(token) {
                this.token = token;
                this.step = "VERIFICATION_STEP";
            },
            verificationSuccess(success) {
                if (success) {
                    this.step = "ORGINIZATION_INFO_STEP";
                }
            },
            handleClinicInfoSuccess() {
                this.step = "LICENCE_STEP";
            },
            licenseInfoSuccess() {
                this.step = "REGISTRATION_SUCCCESS_MESSAGE_PENDING";
            },
            handleQiHolderRegistrationComplete(info, cardNumber) {
                this.userInfoFromQi = info;
                this.cardNumber = cardNumber;
                this.step = "USER_INFO_STEP";
            },
        },
    };
</script>
