<template>
    <section>
        <div class="row">
            <div class="text-center">
                <div v-if="isMobile()" class="mb-3">
                    <label for="files" class="btn btn-primary text-white">
                        {{ t("open_camera_take_live_photo") }}
                    </label>
                    <input
                        id="files"
                        style="visibility: hidden"
                        :placeholder="t('open_camera_take_live_photo')"
                        class="form-control"
                        @change="fileHandler"
                        type="file"
                        accept="image/*"
                        capture="camera" />
                    <img
                        v-show="image"
                        :src="image"
                        style="object-fit: cover; height: 250px; margin-top: -40px"
                        class="img-fluid" />
                </div>

                <div v-else>
                    <button v-if="!isCameraOpen" type="button" class="btn btn-primary" @click="toggleCamera">
                        <span class="text-white">
                            {{ t("open_camera_take_live_photo") }}
                        </span>
                    </button>
                </div>

                <div v-show="isCameraOpen && isLoading">
                    <div class="spinner-border my-5" role="status"></div>
                </div>

                <div class="my-2" v-if="isCameraOpen" v-show="!isLoading">
                    <video v-show="!isPhotoTaken" ref="camera" :width="450" :height="337.5" autoplay></video>

                    <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas>
                </div>

                <div v-if="isCameraOpen && !isLoading && !isPhotoTaken">
                    <button type="button" class="btn btn-sm btn-light" @click="takePhoto">
                        <i class="fa-solid fa-camera"></i>
                    </button>
                </div>
                <div v-if="liveImage">
                    <button type="button" class="btn btn-sm btn-light" @click="retakePhoto">
                        {{ t("retake") }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { getBase64Key } from "../../../Utils/helpers";

    export default {
        name: "FaceId",
        props: ["faceIdSuccess"],

        data() {
            return {
                photoError: "",
                image: "",
                liveImage: "",
                isCameraOpen: false,
                isPhotoTaken: false,
                isLoading: false,
                photo: "",
            };
        },
        watch: {
            liveImage: function (photo) {
                if (photo) {
                    let img = this.isMobile() ? photo : photo.replace("data:", "").replace(/^.+,/, "");
                    this.faceIdSuccess(img);
                }
            },
        },
        methods: {
            async fileHandler($event) {
                this.liveImage = await getBase64Key($event.target.files[0]);
                this.image = URL.createObjectURL($event.target.files[0]);
            },
            toggleCamera() {
                if (this.isCameraOpen) {
                    this.isCameraOpen = false;
                    this.isPhotoTaken = false;
                    this.stopCameraStream();
                } else {
                    this.isCameraOpen = true;
                    this.createCameraElement();
                }
            },

            createCameraElement() {
                this.isLoading = true;

                const constraints = (window.constraints = {
                    audio: false,
                    video: true,
                });

                navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then((stream) => {
                        this.isLoading = false;
                        this.$refs.camera.srcObject = stream;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        alert("May the browser didn't support or there is some errors.");
                    });
            },

            stopCameraStream() {
                let tracks = this.$refs.camera.srcObject.getTracks();
                tracks.forEach((track) => {
                    track.stop();
                });
            },

            takePhoto() {
                this.isPhotoTaken = !this.isPhotoTaken;
                const context = this.$refs.canvas.getContext("2d");
                context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
                const canvas = document
                    .getElementById("photoTaken")
                    .toDataURL("image/jpeg")
                    .replace("image/jpeg", "image/octet-stream");
                this.liveImage = canvas;
                this.stopCameraStream();
            },

            retakePhoto() {
                if (this.isMobile()) {
                    this.photoError = "";
                    this.liveImage = "";
                    this.image = "";
                    this.faceIdSuccess("");
                } else {
                    this.photoError = "";
                    this.isCameraOpen = true;
                    this.createCameraElement();
                    this.isPhotoTaken = !this.isPhotoTaken;
                    this.liveImage = "";
                    this.faceIdSuccess("");
                }
            },
            isMobile() {
                if (screen.width <= 950) {
                    return true;
                } else {
                    return false;
                }
            },
        },
    };
</script>
