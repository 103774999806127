<template>
    <section>
        <div class="row">
            <div class="text-center">
                <div v-show="step === 'OPEN_CAMERA'" @click="toogleFaceId" class="mb-3">
                    <label for="files" class="btn btn-primary text-white">
                        {{ t("open_camera_take_live_photo") }}
                    </label>
                </div>
            </div>
            <div v-show="step === 'SHOW_FACE_ID'">
                <face-capture :locale="locale"></face-capture>
            </div>
            <div class="row mt-2" v-show="step === 'SHOW_IMAGE'">
                <div v-if="!hideImage" class="text-center">
                    <img
                        :src="'data:image/jpeg;base64,' + faceId"
                        style="object-fit: cover; height: 250px"
                        class="rounded img-fluid" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "FaceId",
        props: ["faceIdSuccess", "hideImage"],

        data() {
            return {
                step: "OPEN_CAMERA",
                faceId: "",
                locale: localStorage.getItem("lang"),
            };
        },
        methods: {
            toogleFaceId() {
                this.step = "SHOW_FACE_ID";
            },
        },
        mounted() {
            const component = document.getElementsByTagName("face-capture")[0];
            component.addEventListener("face-capture", (event) => {
                if (event.detail?.capture) {
                    this.faceId = event.detail.capture[0];
                    this.step = "SHOW_IMAGE";
                    this.faceIdSuccess(event.detail.capture[0]);
                } else {
                    this.step = "OPEN_CAMERA";
                }
            });
        },
    };
</script>
