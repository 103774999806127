<template>
    <section>
        <div class="mb-1">
            <input
                ref="file"
                name="picture"
                style="display: none"
                v-validate="'required'"
                type="file"

                class="form-control"
                @change="fileHandler" />
            <img
                @click="imageClick"
                v-show="image"
                :src="image"
                width="150"
                height="150"
                style="border-radius: 50%; cursor: pointer" />
        </div>
    </section>
</template>

<script>
    import { ImageURL } from "../../../Utils/helpers";

    export default {
        name: "ImageInput",
        props: ["handleImageChange"],
        data() {
            return {
                image: ImageURL("camera.jpg"),
            };
        },
        methods: {
            imageClick() {
                let fileInputElement = this.$refs.file;
                fileInputElement.click();
            },
            async fileHandler($event) {
                this.handleImageChange($event.target.files[0]);
                this.image = URL.createObjectURL($event.target.files[0]);
            },
        },
    };
</script>
