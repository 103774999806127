<template>
    <section class="faq-section ptb-120 bg-light">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-12">
                    <div class="section-heading text-center">
                        <h4 class="h5 text-primary">FAQ</h4>
                        <h2 class="mt-4">{{ t("faq") }}</h2>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div v-for="(faq, index) of faqs" :key="index" class="col-lg-7 col-12 my-1">
                    <div class="accordion faq-accordion" id="accordionExample">
                        <div class="accordion-item border border-2 active">
                            <h5 class="accordion-header" id="faq-1">
                                <button
                                    class="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="`#collapse-${index}`"
                                    :aria-expanded="index === 0">
                                    {{ t(faq.question) }}
                                </button>
                            </h5>
                            <div
                                :id="`collapse-${index}`"
                                :class="index === 0 && 'show'"
                                class="accordion-collapse collapse"
                                aria-labelledby="faq-1"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    {{ t(faq.answer) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "FaqAccordion",
        props: ["faqs"],
    };
</script>
