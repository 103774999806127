<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "App",
        mounted() {
            if (!localStorage.getItem("lang")) {
                localStorage.setItem("lang", "ar");
            }
        },
    };
</script>
