export const errorHandler = (error) => {
    if (error.response) {
        // Request made and server responded
        return error.response.data.message;
    } else if (error.request) {
        // The request was made but no response was received
        return error.request;
    } else {
        // Something happened in setting up the request that triggered an Error
        return error.message;
    }
};

export const getBase64Key = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let res = reader.result.replace("data:", "").replace(/^.+,/, "");
            return resolve(res);
        };
        reader.onerror = (error) => reject(error);
    });
};

export const ImageURL = (image) => {
    var images = require.context("@/assets/images", false, /\.jpg$/);
    return images("./" + image);
};

export const decodeToken = (token) => {
    try {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );

        return JSON.parse(jsonPayload);
    } catch (e) {
        throw new Error("Token is not valid!");
    }
};

export const minutesToTimeSpan = (minutes) => {
    // calculate the number of hours, minutes, and seconds
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const seconds = (minutes - hours * 60 - remainingMinutes) * 60;

    // format the time span as a string and return it
    return `${hours.toString().padStart(2, "0")}:${remainingMinutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
};

export const convertToEnglishNumbers = (str) => {
    return str.toString().replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (match) {
        return {
            "٠": "0",
            "١": "1",
            "٢": "2",
            "٣": "3",
            "٤": "4",
            "٥": "5",
            "٦": "6",
            "٧": "7",
            "٨": "8",
            "٩": "9",
        }[match];
    });
};

export const removeSpaces = (string) => {
    if (!string) return null;
    return string.replace(/\s+/g, "");
};

export const objectToFormData = (obj, formData = new FormData(), namespace = "") => {
    for (let key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            const fullKey = namespace ? `${namespace}[${key}]` : key;
            const value = obj[key];

            if (value instanceof Date) {
                formData.append(fullKey, value.toISOString());
            } else if (value instanceof File) {
                formData.append(fullKey, value);
            } else if (value instanceof Blob) {
                formData.append(fullKey, value, value.name || "blob");
            } else if (typeof value === "object" && !(value instanceof Array)) {
                objectToFormData(value, formData, fullKey);
            } else if (value instanceof Array) {
                value.forEach((item, index) => {
                    const arrayKey = `${fullKey}[${index}]`;
                    if (typeof item === "object" && !(item instanceof File) && !(item instanceof Blob)) {
                        objectToFormData(item, formData, arrayKey);
                    } else {
                        formData.append(arrayKey, item);
                    }
                });
            } else {
                formData.append(fullKey, value);
            }
        }
    }

    return formData;
};


export const base64ToFile = (base64String, fileName) => {
    const binaryString = atob(base64String);
    const uint8Array = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: "image/png" }); // or "image/jpeg"
    const file = new File([blob], fileName, { type: blob.type });
    return file;
};