import axiosInstance from "../../Utils/axios";

export default {
    namespaced: true,
    state: {
        general: localStorage.general ? JSON.parse(localStorage.general) : [],
        specialties: localStorage.specialties ? JSON.parse(localStorage.specialties) : [],
    },
    mutations: {
        SET_GENERAL: (state, data) => ((state.general = data), localStorage.setItem("general", JSON.stringify(data))),
        SET_SPECIALTIES: (state, data) => (
            (state.specialties = data), localStorage.setItem("specialties", JSON.stringify(data))
        ),
        SET_SPECIALTIES_FOR_GENERAL: (state, genralSpecialtyId) => {
            let specialties = localStorage.specialties ? JSON.parse(localStorage.specialties) : [];
            state.specialties = specialties.filter((e) => e.genralSpecialtyId == genralSpecialtyId);
        },
    },
    getters: {
        general: (state) =>
            state.general.map((element) => {
                return {
                    value: element.id,
                    text: localStorage.getItem("lang") === "ar" ? element.arabicName : element.name,
                };
            }),
        specialties: (state) =>
            state.specialties.map((element) => {
                return {
                    value: element.id,
                    text: localStorage.getItem("lang") === "ar" ? element.arabicName : element.englishName,
                };
            }),
    },
    actions: {
        async generalSpecialties({ state, commit }) {
            if (state.general != null && state.general.length > 0) return;
            const response = await axiosInstance.get("Specialties/GetDoctorGeneralSpecialties");
            commit("SET_GENERAL", JSON.parse(JSON.stringify(response.data.data)));
        },
        async specialties({ state, commit }) {
            if (state.specialties != null && state.specialties.length > 0) return;
            const response = await axiosInstance.get("Specialties/GetDoctorSpecialties");
            commit("SET_SPECIALTIES", JSON.parse(JSON.stringify(response.data.data)));
        },
        async get_specialties_for({ commit }, id) {
            commit("SET_SPECIALTIES_FOR_GENERAL", id);
        },
    },
};
