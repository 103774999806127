<template>
    <component
        :is="getType(input.type)"
        v-if="!input.hide"
        :class="input.class"
        :label="input.label"
        :items="input.items"
        :rules="input.rules"
        :item-text="input.itemText"
        :item-value="input.itemValue"
        :options="input"
        :disabled="input.disabled"
        :readonly="input.readonly"
        :multiple="input.multiple"
        :searchable="input.searchable"
        :value="value"
        @input="$emit('input', $event)"></component>
</template>

<script>
    export default {
        name: "FieldWrapper",
        props: {
            value: {},
            input: {},
        },
        methods: {
            getType(type) {
                const types = {
                    text: "VTextField",
                    select: "VSelectField",
                    date: "VDateField",
                    checkbox: "CheckboxField",
                    file: "FileField",
                    textarea: "VTextarea",
                    time: "VTime",
                    crop_file: "VCropFileUpload",
                };
                return types[type];
            },
        },
    };
</script>

<style scoped></style>
