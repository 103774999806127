import Vue from "vue";
// Form
import VDateField from "./FormGenerator/Fields/VDateField";
import VBirthdateField from "./FormGenerator/Fields/VBirthdateField";
import VSelectField from "./FormGenerator/Fields/VSelectField";
import VTextArea from "./FormGenerator/Fields/VTextArea";
import VTextField from "./FormGenerator/Fields/VTextField";
import VPasswordField from "./FormGenerator/Fields/VPasswordField";
import FormGenerator from "./FormGenerator/FormGenerator.vue";
import VForm from "./FormGenerator/VForm";
import VPhoneField from "./FormGenerator/Fields/VPhoneField";
import VFileField from "./FormGenerator/Fields/VFileField";

// Form
Vue.component("FormGenerator", FormGenerator);
Vue.component("VForm", VForm);
Vue.component("VTextField", VTextField);
Vue.component("VPasswordField", VPasswordField);
Vue.component("VDateField", VDateField);
Vue.component("VBirthdateField", VBirthdateField);
Vue.component("VSelectField", VSelectField);
Vue.component("VTextArea", VTextArea);
Vue.component("VPhoneField", VPhoneField);
Vue.component("VFileField", VFileField);
