<template>
    <ValidationProvider
        v-show="show"
        v-slot="{ errors }"
        class="form-group"
        :vid="vid"
        :name="label"
        :disabled="disabled"
        :rules="rules"
        tag="div"
        mode="eager">
        <label v-if="label && !hideLabel">{{ t(label) }}</label>
        <span v-if="rules.includes('required')" class="text-sm text-danger mx-1">*</span>

        <div class="input-group w-100">
            <input
                v-model="field"
                :type="showPass ? 'text' : 'password'"
                :placeholder="t(placeholder)"
                :disabled="isDisabled"
                :readonly="readonly"
                :maxlength="maxlength"
                class="form-control"
                v-on="{
                    ...$listeners,
                    input: (e) => e.target.value,
                }" />
            <span @click="showPassword" style="cursor: pointer" class="input-group-text p-1" id="basic-addon1">
                <svg
                    v-if="!showPass"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-eye-fill"
                    viewBox="0 0 16 16">
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                    <path
                        d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
                <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-eye-slash-fill"
                    viewBox="0 0 16 16">
                    <path
                        d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                    <path
                        d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                </svg>
            </span>
        </div>

        <small class="text-danger">{{ errors[0] }}</small>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from "vee-validate";
    import fieldMixins from "@/mixins/fieldMixins";

    export default {
        name: "VPasswordField",
        data() {
            return { showPass: false };
        },
        components: {
            ValidationProvider,
        },
        mixins: [fieldMixins],
        props: {
            type: {
                type: String,
                default: "text",
            },
            maxlength: String,
            hideLabel: Boolean,
            vid: { type: String, default: "" },
        },
        methods: {
            showPassword() {
                this.showPass = !this.showPass;
            },
        },
        computed: {
            isDisabled() {
                if (typeof this.disabled === "function") return this.disabled();
                return this.disabled;
            },
            field: {
                get() {
                    return this.value;
                },
                set(value) {
                    // eslint-disable-next-line no-console
                    this.$emit("input", value);
                },
            },
        },
        watch: {
            value() {
                this.field = this.value;
            },
        },
    };
</script>
