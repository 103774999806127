<template>
    <section class="feature-section ptb-120">
        <div class="container">
            <ServiceRow orderType="order-lg-2" :details="patient_service" />
            <PatientServiceRow orderType="order-lg-1" :details="step1" />
            <PatientServiceRow orderType="order-lg-2" :details="step2" />
            <PatientServiceRow orderType="order-lg-1" :details="step3" />
            <PatientServiceRow orderType="order-lg-2" :details="step4" />
            <PatientServiceRow orderType="order-lg-1" :details="step5" />
            <PatientServiceRow orderType="order-lg-2" :details="step6" />
            <PatientServiceRow orderType="order-lg-1" :details="step7" />
            <PatientServiceRow orderType="order-lg-2" :details="step8" />
        </div>
    </section>
</template>

<script>
    import PatientServiceRow from "./PatientServiceRow.vue";
    import ServiceRow from "./ServiceRow.vue";

    export default {
        name: "LabServices",
        components: {
            PatientServiceRow,
            ServiceRow,
        },
        data() {
            return {
                patient_service: {
                    image: "patient/landing.png",
                    title: "patient_service_title_1",
                    image_class: "w-50",
                    features: [
                        "patient_service_details_1",
                        "patient_service_details_2",
                        "patient_service_details_3",
                        "patient_service_details_4",
                        "patient_service_details_5",
                        "patient_service_details_6",
                    ],
                },
                step1: {
                    image: "patient/Patient-Step-1.png",
                    title: "patient_service_title_2",
                    features: ["patient_service_details_7", "patient_service_details_8"],
                },
                step2: {
                    image: "patient/Patient-Step-2.png",
                    title: "patient_service_title_3",
                    features: ["patient_service_details_9"],
                },
                step3: {
                    image: "patient/Patient-Step-4.png",
                    title: "patient_service_title_3",
                    features: ["patient_service_details_10", "patient_service_details_11"],
                },
                step4: {
                    image: "patient/Patient-Step-6.png",
                    title: "patient_service_title_4",
                    features: ["patient_service_details_12", "patient_service_details_13"],
                },
                step5: {
                    image: "patient/Patient-Step-5.png",
                    title: "patient_service_title_5",
                    features: ["patient_service_details_14"],
                },
                step6: {
                    image: "patient/Patient-Step-8.png",
                    title: "patient_service_title_6",
                    features: ["patient_service_details_15"],
                },
                step7: {
                    image: "patient/Patient-Step-7.png",
                    title: "patient_service_title_7",
                    features: [
                        "patient_service_details_16",
                        "patient_service_details_17",
                        "patient_service_details_18",
                        "patient_service_details_19",
                    ],
                },
                step8: {
                    image: "patient/home-service.png",
                    title: "patient_service_title_8",
                    features: [
                        "patient_service_details_20",
                        "patient_service_details_21",
                        "patient_service_details_22",
                        "patient_service_details_23",
                    ],
                },
            };
        },
    };
</script>
