<template>
    <section class="feature-section ptb-120">
        <div class="container">
            <ServiceRow orderType="order-lg-1" :details="labFeat1" />
            <ServiceRow orderType="order-lg-2" :details="labFeat2" />
        </div>
    </section>
</template>

<script>
    import ServiceRow from "./ServiceRow.vue";

    export default {
        name: "LabServices",
        components: {
            ServiceRow,
        },
        data() {
            return {
                labFeat1: {
                    image: "lab/lab1.png",
                    title: "lab_management_detail2_title",
                    features: ["lab_management_detail2_feat1"],
                },
                labFeat2: {
                    image: "lab/lab2.png",
                    title: "lab_management_detail1_title",
                    features: [
                        "lab_management_detail1_feat1",
                        "lab_management_detail1_feat2",
                        "lab_management_detail1_feat3",
                        "lab_management_detail1_feat4",
                        "lab_management_detail1_feat5",
                        "lab_management_detail1_feat6",
                        "lab_management_detail1_feat7",
                    ],
                },
            };
        },
    };
</script>
