<template>
    <section>
        <h6>{{ t("contract_conditions") }}</h6>
        <div class="col-12 mt-2">
            <div class="p-3">
                <p v-if="contract.description" v-html="contract.description" />
                <h4 v-else class="pt-3 text-center">{{ t("loading") }}</h4>
            </div>
        </div>
        <div v-if="contract.description" class="col-12 mt-2">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="isAccept" id="flexCheckChecked" />
                <label class="form-check-label small" for="flexCheckChecked">
                    {{ t("contract_agreement") }}
                </label>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";

    export default {
        name: "ContractCard",
        props: ["handleAcceptContract"],
        data() {
            return {
                isAccept: false,
            };
        },
        computed: mapGetters(["contract"]),
        methods: {
            ...mapActions(["fetchContract"]),
        },
        watch: {
            isAccept: function (v) {
                this.handleAcceptContract(v);
            },
        },
        created() {
            this.fetchContract();
        },
    };
</script>
