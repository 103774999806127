<template>
    <div>
        <Navbar />
        <BreadCrumb :title="t('contact_us')" />
        <ContactForm />
        <Footer />
    </div>
</template>

<script>
    import BreadCrumb from "../components/common/BreadCrumb.vue";
    import ContactForm from "../components/contacts/ContactForm.vue";
    import Footer from "../components/footer/Footer.vue";
    import Navbar from "../components/nav/Navbar.vue";
    import ContactPromo from "../components/promo/ContactPromo.vue";
    export default {
        name: "Contact",
        components: {
            BreadCrumb,
            Navbar,
            ContactPromo,
            Footer,
            ContactForm,
        },
    };
</script>
