<template>
    <ValidationProvider
        v-slot="{ errors }"
        class="form-group"
        :vid="randomName"
        :name="label"
        :disabled="disabled"
        :rules="rules"
        tag="div"
        mode="eager">
        <label>{{ t(label) }}</label>
        <span v-if="rules.includes('required')" class="text-sm text-danger mx-1">*</span>

        <textarea
            v-model="field"
            :rows="rows"
            :disabled="isDisabled"
            :placeholder="placeholder"
            :readonly="readonly"
            class="form-control"
            v-on="{
                ...$listeners,
                input: (e) => e.target.value,
            }"></textarea>
        <small class="text-danger">{{ errors[0] }}</small>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from "vee-validate";
    import fieldMixins from "@/mixins/fieldMixins";

    export default {
        name: "VTextArea",
        components: {
            ValidationProvider,
        },
        mixins: [fieldMixins],
        props: {
            rows: {
                type: Number,
                required: false,
                default: 3,
            },
        },
        computed: {
            isDisabled() {
                if (typeof this.disabled === "function") return this.disabled();
                return this.disabled;
            },
            field: {
                get() {
                    return this.value;
                },
                set(value) {
                    // eslint-disable-next-line no-console
                    this.$emit("input", value);
                },
            },
            randomName() {
                return Math.random().toString(36).substring(7);
            },
        },
        watch: {
            value() {
                this.field = this.value;
            },
        },
    };
</script>

<style scoped></style>
