<template>
    <section>
        <h6>
            {{ userType === 1 ? t("lab_account_information") : t("pharmacy_account_information") }}
        </h6>
        <VForm v-slot="{ onValidate }">
            <div class="text-center">
                <ImageInput :handleImageChange="handleImageChange" />
            </div>
            <div class="row">
                <div class="col-sm-6 mt-3">
                    <VTextField
                        v-model="orginizationInfo.name"
                        :label="userType === 1 ? t('lab_name') : t('pharmacy_name')"
                        rules="required"></VTextField>
                </div>
                <div class="col-sm-6 mt-3">
                    <VPhoneField v-model="orginizationInfo.phone" label="phone_number" rules="required"></VPhoneField>
                </div>
                <div class="col-sm-6 mt-3">
                    <VSelectField
                        :items="governorates"
                        v-model="governorateId"
                        label="governorate"
                        rules="required"></VSelectField>
                </div>
                <div v-show="governorateId" class="col-sm-6 mt-3">
                    <VSelectField
                        :items="districts"
                        v-model="orginizationInfo.districtId"
                        label="district"
                        rules="required"></VSelectField>
                </div>
                <div class="col-sm-6 mt-3">
                    <VTextField v-model="orginizationInfo.address" label="address" rules="required"></VTextField>
                </div>
                <div class="col-sm-6 mt-3">
                    <VSelectField
                        :items="categoryTypes"
                        v-model="orginizationInfo.category"
                        label="type"
                        rules="required"></VSelectField>
                </div>
                <div class="col-sm-6 mt-3">
                    <VTextField
                        v-model="orginizationInfo.description"
                        label="description"
                        rules="required"></VTextField>
                </div>
            </div>
            <div class="mt-2 text-center text-danger">
                {{ error ? error : "" }}
            </div>
            <div class="row mt-2">
                <div class="col-12 text-right">
                    <button :disabled="loading" @click="onValidate(submitForm)" type="submit" class="btn btn-primary">
                        {{ loading ? t("loading") : t("next") }}
                    </button>
                </div>
            </div>
        </VForm>
    </section>
</template>

<script>
    import axios from "axios";
    import ImageInput from "./ImageInput.vue";
    import { removeSpaces } from "../../../Utils/helpers";

    export default {
        name: "OrginizationInfoForm",
        props: ["userType", "token", "handleOrginizationInfoSuccess"],
        data() {
            return {
                categoryTypes: [
                    { value: 0, text: this.t("private") },
                    { value: 1, text: this.t("public") },
                    { value: 2, text: this.t("mixed") },
                ],
                error: "",
                loading: false,
                governorateId: "",
                orginizationInfo: {
                    name: "",
                    phone: "",
                    address: "",
                    districtId: "",
                    description: "",
                },
            };
        },
        computed: {
            governorates() {
                return this.$store.getters["auth/governorate"];
            },
            districts() {
                return this.$store.getters["auth/districts"];
            },
        },
        watch: {
            governorateId: function (v) {
                this.$store.dispatch("auth/get_districts_for", v);
            },
        },
        methods: {
            handleImageChange(img) {
                this.orginizationInfo.logo = img;
            },
            async submitForm() {
                this.loading = true;
                const instance = axios.create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    timeout: 30000,
                    headers: { Authorization: `Bearer ${this.token}` },
                });

                const { phone, ...others } = this.orginizationInfo;
                const formData = new FormData();
                const objKeys = Object.keys(others);
                const objValues = Object.values(others);
                for (let i = 0; i < objKeys.length; i++) {
                    formData.append(objKeys[i], objValues[i]);
                }

                formData.append("phone", removeSpaces(phone));

                try {
                    const { data } = await instance.post("User/AddNodeInformation", formData);
                    this.loading = false;
                    this.handleOrginizationInfoSuccess();
                } catch (error) {
                    if (error.response) {
                        // Request made and server responded
                        this.error = error.response.data.message;
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.error = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.error = error.message;
                    }
                    this.loading = false;
                }
            },
        },
        components: { ImageInput },
    };
</script>
