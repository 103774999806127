<template>
    <section class="feature-section ptb-120">
        <div class="container">
            <ServiceRow orderType="order-lg-1" :details="pharmacyFeat3" />
            <ServiceRow orderType="order-lg-2" :details="pharmacyFeat2" />
            <ServiceRow orderType="order-lg-1" :details="pharmacyFeat1" />
        </div>
    </section>
</template>

<script>
    import ServiceRow from "./ServiceRow.vue";

    export default {
        name: "PharmacyServices",
        components: {
            ServiceRow,
        },
        data() {
            return {
                pharmacyFeat1: {
                    image: "pharmacy/pharmacy2.png",
                    title: "pharmacy_management_detail1_title",
                    features: [
                        "pharmacy_management_detail1_feat1",
                        "pharmacy_management_detail1_feat2",
                        "pharmacy_management_detail1_feat3",
                        "pharmacy_management_detail1_feat4",
                        "pharmacy_management_detail1_feat5",
                    ],
                },
                pharmacyFeat2: {
                    image: "pharmacy/pharmacy3.png",
                    title: "pharmacy_management_detail2_title",
                    features: ["pharmacy_management_detail2_feat1"],
                },
                pharmacyFeat3: {
                    image: "pharmacy/pharmacy1.png",
                    title: "pharmacy_management_detail3_title",
                    features: ["pharmacy_management_detail3_feat1"],
                },
            };
        },
    };
</script>
