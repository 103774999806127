<template>
    <div class="main-wrapper">
        <Navbar />

        <!-- Main Banner -->
        <Hero />

        <!-- Afea Services -->
        <PromoTwo />

        <!-- i will use it -->
        <!-- <FeatureTwo /> -->

        <!-- About afea with download app links -->
        <ImgContentNine />

        <FeatureThree />

        <!-- Statistics -->
        <!-- <CtaThree /> -->
        <!-- Footer -->
        <Footer />
    </div>
</template>

<script>
    // import CtaThree from "../components/cta/CtaThree.vue";
    import FeatureThree from "../components/features/FeatureThree.vue";
    // import FeatureTwo from "../components/features/FeatureEleven.vue";
    import ImgContentNine from "../components/img-content/ImgContentNine.vue";
    import Footer from "../components/footer/Footer.vue";
    import Hero from "../components/hero/Hero.vue";
    import Navbar from "../components/nav/Navbar.vue";
    import PromoTwo from "../components/promo/PromoTwo.vue";

    export default {
        components: {
            ImgContentNine,
            Hero,
            PromoTwo,
            // FeatureTwo,
            FeatureThree,
            // CtaThree,
            Navbar,
            Footer,
        },
    };
</script>
