<template>
    <section class="pricing-section mt-5 container">
        <div class="row my-2" v-for="entitlement of allEntitlements" :key="entitlement.id">
            <div v-if="entitlement.isActive" class="row mt-5 justify-content-center">
                <div class="text-center mb-2">
                    <div data-aos="fade-up">
                        <h4 class="h4 mb-3 text-warning">{{ t("plans") }}</h4>
                        <h2 class="mb-2">
                            {{
                                entitlement.package === 0
                                    ? `${t("clinicSubscriptions")} ${
                                          entitlement.name === "Advanced" ? t("advanced") : t("basic")
                                      }`
                                    : entitlement.package === 1
                                    ? `${t("pharmacySubscriptions")} ${
                                          entitlement.name === "Advanced" ? t("advanced") : t("basic")
                                      }`
                                    : `${t("labSubscriptions")} ${
                                          entitlement.name === "Advanced" ? t("advanced") : t("basic")
                                      }`
                            }}
                        </h2>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6" v-for="product of entitlement.products" :key="product.id">
                    <div
                        class="position-relative single-pricing-wrap rounded-custom custom-shadow p-5 mb-4 mb-lg-0"
                        :class="entitlement.name === 'Advanced' ? 'bg-gradient' : 'bg-white'">
                        <div class="pricing-header mb-2">
                            <h2 class="package-name text-primary d-block">
                                {{ product.name }}
                            </h2>
                            <h4 class="display-6 fw-semi-bold">
                                {{ product.amount }}
                                <span>/{{ product.name }}</span>
                            </h4>
                        </div>
                        <div class="pricing-info mb-4">
                            <ul class="pricing-feature-list list-unstyled">
                                <li>
                                    <i class="fas fa-circle fa-2xs text-primary me-2"></i>
                                    {{ product.duration }} {{ product.name }} {{ t("duration") }}
                                </li>
                                <li>
                                    <i class="fas fa-circle fa-2xs text-primary me-2"></i>
                                    {{ product.trailDuration }} {{ product.name }}
                                    {{ t("trailDuration") }}
                                </li>
                            </ul>
                            <p>
                                {{ product.description }}
                            </p>
                        </div>
                        <router-link
                            :to="`/registration/${entitlement.package}/${entitlement.id}/${product.id}`"
                            class="btn btn-outline-primary mt-2">
                            {{ t("subscribe") }}
                        </router-link>

                        <!--pattern start-->
                        <div class="dot-shape-bg position-absolute z--1 right--40 top--40">
                            <img src="@/assets/img/shape/dot-big-square.svg" alt="shape" />
                        </div>
                        <!--pattern end-->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";

    export default {
        name: "Price",
        data() {
            return {
                packageType: null,
            };
        },
        computed: mapGetters(["allEntitlements"]),
        mounted() {
            this.getEntitlements(this.$route.params.packageType);
        },
        methods: {
            ...mapActions(["getEntitlements"]),
        },
        watch: {
            $route: function () {
                this.packageType = this.$route.params.packageType;
                this.getEntitlements(this.$route.params.packageType);
            },
            packageType: function (v) {
                console.log(v);
            },
        },
    };
</script>
