<template>
    <div class="offcanvas offcanvas-end d-xl-none" tabindex="-1" id="offcanvasWithBackdrop">
        <div class="offcanvas-header d-flex align-items-center mt-4">
            <router-link to="/" class="d-flex align-items-center mb-md-0 text-decoration-none">
                <img src="@/assets/images/afea-logo.png" alt="logo" width="115" class="img-fluid ps-2" />
            </router-link>
            <button type="button" class="close-btn text-danger" data-bs-dismiss="offcanvas" aria-label="Close">
                <i class="far fa-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <ul class="nav col-12 col-md-auto justify-content-center main-menu">
                <li>
                    <router-link to="/" class="nav-link">
                        {{ t("home") }}
                    </router-link>
                </li>
                <li>
                    <router-link to="/#services" class="nav-link">
                        {{ t("services") }}
                    </router-link>
                </li>
                <!-- <li>
          <router-link to="/registration" class="nav-link">
            {{ t("registration") }}
          </router-link>
        </li> -->
                <li>
                    <router-link to="/features" class="nav-link">
                        {{ t("features") }}
                    </router-link>
                </li>
                <!-- <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ t("pricing") }}
          </a>

          <div
            class="dropdown-menu border-0 rounded-custom shadow py-0 bg-white"
          >
            <div class="dropdown-grid rounded-custom width-half">
              <div class="dropdown-grid-item">
                <h6 class="drop-heading">
                  {{ t("pricingAndSubscriptions") }}
                </h6>
                <router-link to="/pricing/0" class="dropdown-link">
                  <span class="demo-list bg-primary rounded text-white fw-bold"
                    >1</span
                  >
                  <div class="dropdown-info">
                    <div class="drop-title">{{ t("clinicService") }}</div>
                  </div>
                </router-link>
                <router-link to="/pricing/1" class="dropdown-link">
                  <span class="demo-list bg-primary rounded text-white fw-bold"
                    >2</span
                  >
                  <div class="dropdown-info">
                    <div class="drop-title">{{ t("pharmacyService") }}</div>
                  </div>
                </router-link>
                <router-link to="/pricing/2" class="dropdown-link">
                  <span class="demo-list bg-primary rounded text-white fw-bold"
                    >3</span
                  >
                  <div class="dropdown-info">
                    <div class="drop-title">
                      {{ t("laboratoryService") }}
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </li> -->

                <li>
                    <router-link to="/#sponsers" class="nav-link">
                        {{ t("sponsers") }}
                    </router-link>
                </li>
                <li>
                    <router-link to="/#features" class="nav-link">
                        {{ t("features") }}
                    </router-link>
                </li>
                <li v-if="this.$device.mobile">
                    <router-link to="/#mobile" class="nav-link">
                        {{ t("download_app") }}
                    </router-link>
                </li>
                <li>
                    <router-link to="/contact" class="nav-link">
                        {{ t("contact_us") }}
                    </router-link>
                </li>
            </ul>

            <div class="action-btns mt-4 ps-3">
                <div class="nav-item dropdown">
                    <button role="button" data-bs-toggle="dropdown" aria-expanded="false" class="btn btn-primary">
                        {{ t("get_started") }}
                    </button>

                    <div class="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                        <div class="dropdown-grid rounded-custom width-half">
                            <div class="dropdown-grid-item">
                                <h6 class="drop-heading">
                                    {{ t("get_started") }}
                                </h6>
                                <router-link to="/patient/registration" class="dropdown-link">
                                    <span class="demo-list bg-primary rounded text-white fw-bold">1</span>
                                    <div class="dropdown-info">
                                        <div class="drop-title">{{ t("patientService") }}</div>
                                    </div>
                                </router-link>
                                <router-link to="/clinic/registration" class="dropdown-link">
                                    <span class="demo-list bg-primary rounded text-white fw-bold">2</span>
                                    <div class="dropdown-info">
                                        <div class="drop-title">{{ t("clinicService") }}</div>
                                    </div>
                                </router-link>
                                <router-link to="/pharmacy/registration" class="dropdown-link">
                                    <span class="demo-list bg-primary rounded text-white fw-bold">3</span>
                                    <div class="dropdown-info">
                                        <div class="drop-title">{{ t("pharmacyService") }}</div>
                                    </div>
                                </router-link>
                                <router-link to="/lab/registration" class="dropdown-link">
                                    <span class="demo-list bg-primary rounded text-white fw-bold">4</span>
                                    <div class="dropdown-info">
                                        <div class="drop-title">
                                            {{ t("laboratoryService") }}
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="https://dashboard.afea.healthcare/" class="btn mt-4 btn-primary" target="_blank">
                {{ t("login") }}
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Offcanvas",
    };
</script>
