<template>
    <div class="main-wrapper">
        <Navbar />
        <BreadCrumb :title="t(data[this.$route.params.id].name)" :desc="t(data[this.$route.params.id].description)" />
        <ClinicServices v-show="this.$route.params.id == 0" />
        <PharmacyServices v-show="this.$route.params.id == 1" />
        <LabServices v-show="this.$route.params.id == 2" />
<!--        <PatientServices v-show="this.$route.params.id == 3" />-->
        <InsuranceServices v-show="this.$route.params.id == 3" />
        <FaqAccordion :faqs="faqs[this.$route.params.id].questions" />
        <Footer />
    </div>
</template>

<script>
    import BreadCrumb from "../components/common/BreadCrumb.vue";
    import Footer from "../components/footer/Footer.vue";
    import Navbar from "../components/nav/Navbar.vue";
    import ClinicServices from "../components/Services/ClinicServices.vue";
    import PharmacyServices from "../components/Services/PharmacyServices.vue";
    import LabServices from "../components/Services/LabServices.vue";
    import PatientServices from "../components/Services/PatientServices.vue";
    import FaqAccordion from "../components/faqs/FaqAccordion.vue";
    import InsuranceServices from "../components/Services/InsuranceServices.vue";
    import {systemServices, servicesfaq} from "../constants";

    export default {
      data() {
        return {
          data: systemServices,
          faqs: servicesfaq,
        };
      },
      components: {
        Navbar,
        BreadCrumb,
        ClinicServices,
        PharmacyServices,
        PatientServices,
        LabServices,
        FaqAccordion,
        Footer,
        InsuranceServices,
      },
    };
</script>