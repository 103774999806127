<template>
    <section class="feature-section">
        <div class="row align-items-lg-center justify-content-between">
            <div class="col-lg-6 px-4 order-lg-2 mb-lg-0 app-two-feature-two-content lrt-order-lg-2">
                <div class="mb-2">
                    <h3>
                        {{ t(details.title) }}
                    </h3>
                </div>
                <ul class="list-unstyled">
                    <li v-for="feature of details.features" :key="feature" class="py-1 d-flex">
                        <i class="fad fa-check-circle me-2"></i>
                        {{ t(feature) }}
                    </li>
                </ul>
            </div>
            <div :class="orderType" class="col-lg-6">
                <div class="pr-lg-4 mt-md-4 position-relative">
                    <div class="text-center rounded-custom overflow-hidden mb-5 mx-lg-auto">
                        <img
                            style="height: 700px"
                            :src="require('@/assets/img/screen/' + details.image + '')"
                            :class="'rounded-custom ' + details.class" />
                        <div
                            class="position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "PatientServiceRow",
        props: ["details", "orderType"],
    };
</script>
