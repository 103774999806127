<template>
    <section>
        <div v-show="step === 'VERIFICATION_TYPE_STEP'">
            <h6>{{ t("choose_verification_type") }}</h6>
            <div class="cards mt-3 row d-flex justify-content-center">
                <label class="col-lg-4 col-md-4 col-sm-12">
                    <input type="radio" v-model="verificationType" :value="1" />
                    <div class="custom-card card p-5 text-center">
                        <h4 class="mb-0">
                            {{ t("verify_by_phone") }}
                        </h4>
                    </div>
                </label>
                <label class="col-lg-4 col-md-4 col-sm-12">
                    <input type="radio" v-model="verificationType" :value="0" />
                    <div class="custom-card card p-5 text-center">
                        <h4 class="mb-0">
                            {{ t("verify_by_email") }}
                        </h4>
                    </div>
                </label>
            </div>
            <div class="row mt-2">
                <div class="col-12 text-right">
                    <button :disabled="loading" type="button" @click="submitVerificationType" class="btn btn-primary">
                        {{ loading ? t("loading") : t("next") }}
                    </button>
                </div>
            </div>
        </div>

        <div v-show="step == 'PATIENT_VERIFICATION_SUCCCESS_MESSAGE'">
            <ExtraInfo v-if="healthNumber" :healthNumber="healthNumber" />
            <VForm v-else v-slot="{ onValidate }">
                <div class="row">
                    <div class="mt-3">
                        <VTextField
                            v-model="manualHealthNumber"
                            :label="t('patient_service_title_7')"
                            rules="required"></VTextField>
                    </div>
                </div>
                <div class="mt-2 text-center text-danger">
                    {{ error ? error : "" }}
                </div>
                <div class="row mt-2">
                    <div class="col-12 text-right">
                        <button
                            :disabled="loading"
                            @click="onValidate(setHealthNumber)"
                            type="submit"
                            class="btn btn-primary">
                            {{ loading ? t("loading") : t("next") }}
                        </button>
                    </div>
                </div>
            </VForm>
        </div>

        <div v-show="step == 'VERIFICATION_BY_EMAIL_MESSAGE'">
            <h4>{{ t("registeration_complete") }}</h4>
            <div class="text-center py-3 mx-auto">
                <p style="font-size: 20px">
                    {{ t("registeration_by_email_text") }}
                </p>
            </div>
            <div class="text-center mx-auto">
                <div dir="ltr" class="text-center text-danger">
                    <countdown v-if="!showResendVerification" @progress="handleCountdownProgress" :time="120 * 1000">
                        <template slot-scope="props">0{{ props.minutes }} : {{ props.seconds }}</template>
                    </countdown>
                    <div v-show="showResendVerification">
                        <button :disabled="loading" @click="submitVerificationType" class="btn btn-light btn-sm">
                            {{ t("if_you_did_not_receive_the_verification_email_click_here") }}
                        </button>
                        <br />
                        <button @click="changeVerificationMethode" class="btn btn-light btn-sm mt-3">
                            {{ t("change_verification_methode") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="step === 'OTP_STEP'">
            <h4>{{ t("confirm_phone_number") }}</h4>
            <div class="row mt-3">
                <div class="col-lg-6 col-md-8 col-sm-12 mx-auto">
                    <div class="input-group mb-3">
                        <label class="mb-1">{{ t("enter_otp") }}</label>
                        <v-otp-input
                            dir="ltr"
                            ref="otpInput"
                            input-classes="text-center form-control"
                            separator="-"
                            :num-inputs="6"
                            :should-auto-focus="true"
                            :is-input-num="true"
                            @on-change="handleOnChange"
                            @on-complete="handleOnComplete" />
                    </div>
                </div>
            </div>
            <div dir="ltr" class="mt-4 text-center text-danger">
                <countdown v-if="!showResendVerification" @progress="handleCountdownProgress" :time="120 * 1000">
                    <template slot-scope="props">0{{ props.minutes }} : {{ props.seconds }}</template>
                </countdown>
                <div v-show="showResendVerification">
                    <button :disabled="loading" @click="submitVerificationType" class="btn btn-light btn-sm">
                        {{ t("resend_otp") }}
                    </button>
                    <br />
                    <button @click="changeVerificationMethode" class="btn btn-light btn-sm mt-3">
                        {{ t("change_verification_methode") }}
                    </button>
                </div>
            </div>
            <div class="mt-4 text-center text-danger">
                {{ error ? error : "" }}
            </div>
            <div class="row mt-2">
                <div class="col-12 text-right">
                    <button :disabled="loading" @click="submitOtp" class="btn btn-primary">
                        {{ loading ? t("loading") : t("confirm") }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import axios from "axios";
    import { decodeToken } from "../../../Utils/helpers";
    import ExtraInfo from "../Shared/ExtraInfo.vue";
    // enums
    // 0 email
    // 1 phone
    export default {
        name: "AccountVerification",
        props: ["token", "verificationSuccess"],
        components: { ExtraInfo },
        data() {
            return {
                healthNumber: "",
                manualHealthNumber: "",
                step: "VERIFICATION_TYPE_STEP",
                verificationType: 1,
                loading: false,
                verfication: "",
                error: "",
                showResendVerification: false,
            };
        },
        methods: {
            async setHealthNumber() {
                this.error = "";
                this.loading = true;
                const instance = axios.create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    timeout: 30000,
                    headers: { Authorization: `Bearer ${this.token}` },
                });
                try {
                    const { data } = await instance.post(`User/AddPatientInformation`, {
                        healthNumber: this.manualHealthNumber,
                    });
                    this.healthNumber = data.data.healthNumber;
                } catch (error) {
                    if (error.response) {
                        // Request made and server responded
                        this.error = error.response.data.message;
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.error = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.error = error.message;
                    }
                    this.loading = false;
                }
            },
            changeVerificationMethode() {
                this.step = "VERIFICATION_TYPE_STEP";
            },
            handleCountdownProgress(data) {
                if (data.seconds === 1) {
                    this.showResendVerification = true;
                }
            },
            async submitVerificationType(e) {
                e.preventDefault();
                this.loading = true;
                this.showResendVerification = false;
                const instance = axios.create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    timeout: 30000,
                    headers: { Authorization: `Bearer ${this.token}` },
                });
                try {
                    const { data } = await instance.get(
                        `User/SendVerification?verificationType=${this.verificationType}`
                    );
                    this.loading = false;
                    if (this.verificationType === 1) {
                        this.step = "OTP_STEP";
                    } else {
                        this.step = "VERIFICATION_BY_EMAIL_MESSAGE";
                    }
                } catch (error) {
                    if (error.response) {
                        // Request made and server responded
                        this.error = error.response.data.message;
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.error = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.error = error.message;
                    }
                    this.loading = false;
                }
            },
            async submitOtp(e) {
                e.preventDefault();
                this.error = "";
                this.loading = true;
                const instance = axios.create({
                    baseURL: process.env.VUE_APP_BASE_URL,
                    timeout: 30000,
                    headers: { Authorization: `Bearer ${this.token}` },
                });
                try {
                    const { data } = await instance.get(
                        `User/VerifyVerification?verificationType=${this.verificationType}&verfication=${this.verfication}`
                    );
                    this.loading = false;
                    const decodedToken = decodeToken(this.token);
                    if (decodedToken.userType === "Patient") {
                        this.step = "PATIENT_VERIFICATION_SUCCCESS_MESSAGE";
                    } else {
                        this.verificationSuccess(true);
                    }
                } catch (error) {
                    if (error.response) {
                        // Request made and server responded
                        this.error = error.response.data.message;
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.error = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.error = error.message;
                    }
                    this.loading = false;
                }
            },
            handleOnComplete(value) {
                this.verfication = value;
            },
            handleOnChange(value) {
                console.log("OTP changed: ", value);
            },
        },
    };
</script>
