<template>
    <section>
        <div v-show="step == 1">
            <h5>{{ t("register_by_qi_card") }}</h5>
            <div class="d-flex justify-content-center my-5">
                <div class="text-center">
                    <img class="w-100 px-3" src="@/assets/img/cards/master.png" alt="" />
                    <h6 class="mt-2 text-secondary">Master Card</h6>
                </div>
                <div class="text-center">
                    <img class="w-100 px-3" src="@/assets/img/cards/qi.png" alt="" />
                    <h6 class="mt-2 text-secondary">QI Card</h6>
                </div>
            </div>
            <form @submit="checkCardNumber">
                <div class="row mt-3">
                    <label>{{ t("card_number") }}</label>
                    <div class="input-group mb-3">
                        <input
                            required
                            :disabled="checkLivness"
                            type="number"
                            class="form-control"
                            maxlength="16"
                            minlength="8"
                            v-model="referenceValue" />
                    </div>

                    <div class="mt-4 text-center text-danger">
                        {{ error ? error : "" }}
                    </div>
                </div>
                <div v-show="!checkLivness" class="row mt-2">
                    <div class="col-12 text-right">
                        <button :disabled="loading" type="submit" class="btn btn-primary">
                            {{ loading ? t("loading") : t("send") }}
                        </button>
                    </div>
                </div>
            </form>
            <div v-if="checkLivness" class="row">
                <FaceId :key="componentKey" :faceIdSuccess="faceIdSuccess" />
                <div class="text-center mt-3" v-show="loading">
                    <div class="spinner-border" role="status"></div>
                    <div>{{ t("uploading_image") }}</div>
                </div>
                <div class="mt-4 text-center" v-if="photoError">
                    <button type="button" class="btn btn-sm btn-light" @click="retakePhoto">
                        {{ t("retake_image") }}
                    </button>
                    <div class="mt-2 text-center text-danger">
                        {{ t(photoError) }}
                    </div>
                </div>
            </div>
        </div>
        <div v-show="step == 2">
            <h5>{{ t("verify_identity") }}</h5>
            <div class="row mt-3">
                <div class="cards mt-3 row d-flex justify-content-center">
                    <label class="col-lg-4 my-1 col-md-6 col-sm-12">
                        <input type="radio" v-model="verificationChannel" value="0" />
                        <div class="custom-card card p-5 text-center">
                            <div class="position-relative connected-app-content">
                                <p class="mb-0">
                                    {{ t("face_id_verification") }}
                                </p>
                            </div>
                        </div>
                    </label>
                    <label class="col-lg-4 my-1 col-md-6 col-sm-12">
                        <input type="radio" v-model="verificationChannel" value="1" />
                        <div class="custom-card card p-5 text-center">
                            <div class="position-relative connected-app-content">
                                <p class="mb-0">
                                    {{ t("otp_verification") }}
                                </p>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 text-right">
                    <button :disabled="!verificationChannel" @click="handleVerificationChannel" class="btn btn-primary">
                        {{ t("next") }}
                    </button>
                </div>
            </div>
        </div>
        <div v-show="step == 3" class="pt-3 text-center">
            <h5>
                {{ loading ? t("loading") : "" }}
            </h5>
            <div class="mt-4 text-center text-danger">
                {{ t(photoError) }}
            </div>
            <button v-show="photoError" type="button" class="mt-2 btn btn-sm btn-light" @click="retryLiveness">
                {{ t("retry") }}
            </button>
        </div>
        <div v-show="step == 4">
            <h5>{{ t(otpSentMessage) }}</h5>
            <div class="row mt-3">
                <div class="col-lg-6 col-md-8 col-sm-12 mx-auto">
                    <div class="input-group mb-3">
                        <label class="mb-1">{{ t("enter_otp") }}</label>
                        <v-otp-input
                            dir="ltr"
                            ref="otpInput"
                            input-classes="text-center form-control"
                            separator="-"
                            :num-inputs="6"
                            :should-auto-focus="true"
                            :is-input-num="true"
                            @on-change="handleOnChange"
                            @on-complete="handleOnComplete" />
                    </div>
                </div>
            </div>
            <div class="mt-4 text-center text-danger">
                {{ error ? error : "" }}
            </div>
            <div class="row mt-2">
                <div class="col-12 text-right">
                    <button :disabled="loading" @click="verifySms" class="btn btn-primary">
                        {{ loading ? t("loading") : t("confirm") }}
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import axiosInstance from "../../../Utils/axios";
    import FaceId from "./FaceId.vue";

    export default {
        name: "QiCardRegistration",
        props: ["handleSuccess"],
        components: { FaceId },
        data() {
            return {
                componentKey: 0,
                step: 1,
                verificationChannel: "",
                error: "",
                photoError: "",
                loading: false,
                referenceType: "",
                referenceValue: "",
                guid: "",
                checkLivness: false,
                image: "",
                liveImage: "",
                isLoading: false,
                photo: "",
                otp: null,
                customerInfo: {},
                otpSentMessage: "",
                refGuid: "",
            };
        },
        watch: {
            liveImage: function (v) {
                if (v) {
                    this.checkImageLiveness(v);
                }
            },
            customerInfo: function (v) {
                this.handleSuccess(v, this.referenceValue);
            },
        },
        methods: {
            handleVerificationChannel() {
                this.verificationChannel === "0" ? (this.step = 3) : (this.step = 4);
                this.handleVerificationRequest();
            },
            retakePhoto() {
                this.componentKey += 1;
                this.photoError = "";
                this.liveImage = "";
            },
            async checkCardNumber(e) {
                e.preventDefault();
                this.loading = true;
                let type = this.referenceValue.length === 16 ? 1 : 0;
                this.referenceType = type;
                try {
                    const { data } = await axiosInstance.get("QiHolderVerification/VerificationChanel", {
                        params: {
                            referenceType: type,
                            referenceValue: this.referenceValue,
                        },
                    });
                    this.loading = false;
                    if (data.message === "Success") {
                        this.error = "";
                        this.checkLivness = true;
                    }
                } catch (error) {
                    if (error.response) {
                        // Request made and server responded
                        this.error = error.response.data.message;
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.error = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.error = error.message;
                    }
                    this.loading = false;
                }
            },
            async checkImageLiveness(photo) {
                this.loading = true;
                this.photoError = "";
                try {
                    const { data } = await axiosInstance.post("QiHolderVerification/LivenessCheck", {
                        photo,
                    });
                    this.loading = false;
                    if (data.message === "Liveness Success") {
                        this.photo = photo;
                        this.guid = data.data.responseGuid;
                        this.step = 2;
                    }
                } catch (error) {
                    if (error.response) {
                        // Request made and server responded
                        this.photoError = error.response.data.message;
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.photoError = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.photoError = error.message;
                    }
                    this.loading = false;
                }
            },
            async handleVerificationRequest() {
                this.loading = true;
                this.photoError = "";
                try {
                    const { data } = await axiosInstance.post("QiHolderVerification/VerificationByChanel", {
                        verificationChannel: parseInt(this.verificationChannel),
                        requestGuid: this.guid,
                        customerInfo: {
                            referenceType: this.referenceType,
                            referenceValue: this.referenceValue,
                        },
                        photo: this.photo,
                    });
                    this.loading = false;

                    if (data.message === "Verification Success") {
                        this.customerInfo = data.data.demographics;
                    } else {
                        this.refGuid = data.data.refGuid;
                        this.otpSentMessage = data.message;
                    }
                } catch (error) {
                    if (error.response) {
                        // Request made and server responded
                        if (error.response.data.message === "Verification Failed") {
                            this.checkLivness = true;
                        }
                        this.photoError = error.response.data.message;
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.photoError = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.photoError = error.message;
                    }
                    this.loading = false;
                }
            },
            async verifySms() {
                this.loading = true;
                try {
                    const { data } = await axiosInstance.get("QiHolderVerification/VerifyBySms", {
                        params: {
                            refGuid: this.refGuid,
                            otp: this.otp,
                        },
                    });
                    this.loading = false;
                    if (data.message === "OTP Verification Success") {
                        this.customerInfo = data.data;
                        this.error = "";
                    }
                } catch (error) {
                    if (error.response) {
                        // Request made and server responded
                        this.error = error.response.data.message;
                    } else if (error.request) {
                        // The request was made but no response was received
                        this.error = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        this.error = error.message;
                    }
                    this.loading = false;
                }
            },
            faceIdSuccess(image) {
                this.liveImage = image;
            },
            handleOnComplete(value) {
                this.otp = value;
            },
            handleOnChange(value) {
                console.log("OTP changed: ", value);
            },
            retryLiveness() {
                this.componentKey += 1;
                this.checkLivness = true;
                this.photoError = "";
                this.step = 1;
            },
        },
    };
</script>
