<template>
    <section class="feature-section ptb-120" :class="[darkBg ? 'bg-dark' : '', bgLight ? 'bg-light' : '']">
        <div class="container">
            <div class="row justify-content-center" :class="hasTitle ? 'row justify-content-center' : 'd-none'">
                <div class="col-lg-6 col-md-10">
                    <div class="section-heading text-center" data-aos="fade-up">
                        <h2>{{ t("services_title") }}</h2>
                        <h2>{{ t("services_subtitle") }}</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-for="feature in features" :key="feature.id" class="col-md-4 my-2">
                    <div
                        style="height: 100%"
                        class="feature-card shadow-sm rounded-custom p-5"
                        :class="
                            darkBg
                                ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                                : 'bg-white'
                        "
                        data-aos="fade-up"
                        data-aos-delay="50">
                        <div class="icon-box d-inline-block rounded-circle bg-success-soft mb-32">
                            <i :class="`${feature.icon} icon-sm text-success`"></i>
                        </div>
                        <div class="feature-content">
                            <h3 class="h5">{{ t(feature.name) }}</h3>
                            <p class="mb-0">
                                {{ t(feature.description) }}
                            </p>
                        </div>
                        <!-- <router-link
              :to="`/services/${feature.id}`"
              class="link-with-icon text-decoration-none mt-3"
              >{{ t("view_details") }}
              <i v-if="currentLang === 'ar'" class="far fa-arrow-left"></i>
              <i v-else class="far fa-arrow-right"></i>
            </router-link> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { systemServices } from "../../constants";

    export default {
        name: "Features",
        props: {
            hasTitle: String,
            darkBg: String,
            bgLight: String,
        },
        data() {
            return {
                currentLang: localStorage.getItem("lang"),
                features: systemServices,
            };
        },
    };
</script>
