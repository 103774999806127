<template>
    <section class="hero-section hero-three ptb-120">
        <div class="container">
            <div class="row align-items-center justify-content-lg-between">
                <div class="col-xl-5 col-lg-5">
                    <div class="hero-content-wrap text-center text-xl-start text-lg-start" data-aos="fade-right">
                        <h1 class="fw-bold display-5 mb-3">
                            {{ t("app_name") }}
                        </h1>
                        <p class="lead">
                            نظام عافية الصحي، الخيار الأمثل لإدارة السجل الطبي ، محققا
                            التكامل والشفافية في الرعاية الصحية، ومُضمنا حقك في الحصول على الخدمات الصحية المستحقة.
                        </p>
                        <div
                            class="hero-subscribe-form-wrap pt-4 position-relative m-auto m-xl-0 d-none d-md-block d-lg-block d-xl-block"></div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 mt-4 mt-xl-0">
                    <div class="hero-img-wrap position-relative" data-aos="fade-left">
                        <!--animated shape start-->
                        <ul class="position-absolute animate-element parallax-element shape-service hide-medium">
                            <li class="layer" data-depth="0.03">
                                <img
                                    src="@/assets/img/color-shape/image-1.svg"
                                    alt="shape"
                                    class="img-fluid position-absolute color-shape-1" />
                            </li>
                            <li class="layer" data-depth="0.02">
                                <img
                                    src="@/assets/img/color-shape/feature-2.svg"
                                    alt="shape"
                                    class="img-fluid position-absolute color-shape-2 z-5" />
                            </li>
                            <li class="layer" data-depth="0.03">
                                <img
                                    src="@/assets/img/color-shape/feature-3.svg"
                                    alt="shape"
                                    class="img-fluid position-absolute color-shape-3" />
                            </li>
                        </ul>
                        <!--animated shape end-->
                        <div class="hero-img-wrap position-relative">
                            <div class="hero-screen-wrap">
                                <!-- <div class="phone-screen">
<img
src="@/assets/img/screen/phone-screen.png"
alt="hero image"
class="position-relative img-fluid"
/>
</div> -->
                                <div class="mac-screen">
                                    <img
                                        src="@/assets/img/screen/clinic/clinic2.png"
                                        alt="hero image"
                                        class="position-relative img-fluid rounded-custom" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {};
</script>

<style></style>