<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "GroupTitle",
    };
</script>

<style scoped>
    div {
        width: 100%;
        padding-right: 15px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
</style>
