import axiosInstance from "../../Utils/axios";

export default {
    namespaced: true,
    state: {
        governorates: localStorage.governorates ? JSON.parse(localStorage.governorates) : [],
        districts: localStorage.districts ? JSON.parse(localStorage.districts) : [],
        nationalties: localStorage.nationalties ? JSON.parse(localStorage.nationalties) : [],
        languages: localStorage.languages ? JSON.parse(localStorage.languages) : [],
    },
    mutations: {
        SET_GOVERNANTES: (state, data) => {
            (state.governorates = data), localStorage.setItem("governorates", JSON.stringify(data));
        },
        SET_DISTRICTS: (state, data) => {
            state.districts = data;
            localStorage.setItem("districts", JSON.stringify(data));
        },
        SET_DISTRICTS_FOR_GOVERNATE: (state, governateId) => {
            let districts = localStorage.districts ? JSON.parse(localStorage.districts) : [];
            state.districts = districts.filter((e) => e.governorateId == governateId);
        },
        SET_NATIONALTIES: (state, data) => {
            state.nationalties = data;
            localStorage.setItem("nationalties", JSON.stringify(data));
        },
        SET_LANGUAGES: (state, data) => {
            state.languages = data;
            localStorage.setItem("languages", JSON.stringify(data));
        },
    },
    getters: {
        governorate: (state) =>
            state.governorates.map((element) => {
                return {
                    value: element.id,
                    text: localStorage.getItem("lang") === "ar" ? element.arabicName : element.name,
                };
            }),
        districts: (state) =>
            state.districts.map((element) => {
                return {
                    value: element.id,
                    text: localStorage.getItem("lang") === "ar" ? element.name : element.englishName,
                };
            }),

        nationalties: (state) =>
            state.nationalties.map((element) => {
                return {
                    value: element.id,
                    text: element.name,
                };
            }),
        languages: (state) =>
            state.languages.map((element) => {
                return {
                    value: element.id,
                    text: element.name,
                };
            }),
    },
    actions: {
        async governorates({ commit, state }) {
            if (state.governorates != null && state.governorates.length > 0) return;
            const response = await axiosInstance.get("Auth/GetGovernorates");
            commit("SET_GOVERNANTES", JSON.parse(JSON.stringify(response.data.data)));
        },
        async districts({ commit }) {
            const response = await axiosInstance.get("Auth/GetDistricts");
            commit("SET_DISTRICTS", JSON.parse(JSON.stringify(response.data.data)));
        },
        async get_districts_for({ commit }, id) {
            commit("SET_DISTRICTS_FOR_GOVERNATE", id);
        },
        async activate_account(_, token) {
            return await axiosInstance.get(`User/VerifyVerification?verificationType=${0}&verfication=${token}`);
        },
        async accept_invite(_, token) {
            return await axiosInstance.post("Doctor/AcceptInvitationToClinic?token=" + token);
        },
        async recaptcha(_, token) {
            return await axiosInstance.get("Auth/RecaptchaTest?token=" + token);
        },
        async nationalties({ commit }) {
            const response = await axiosInstance.get("Nationality/GetAllNationalities");
            commit("SET_NATIONALTIES", JSON.parse(JSON.stringify(response.data.data)));
        },
        async languages({ commit }) {
            const response = await axiosInstance.get("Language/GetLanguages");
            commit("SET_LANGUAGES", JSON.parse(JSON.stringify(response.data.data)));
        },
        async resetPassword(_, { token, password }) {
            return await axiosInstance.put(`Auth/UpdatePassword?token=${token}&password=${password}`);
        },
        async getFingerPrint() {
            return await axiosInstance.get(
                "http://localhost:49274/fingerprint/GetDataBase64async?smartAccountNo=SmartCard"
            );
        },
        async requestPDF(_, submittedData) {
            const { birthDate, ...others } = submittedData;
            console.log({ submittedData });
            return await axiosInstance.post("https://black-code.tech/api/pdf", { birth: birthDate, ...others });
        },
    },
};
